import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReorderIcon from "@mui/icons-material/Reorder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Footer } from "../../../common/Footer/Footer";
import {
  ChatBotSidebar,
  ChatContentWrapper,
  MainContent,
  InfoMessageBot,
  BotHeaderHeading,
  BotHeaderRight,
  CustomButtonOpenClose,
} from "./style";

export function Layout(props: {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean | ((prev: boolean) => boolean)) => void;
  sidebar: React.ReactNode;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
}) {
  return (
    <>
      {/* <Transition.Root show={props.sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => props.setSidebarOpen(props.sidebarOpen)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => props.setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <ChatBotSidebar>{props.sidebar}</ChatBotSidebar>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> */}
      {/* Static sidebar for desktop */}
      {props.sidebarOpen && (
        <ChatBotSidebar>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          {props.sidebar}
        </ChatBotSidebar>
      )}
      <ChatContentWrapper
        className={props.sidebarOpen ? "sidebar-opened" : "sidebar-closed"}
      >
        <BotHeaderRight>
          <CustomButtonOpenClose
            type="button"
            onClick={() => props.setSidebarOpen((prev: boolean) => !prev)}
          >
            <span className="sr-only">Open sidebar</span>
            {props.sidebarOpen ? <ArrowBackIcon /> : <ReorderIcon />}
          </CustomButtonOpenClose>
          <BotHeaderHeading>
            {props.subtitle ? (
              <>
                BrightBot Studio{" "}
                {/* <span className="font-normal">{props.subtitle}</span> */}
              </>
            ) : (
              "BrightBot Studio"
            )}
          </BotHeaderHeading>
          {/* <InfoMessageBot>
            Beta feature: this is a beta admin portal for custom brightbot
            assistants. Please report any issues to the BrightBot team.
          </InfoMessageBot> */}
        </BotHeaderRight>

        <MainContent>{props.children}</MainContent>
        <Footer />
      </ChatContentWrapper>
    </>
  );
}
