import React, { useState } from "react";
import { DataNode } from "./DataNode";
import { WorkflowDataAssetGroupOutput } from "../generated";
import { Typography } from "../common/Typography/Typography";
import { Chip } from "../common/Chip/Chip";
import { DataBox } from "./DataBox";
import { getStatusFromWorkflowIssueStatus } from "./helpers";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AddInputDataAssetsModal } from "./AddInputDataAssetsModal/AddInputDataAssetsModal";

export interface InputNodeProps {
  data: WorkflowDataAssetGroupOutput & {
    onSubNodeClick: (id: string, parentId: string) => void;
  };
  selected?: boolean;
}

export const InputDataNode = ({
  data: { dataAssets, name, issueStatus, pii, id, onSubNodeClick, schemaId },
  selected,
  ...p
}: InputNodeProps) => {
  const status = getStatusFromWorkflowIssueStatus(issueStatus);
  const [inputDataModal, setInputDataModal] = useState(false);

  return (
    <>
      <DataNode
        id={id}
        selected={selected}
        title={name}
        issueStatus={issueStatus}
        containsPii={pii}
        accordionSummaryContent={
          <>
            <Typography mr={2} variant="body3">
              Input data assets
            </Typography>
            <Chip
              color={dataAssets.length === 0 ? "gray" : "grayAccent"}
              size="small"
              label={dataAssets.length || 0}
            />
          </>
        }
        accordionDetailsContent={
          <>
            {dataAssets.map((dataAsset, index) => (
              <DataBox
                key={index}
                mb={2}
                title={dataAsset?.name}
                caption={dataAsset?.ownerName}
                status={status}
                onSubNodeClick={() => {
                  onSubNodeClick(dataAsset?.id || "", id);
                }}
              />
            ))}
            <Button
              sx={{ mt: 1, height: "2.25rem" }}
              color="grayAccent"
              variant="outlined"
              fullWidth
              startIcon={<Add />}
              onClick={(event) => {
                event.preventDefault();
                setInputDataModal(true);
              }}
            >
              Add Input Data Asset
            </Button>
            {inputDataModal && (
              <AddInputDataAssetsModal
                show={inputDataModal}
                closeDialog={() => {
                  setInputDataModal(false);
                }}
                inputDataAssetGroupId={id}
                selectedIds={dataAssets?.map((item) => item?.id || "") || []}
                schemaId={schemaId as string}
              />
            )}
          </>
        }
      />
    </>
  );
};
