import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  BoxProps,
  Card,
  CardProps,
  Divider,
  useTheme,
} from "@mui/material";
import { BubbleChartOutlined, CorporateFare } from "@mui/icons-material";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { OverridableStringUnion } from "@mui/types";

import {
  convertIsoDateStringToMMDDYYYY,
  convertToTitleCase,
} from "../../helpers";
import {
  DuplicateProjectDocument,
  GetProjectQuery,
  ProjectStatus,
} from "../../generated";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Schema } from "react-markdown/lib/ast-to-react";
import { Typography } from "../../common/Typography/Typography";
import { LinesEllipsisTypography } from "../../common/LinesEllipsisTypography/LinesEllipsisTypography";
import { Chip } from "../../common/Chip/Chip";
import { ProjectCardDataRow } from "../../common/ProjectCard/ProjectCardDataRow";
import { Tag } from "../../common/Tag/Tag";
import { size } from "lodash";
import { CardWidth } from "./style";

interface SpecificCardProps {
  modifiedAt?: string;
  name?: string;
}

export enum Status {
  ACTIVE = "Active",
  DRAFT = "Draft",
  ARCHIVED = "Archived",
}

interface SpecificCardProps {
  id?: string;
  updatedDateText?: string;
  projectName?: string;
  cardProps?: CardProps;
  status: ProjectStatus;
  numberOfIssues?: number;
  description?: string;
  templateName?: string;
  inputAssetCount?: number;
  createdAssetCount?: number;
  ownerName?: string;
  cardSizeExpanded?: boolean;
  workspaceId?: string;
  workspaceName?: string;
  refetchProjectDocuments?: any;
}

export type ScehmaCardProps = SpecificCardProps & BoxProps;

export const SchemaCard = ({ schema, ...rest }: any) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { workspaceId, projectId } = useParams();

  let chipColor: OverridableStringUnion<
    "primary" | "secondary" | "error" | "info" | "success" | "warning",
    ChipPropsColorOverrides
  > = "primary";
  if (schema?.status === ProjectStatus.Draft) {
    chipColor = "grayAccent";
  } else if (schema?.status === ProjectStatus.Private) {
    chipColor = "gray";
  }

  const formattedDescription = schema?.description || "No Description";
  const width = "100%";
  const height = "100%";

  const getProjectManagers = (knowledgeSchema: any) => {
    const managers = knowledgeSchema?.managers;
    if (Array.isArray(managers)) {
      return managers.reduce((accum, manger) => {
        if (accum === "") {
          return `${manger.firstName || ""} ${manger.lastName || ""}`;
        } else {
          return `${accum}, ${manger.firstName || ""} ${manger.lastName || ""}`;
        }
      }, "");
    } else {
      return "";
    }
  };

  return (
    <CardWidth
      sx={{
        cursor: "pointer",
        position: "relative",
        boxSizing: "border-box",
        padding: "0 8px 15px",
      }}
      className="cardstyle"
      {...rest}
    >
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
        onClick={() =>
          navigate(`/workspace/${workspaceId}/govern/schemas/${schema?.id}`)
        }
        className="project-card"
        tabIndex={1}
        aria-labelledby={`${schema?.name}-title`}
        role="link"
      >
        <Card
          sx={{
            height,
            width,
            cursor: "pointer",
            boxShadow: "none",
            borderRadius: "6px",
            border: `1px solid ${palette.action.disabledBackground}`,
          }}
        >
          <Box
            sx={{
              px: 4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box mb={4} mt={4}>
              <Typography mb={1} color={palette.gray.dark} variant="caption">
                {`Updated: ${convertIsoDateStringToMMDDYYYY(
                  schema?.modifiedAt
                )}`}
              </Typography>

              <LinesEllipsisTypography
                id={`${schema?.name}-title`}
                mb={3}
                fontWeight={700}
                variant="h6"
                text={schema?.name}
                maxLines={2}
              />
              {schema?.types && size(schema?.types) > 1 && (
                <Chip
                  label={schema?.types}
                  color={schema?.types === "OUTPUT" ? "warning" : "primary"}
                />
              )}
              {schema?.status && (
                <Chip
                  label={schema?.status}
                  color={
                    schema?.status === "MAJOR"
                      ? "error"
                      : schema?.status === "MINOR"
                      ? "warning"
                      : "primary"
                  }
                />
              )}
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <ProjectCardDataRow
                icon={<CorporateFare />}
                name="Schema Owner"
                value={schema?.owner?.name}
                valueContainerProps={{
                  "aria-label": "owning-organization",
                  role: "label",
                }}
              />

              <LinesEllipsisTypography
                flexGrow={1}
                mt={1}
                mb={4}
                color={palette.gray.dark}
                variant="body3"
                text={formattedDescription}
                maxLines={2}
              />
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={1}
              mb={4}
            >
              {Array.isArray(schema?.tags) &&
                schema?.tags.map((value: any) => (
                  <Tag key={value} label={value} dismissable={false} />
                ))}
            </Box>
          </Box>
        </Card>
      </Box>
    </CardWidth>
  );
};
