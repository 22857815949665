import { Button, OutlinedInput, styled } from "@mui/material";
import { brighthiveTheme } from "../../../../theme/theme";

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 7px;
  box-shadow: none;
  min-width: 1px;
  width: fit-content;
  height: fit-content;
  padding: 0.6rem 0.8rem;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  & path {
      fill: white;
    },
  }
`;

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: brighthiveTheme.palette.common.white,
  borderRadius: "15px",
  border: "none",
  "[type='text']:focus": {
    boxShadow: "none",
  },
  boxShadow:
    "rgba(214, 234, 230, 1) 0px 10px 36px 0px, rgba(214, 234, 230, 0.06) 0px 0px 0px 1px;",
  ":hover": {
    boxShadow:
      "rgba(214, 234, 230, 1) 0px 10px 36px 0px, rgba(214, 234, 230, 1) 0px 0px 0px 1px;",
  },
}));
