import { styled } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";
export const SidebarSkeleton = styled("div")`
  position: fixed;
  width: 260px;
  background: white;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  overflow: hidden;
  padding: 10px 20px 0 10px;
  box-sizing: border-box;
  border-right: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
`;
export const SkeletonBar = styled("div")`
  height: 25px;
  position: relative;
  overflow: hidden;
  background-color: rgb(58, 67, 105, 0.08);
  border-radius: 4px;
  &.mainlink {
    margin: 0 0 35px;
    width: 130px;
    &.last {
      margin: 0 0 10px;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 3s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
 } 
`;
export const SidebarSkeletonlist = styled("ul")`
  list-style: none;
  margin: 0 -20px 0 -10px;
  padding: 0;
`;
export const SidebarSkeletonlistItem = styled("li")`
  padding: 14px 20px 16px 10px;
  border-top: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  &:first-child {
    border: none;
  }
`;
