import { styled, Typography } from "@mui/material";
import { brighthiveTheme } from "../../../../theme/theme";

export const ChatBotWidgetWrapper = styled("button")`
  background-color: ${brighthiveTheme.palette.common.white};
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: left;
  padding: 1rem;
  box-shadow: rgba(214, 234, 230, 1) 0px 10px 36px 0px,
    rgba(214, 234, 230, 0.06) 0px 0px 0px 1px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    transition: all 0.2s linear;
    & h2,
    p {
      color: ${brighthiveTheme.palette.common.white};
    }
  }
`;

export const ChatBotWidgetHeading = styled(Typography)`
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin-bottom: 0.7rem;
`;

export const ChatBotWidgetDescription = styled(Typography)`
  color: ${brighthiveTheme.palette.gray.darkest};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;

  &.black {
    color: ${brighthiveTheme.palette.common.black};
  }
`;
