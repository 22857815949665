import { useCallback, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Chat } from "./components/Chat";
import { ChatList } from "./components/ChatList";
import { Layout } from "./components/Layout";
import { NewChat } from "./components/NewChat";
import { useChatList, useWorkspaceChatList } from "./hooks/useChatList";
import { useSchemas } from "./hooks/useSchemas";
import { useStreamState } from "./hooks/useStreamState";
import {
  useConfigList,
  Config as ConfigInterface,
} from "./hooks/useConfigList";
import { Config } from "./components/Config";
import { MessageWithFiles } from "./utils/formTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useThreadAndAssistant } from "./hooks/useThreadAndAssistant";
import { Message } from "./types";
import { OrphanChat } from "./components/OrphanChat";
import bBaseApi from "./api/bBaseApi";
import { create } from "lodash";

const baseUrl = process.env.REACT_APP_BB_ASSISTANT_URL;

function BbAssistant() {
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { chats, createChat, updateChat, deleteChat } = useChatList();

  const {
    chats: workspaceChat,
    createChat: workspaceCreateChat,
    updateChat: workspaceUpdateChat,
    deleteChat: workspaceDeleteChat,
  } = useWorkspaceChatList();
  const { configs, saveConfig, deleteConfig } = useConfigList();
  const { startStream, stopStream, stream } = useStreamState();
  const { configSchema, configDefaults } = useSchemas();

  const edit = pathname.includes("edit");

  const { currentChat, assistantConfig, isLoading } = useThreadAndAssistant();

  const startTurn = useCallback(
    async (
      message: MessageWithFiles | null,
      thread_id: string,
      assistantType: string,
      config?: Record<string, unknown>
    ) => {
      const files = message?.files || [];
      if (files.length > 0) {
        const formData = files.reduce((formData, file) => {
          formData.append("files", file);
          return formData;
        }, new FormData());
        formData.append(
          "config",
          JSON.stringify({ configurable: { thread_id } })
        );
        await bBaseApi.post(`/ingest`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let input: Message[] | Record<string, any> | null = null;

      if (message) {
        // Set the input to an array of messages. This is the default input
        // format for all assistant types.
        input = [
          {
            content: message.message,
            additional_kwargs: {},
            type: "human",
            example: false,
            id: `human-${Math.random()}`,
          },
        ];

        if (assistantType === "chat_retrieval") {
          // The RAG assistant type requires an object with a `messages` field.
          input = {
            messages: input,
          };
        }
      }

      await startStream(input, thread_id, config);
    },
    [startStream]
  );

  const startChat = useCallback(
    async (config: ConfigInterface, message: MessageWithFiles) => {
      const chat = await createChat(message.message, config.assistant_id);
      navigate(
        `/workspace/${workspaceId}/brightbot-studio/thread/${chat.thread_id}`
      );
      const assistantType = config.config.configurable?.type as string;
      return startTurn(message, chat.thread_id, assistantType);
    },
    [createChat, navigate, startTurn, workspaceId]
  );

  const selectChat = useCallback(
    async (id: string | null) => {
      if (currentChat) {
        stopStream?.(true);
      }
      if (!id) {
        const firstAssistant = configs?.[0]?.assistant_id ?? null;
        navigate(
          firstAssistant
            ? `/workspace/${workspaceId}/brightbot-studio/assistant/${firstAssistant}`
            : `/workspace/${workspaceId}/brightbot-studio`
        );
        window.scrollTo({ top: 0 });
      } else {
        navigate(`/workspace/${workspaceId}/brightbot-studio/thread/${id}`);
      }
      // if (sidebarOpen) {
      //   setSidebarOpen(false);
      // }
    },
    [currentChat, sidebarOpen, stopStream, configs, navigate, workspaceId]
  );

  const selectConfig = useCallback(
    (id: string | null) => {
      navigate(
        id
          ? `/workspace/${workspaceId}/brightbot-studio/assistant/${id}`
          : `/workspace/${workspaceId}/brightbot-studio`
      );
    },
    [navigate]
  );

  return (
    <Layout
      subtitle={
        assistantConfig ? (
          <span className="inline-flex gap-1 items-center">
            {assistantConfig.name}
            <InformationCircleIcon
              className="h-5 w-5 cursor-pointer text-indigo-600"
              onClick={() => {
                selectConfig(assistantConfig.assistant_id);
              }}
            />
          </span>
        ) : null
      }
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      sidebar={
        <ChatList
          workspaceChat={workspaceChat}
          createChat={createChat}
          workspaceDeleteChat={workspaceDeleteChat}
          chats={chats}
          configs={configs}
          deleteConfig={deleteConfig}
          enterChat={selectChat}
          deleteChat={deleteChat}
          enterConfig={selectConfig}
        />
      }
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {currentChat && assistantConfig && (
            <Chat
              startStream={startTurn}
              stopStream={stopStream}
              stream={stream}
            />
          )}
          {currentChat && !assistantConfig && (
            <OrphanChat chat={currentChat} updateChat={updateChat} />
          )}
          {!currentChat && assistantConfig && !edit && (
            <NewChat
              startChat={startChat}
              configSchema={configSchema}
              configDefaults={configDefaults}
              configs={configs}
              saveConfig={saveConfig}
              enterConfig={selectConfig}
              deleteConfig={deleteConfig}
            />
          )}
          {!currentChat && assistantConfig && edit && (
            <Config
              className="mb-6"
              config={assistantConfig}
              configSchema={configSchema}
              configDefaults={configDefaults}
              saveConfig={saveConfig}
              enterConfig={selectConfig}
              edit={edit}
            />
          )}
          {!currentChat && !assistantConfig && !isLoading && (
            <Config
              className="mb-6"
              config={null}
              configSchema={configSchema}
              configDefaults={configDefaults}
              saveConfig={saveConfig}
              enterConfig={selectConfig}
            />
          )}
          {isLoading && <div>Loading...</div>}
        </>
      )}
    </Layout>
  );
}

export default BbAssistant;
