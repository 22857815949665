import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  EllipsisHorizontalIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import bBaseApi from "../api/bBaseApi";
import { ThumbsHolder } from "./style";

const baseUrl = process.env.REACT_APP_BB_ASSISTANT_URL;

export function LangSmithActions(props: { runId: string }) {
  const [state, setState] = useState<{
    score: number;
    inflight: boolean;
  } | null>(null);
  const sendFeedback = async (score: number) => {
    setState({ score, inflight: true });
    await bBaseApi.post(
      `/runs/feedback`,
      JSON.stringify({
        run_id: props.runId,
        key: "user_score",
        score: score,
      })
    );
    setState({ score, inflight: false });
  };
  return (
    <ThumbsHolder>
      <button type="button" onClick={() => sendFeedback(1)}>
        {state?.score === 1 ? (
          state?.inflight ? (
            <EllipsisHorizontalIcon aria-hidden="true" />
          ) : (
            <CheckIcon aria-hidden="true" />
          )
        ) : (
          <HandThumbUpIcon aria-hidden="true" />
        )}
      </button>
      <button type="button" onClick={() => sendFeedback(0)}>
        {state?.score === 0 ? (
          state?.inflight ? (
            <EllipsisHorizontalIcon aria-hidden="true" />
          ) : (
            <CheckIcon aria-hidden="true" />
          )
        ) : (
          <HandThumbDownIcon aria-hidden="true" />
        )}
      </button>
    </ThumbsHolder>
  );
}
