import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** Email Address custom scalar type */
  EmailAddress: any;
  JSON: any;
};

export type AcceptWorkspacePolicyInput = {
  workspaceId: Scalars["ID"];
};

export type AcceptWorkspacePolicyOutput = {
  __typename?: "AcceptWorkspacePolicyOutput";
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
};

export enum AccessMode {
  ReadOnly = "READ_ONLY",
  ReadWrite = "READ_WRITE",
}

export enum AccessStatus {
  Private = "PRIVATE",
  Restricted = "RESTRICTED",
  Shared = "SHARED",
}

export type AddContributingOrganizationToProjectInput = {
  organizationId: Scalars["ID"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type AddDataAssetsToInputDataAssetGroupInput = {
  dataAssetIds: Array<InputMaybe<Scalars["ID"]>>;
  inputDataAssetGroupId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type AddSourceInput = {
  connectionSchedule: AirbyteConnectionSchedule;
  description: Scalars["String"];
  documentationUrl: Scalars["String"];
  name: Scalars["String"];
  sourceConfigs: Scalars["String"];
  workspaceId: Scalars["ID"];
};

export type AdminOrganization = {
  __typename?: "AdminOrganization";
  adminGroup?: Maybe<OrganizationAdmin>;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  generalUserGroup?: Maybe<GeneralUser>;
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  systemAdminGroup?: Maybe<SystemAdmin>;
};

export type AirbyteConnectionSchedule = {
  cronExpression?: InputMaybe<Scalars["String"]>;
  scheduleType: AirbyteConnectionScheduleType;
};

export enum AirbyteConnectionScheduleType {
  Cron = "CRON",
  Manual = "MANUAL",
}

export type AssetPolicy = {
  __typename?: "AssetPolicy";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  href: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export enum AuthRole {
  DataAssetManager = "DATA_ASSET_MANAGER",
  ProjectManager = "PROJECT_MANAGER",
  WorkspaceAdmin = "WORKSPACE_ADMIN",
  WorkspaceCollaborator = "WORKSPACE_COLLABORATOR",
  WorkspaceContributor = "WORKSPACE_CONTRIBUTOR",
  WorkspaceViewer = "WORKSPACE_VIEWER",
}

export enum AuthWorkspaceRole {
  WorkspaceAdmin = "WORKSPACE_ADMIN",
  WorkspaceCollaborator = "WORKSPACE_COLLABORATOR",
  WorkspaceContributor = "WORKSPACE_CONTRIBUTOR",
  WorkspaceViewer = "WORKSPACE_VIEWER",
}

export type Collaborator = {
  __typename?: "Collaborator";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  policy?: Maybe<WorkspacePolicy>;
  users: Array<User>;
  workspace?: Maybe<Workspace>;
};

export type ConfirmForgotPasswordInput = {
  confirmationCode: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type ConfirmUserInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
  session: Scalars["String"];
  username: Scalars["String"];
};

export type CreateAssetPolicyInput = {
  description: Scalars["String"];
  href: Scalars["String"];
  name: Scalars["String"];
};

export type CreateCustomPolicyInput = {
  description: Scalars["String"];
  name: Scalars["String"];
  policyCategory: PolicyCategory;
  referenceDocuments: Array<InputMaybe<CreateReferenceDocumentInput>>;
};

export type CreateDataAssetInput = {
  workspaceId: Scalars["ID"];
};

export type CreateDataAssetOutput = {
  __typename?: "CreateDataAssetOutput";
  dataAssetId: Scalars["ID"];
  success: Scalars["Boolean"];
};

export type CreateDestinationInput = {
  dataStorage?: InputMaybe<WarehouseServiceProvider>;
  description: Scalars["String"];
  destinationType: DestinationType;
  destinationUrl?: InputMaybe<Scalars["String"]>;
  fileFormat?: InputMaybe<DownloadFileFormat>;
  name: Scalars["String"];
  organizationId?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  tableName?: InputMaybe<Scalars["String"]>;
  workspaceId: Scalars["ID"];
};

export type CreateDestinationOutput = {
  __typename?: "CreateDestinationOutput";
  destinationId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
};

export type CreateFinalDataProductGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  jsonSchema?: InputMaybe<Scalars["String"]>;
  method?: InputMaybe<FinalDataProductGroupCreationMethod>;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  targetSchemaId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type CreateFinalDataProductGroupOutput = {
  __typename?: "CreateFinalDataProductGroupOutput";
  finalDataProductGroupId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
  targetSchemaId?: Maybe<Scalars["ID"]>;
};

export type CreateGoalInput = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateGovernanceDocumentInput = {
  href: Scalars["String"];
  name: Scalars["String"];
};

export type CreateGovernanceGoalInput = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateGovernanceInput = {
  customPolicies: Array<InputMaybe<CreateGovernancePolicyInput>>;
  goals: Array<InputMaybe<CreateGovernanceGoalInput>>;
  governanceType: GovernanceType;
  legalDocuments: Array<InputMaybe<CreateGovernanceDocumentInput>>;
  monitoredPolicies: Array<InputMaybe<CreateGovernancePolicyInput>>;
  referenceDocuments: Array<InputMaybe<CreateGovernanceDocumentInput>>;
  workspaceId: Scalars["ID"];
};

export type CreateGovernancePolicyInput = {
  description: Scalars["String"];
  name: Scalars["String"];
  policyCategory: PolicyCategory;
  policyType: PolicyType;
  referenceDocuments: Array<InputMaybe<CreateGovernanceDocumentInput>>;
};

export type CreateIngestionServiceInput = {
  configurations: Scalars["String"];
  description: Scalars["String"];
  managerId: Scalars["ID"];
  name: Scalars["String"];
  provider: IngestionServiceProvider;
  workspaceId: Scalars["ID"];
};

export type CreateIngestionServiceOutput = {
  __typename?: "CreateIngestionServiceOutput";
  ingestionServiceId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
};

export type CreateInputDataAssetGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  jsonSchema?: InputMaybe<Scalars["String"]>;
  method: InputDataAssetGroupCreationMethod;
  name: Scalars["String"];
  projectId: Scalars["ID"];
  targetSchemaId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type CreateInputDataAssetGroupOutput = {
  __typename?: "CreateInputDataAssetGroupOutput";
  inputDataAssetGroupId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
  targetSchemaId?: Maybe<Scalars["ID"]>;
};

export type CreateLegalDocumentInput = {
  href: Scalars["String"];
  name: Scalars["String"];
};

export type CreateOrganizationInput = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateOrganizationOutput = {
  __typename?: "CreateOrganizationOutput";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type CreateProjectGoalInput = {
  description: Scalars["String"];
  metrics: Array<Scalars["String"]>;
};

export type CreateProjectInput = {
  workspaceId: Scalars["ID"];
};

export type CreateProjectOutput = {
  __typename?: "CreateProjectOutput";
  projectId: Scalars["ID"];
  success: Scalars["Boolean"];
};

export type CreateReferenceDocumentInput = {
  href: Scalars["String"];
  name: Scalars["String"];
};

export type CreateResourceFolderInput = {
  name: Scalars["String"];
  parentId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type CreateResourceFolderOutput = {
  __typename?: "CreateResourceFolderOutput";
  resourceFolderId: Scalars["ID"];
  success: Scalars["Boolean"];
};

export type CreateResourceInput = {
  description?: InputMaybe<Scalars["String"]>;
  internal?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  parentId?: InputMaybe<Scalars["ID"]>;
  path: Scalars["String"];
  tags?: InputMaybe<Array<Scalars["String"]>>;
  type: ResourceType;
  workspaceId: Scalars["ID"];
};

export type CreateResourceOutput = {
  __typename?: "CreateResourceOutput";
  resourceId: Scalars["ID"];
  success: Scalars["Boolean"];
};

export type CreateSchemaInput = {
  description?: InputMaybe<Scalars["String"]>;
  inputDataAssetGroupId?: InputMaybe<Scalars["ID"]>;
  jsonSchema: Scalars["String"];
  name: Scalars["String"];
  projectId?: InputMaybe<Scalars["ID"]>;
  scope: ScopeType;
  status?: InputMaybe<TargetSchemaStatus>;
  workspaceId: Scalars["ID"];
};

export type CreateSchemaOutput = {
  __typename?: "CreateSchemaOutput";
  schemaId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
};

export type CreateTargetSchemaInput = {
  description?: InputMaybe<Scalars["String"]>;
  inputDataAssetGroupId?: InputMaybe<Scalars["ID"]>;
  jsonSchema: Scalars["String"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  status?: InputMaybe<TargetSchemaStatus>;
  workspaceId: Scalars["ID"];
};

export type CreateTargetSchemaOutput = {
  __typename?: "CreateTargetSchemaOutput";
  success: Scalars["Boolean"];
  targetSchemaId?: Maybe<Scalars["ID"]>;
};

export type CreateTermInput = {
  description: Scalars["String"];
  name: Scalars["String"];
  scope: ScopeType;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  type: Scalars["String"];
  workspaceId: Scalars["ID"];
};

export type CreateTermOutput = {
  __typename?: "CreateTermOutput";
  success: Scalars["Boolean"];
};

export type CreateTransformationInput = {
  description: Scalars["String"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  sourceUrl?: InputMaybe<Scalars["String"]>;
  transformationMethod: TransformationMethod;
  workspaceId: Scalars["ID"];
};

export type CreateTransformationOutput = {
  __typename?: "CreateTransformationOutput";
  success: Scalars["Boolean"];
  transformationId?: Maybe<Scalars["ID"]>;
};

export type CreateTransformationServiceInput = {
  accountId: Scalars["String"];
  apiKey: Scalars["String"];
  description: Scalars["String"];
  managerId: Scalars["ID"];
  name: Scalars["String"];
  provider: TransformationServiceProvider;
  warehouseServiceId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type CreateTransformationServiceOutput = {
  __typename?: "CreateTransformationServiceOutput";
  success: Scalars["Boolean"];
  transformationServiceId?: Maybe<Scalars["ID"]>;
};

export type CreateUserInput = {
  emailAddress: Scalars["EmailAddress"];
  organizationId: Scalars["ID"];
  workspaceId: Scalars["ID"];
  workspaceRole?: InputMaybe<WorkspaceRoleEnum>;
};

export type CreateUserOrganizationInput = {
  connect?: InputMaybe<Scalars["ID"]>;
  create?: InputMaybe<CreateOrganizationInput>;
};

export type CreateUserOutput = {
  __typename?: "CreateUserOutput";
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
  id?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
};

export type CreateWarehouseServiceInput = {
  configurations: Scalars["String"];
  description: Scalars["String"];
  managed: Scalars["Boolean"];
  name: Scalars["String"];
  provider: WarehouseServiceProvider;
  workspaceId: Scalars["ID"];
};

export type CreateWarehouseServiceOutput = {
  __typename?: "CreateWarehouseServiceOutput";
  success: Scalars["Boolean"];
  warehouseServiceId?: Maybe<Scalars["ID"]>;
};

export type CreateWorkflowConnectionInput = {
  projectId: Scalars["ID"];
  sourceId: Scalars["ID"];
  sourceNodeType: WorkflowNodeType;
  targetId: Scalars["ID"];
  targetNodeType: WorkflowNodeType;
  workspaceId: Scalars["ID"];
};

export type CreateWorkspaceInput = {
  description: Scalars["String"];
  name: Scalars["String"];
  ownerOrganization: CreateWorkspaceOwnerOrganizationInput;
};

export type CreateWorkspaceOutput = {
  __typename?: "CreateWorkspaceOutput";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type CreateWorkspaceOwnerOrganizationInput = {
  description: Scalars["String"];
  member: CreateWorkspaceOwnerOrganizationMemberInput;
  name: Scalars["String"];
};

export type CreateWorkspaceOwnerOrganizationMemberInput = {
  emailAddress: Scalars["EmailAddress"];
};

export type CreatedDataProductFilterInput = {
  dataAssetId: Scalars["ID"];
};

export type CreatedFinalProduct = {
  __typename?: "CreatedFinalProduct";
  connectionType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  finalDataProductGroup?: Maybe<CreatedFinalProductFinalDataProductGroupOutput>;
  id: Scalars["ID"];
  managers: Array<FinalDataProductManagerOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: FinalDataProductOwnerOutput;
  piiTypes: Array<Scalars["String"]>;
  refreshRate?: Maybe<RefreshRate>;
  sensitivity?: Maybe<Sensitivity>;
  status: WorkflowIssueStatus;
  tags: Array<Scalars["String"]>;
};

export type CreatedFinalProductFinalDataProductGroupOutput = {
  __typename?: "CreatedFinalProductFinalDataProductGroupOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type CurrentUserOrganizationGroupOutput = {
  __typename?: "CurrentUserOrganizationGroupOutput";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  users: Array<CurrentUserOrganizationGroupUserOutput>;
};

export type CurrentUserOrganizationGroupUserOutput = {
  __typename?: "CurrentUserOrganizationGroupUserOutput";
  active: Scalars["Boolean"];
  createdAt: Scalars["String"];
  emailAddress: Scalars["EmailAddress"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  modifiedAt: Scalars["String"];
};

export type CurrentUserOrganizationMemberOutput = {
  __typename?: "CurrentUserOrganizationMemberOutput";
  assetManagedCount: Scalars["Int"];
  createdAt: Scalars["String"];
  emailAddress: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  organizationRole: OrganizationRoleEnum;
  projectManagedCount: Scalars["Int"];
  status: Scalars["Boolean"];
  workspaceCount: Scalars["Int"];
};

export type CurrentUserOrganizationOutput = {
  __typename?: "CurrentUserOrganizationOutput";
  adminGroup?: Maybe<CurrentUserOrganizationGroupOutput>;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  externalWorkspaces?: Maybe<Array<Maybe<CurrentUserWorkspaceOutput>>>;
  generalUserGroup?: Maybe<CurrentUserOrganizationGroupOutput>;
  id: Scalars["ID"];
  isEditable: Scalars["Boolean"];
  members: Array<CurrentUserOrganizationMemberOutput>;
  name: Scalars["String"];
  ownedWorkspaces?: Maybe<Array<Maybe<CurrentUserWorkspaceOutput>>>;
  terms?: Maybe<Array<TermOutput>>;
};

export type CurrentUserOutput = {
  __typename?: "CurrentUserOutput";
  emailAddress: Scalars["EmailAddress"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  organization?: Maybe<CurrentUserOrganizationOutput>;
  workspaceRole: WorkspaceRoleEnum;
  workspaces: Array<Maybe<CurrentUserWorkspaceOutput>>;
};

export type CurrentUserOutputWorkspaceRoleArgs = {
  input: CurrentUserWorkspaceRoleFilter;
};

export type CurrentUserOutputWorkspacesArgs = {
  currentUserWorkspaceFilter?: InputMaybe<CurrentUserWorkspaceFilterInput>;
};

export type CurrentUserWorkspaceFilterInput = {
  workspaceId: Scalars["ID"];
};

export type CurrentUserWorkspaceOutput = {
  __typename?: "CurrentUserWorkspaceOutput";
  acceptedPolicies: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  ownerOrganizationName: Scalars["String"];
  policies: Array<CurrentUserWorkspacePolicy>;
};

export type CurrentUserWorkspacePolicy = {
  __typename?: "CurrentUserWorkspacePolicy";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export type CurrentUserWorkspaceRoleFilter = {
  workspaceId: Scalars["ID"];
};

export type DataAsset = {
  __typename?: "DataAsset";
  accessStatus?: Maybe<AccessStatus>;
  assetPolicies?: Maybe<Array<Maybe<AssetPolicy>>>;
  assetSize?: Maybe<Scalars["String"]>;
  childAssetCount?: Maybe<Scalars["Int"]>;
  connectionDescription?: Maybe<Scalars["String"]>;
  connectionType?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<Maybe<Field>>>;
  id: Scalars["ID"];
  isEditable: Scalars["Boolean"];
  issues?: Maybe<Array<Maybe<Issue>>>;
  managers?: Maybe<Array<Maybe<UserDataAssetOutput>>>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<DataAssetOwnerDataAssetOutput>;
  piiTypes?: Maybe<Array<PiiType>>;
  projectCount?: Maybe<Scalars["Int"]>;
  projects?: Maybe<Array<DataAssetProjectOutput>>;
  redshiftTableName?: Maybe<Scalars["String"]>;
  refreshRate?: Maybe<RefreshRate>;
  refreshStatus?: Maybe<RefreshStatus>;
  sensitivity?: Maybe<Sensitivity>;
  snowflakeTableName?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  storageService?: Maybe<Scalars["String"]>;
  tableName?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  validationStatus?: Maybe<ValidationStatus>;
};

export type DataAssetEntityInput = {
  dataAssetId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DataAssetFilterInput = {
  dataAssetId: Scalars["ID"];
};

export type DataAssetInput = {
  dataAssetId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DataAssetOutput = {
  __typename?: "DataAssetOutput";
  accessStatus: AccessStatus;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  manager: UserOutput;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: DataAssetOwnerOutput;
  sensitivity: Sensitivity;
};

export type DataAssetOwnerDataAssetOutput =
  | OrganizationDataAssetOutput
  | ProjectDataAssetOutput;

export type DataAssetOwnerOutput = OrganizationOutput | ProjectOutput;

export type DataAssetProjectOutput = {
  __typename?: "DataAssetProjectOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type DataAssetsInput = {
  workspaceId: Scalars["ID"];
};

export enum DataProductType {
  FinalDataProduct = "FINAL_DATA_PRODUCT",
  Poc = "POC",
}

export enum DataType {
  Array = "ARRAY",
  Bigint = "BIGINT",
  Binary = "BINARY",
  Blob = "BLOB",
  Boolean = "BOOLEAN",
  Byteint = "BYTEINT",
  Bytes = "BYTES",
  Char = "CHAR",
  Date = "DATE",
  Datetime = "DATETIME",
  Decimal = "DECIMAL",
  Double = "DOUBLE",
  Enum = "ENUM",
  Float = "FLOAT",
  Geography = "GEOGRAPHY",
  Int = "INT",
  Interval = "INTERVAL",
  Json = "JSON",
  Longblob = "LONGBLOB",
  Map = "MAP",
  Mediumblob = "MEDIUMBLOB",
  Mediumtext = "MEDIUMTEXT",
  Number = "NUMBER",
  Numeric = "NUMERIC",
  Set = "SET",
  Smallint = "SMALLINT",
  String = "STRING",
  Struct = "STRUCT",
  Text = "TEXT",
  Time = "TIME",
  Timestamp = "TIMESTAMP",
  Tinyint = "TINYINT",
  Union = "UNION",
  Varbinary = "VARBINARY",
  Varchar = "VARCHAR",
}

export type DeactivateOrganizationUserInput = {
  userId: Scalars["String"];
};

export type DeactivateOrganizationUserOutput = {
  __typename?: "DeactivateOrganizationUserOutput";
  success: Scalars["Boolean"];
};

export type DeleteDestinationInput = {
  destinationId: Scalars["ID"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DeleteDestinationOutput = {
  __typename?: "DeleteDestinationOutput";
  success: Scalars["Boolean"];
};

export type DeleteFinalDataProductGroupInput = {
  finalDataProductGroupId: Scalars["ID"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DeleteFinalDataProductGroupOutput = {
  __typename?: "DeleteFinalDataProductGroupOutput";
  success: Scalars["Boolean"];
};

export type DeleteInputDataAssetGroupInput = {
  inputDataAssetGroupId: Scalars["ID"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DeleteInputDataAssetGroupOutput = {
  __typename?: "DeleteInputDataAssetGroupOutput";
  success: Scalars["Boolean"];
};

export type DeleteResourceInput = {
  resourceId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DeleteResourceOutput = {
  __typename?: "DeleteResourceOutput";
  success: Scalars["Boolean"];
};

export type DeleteTermInput = {
  termId: Scalars["ID"];
};

export type DeleteTermOutput = {
  __typename?: "DeleteTermOutput";
  success: Scalars["Boolean"];
};

export enum DestinationType {
  Api = "API",
  Connect = "CONNECT",
  Download = "DOWNLOAD",
  Etl = "ETL",
}

export enum Directory {
  Resource = "RESOURCE",
}

export type DisableUserInput = {
  userId: Scalars["String"];
  workspaceId: Scalars["ID"];
};

export type DisableUserOutput = {
  __typename?: "DisableUserOutput";
  success: Scalars["Boolean"];
};

export type Document = {
  __typename?: "Document";
  createdAt: Scalars["String"];
  href: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export type DownloadFileDestinationInput = {
  destinationId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type DownloadFileDestinationOutput = {
  __typename?: "DownloadFileDestinationOutput";
  destinationId?: Maybe<Scalars["ID"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export enum DownloadFileFormat {
  Csv = "CSV",
  Json = "JSON",
  Parquet = "PARQUET",
}

export type DuplicateProjectInput = {
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type EntityOutput = {
  __typename?: "EntityOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Field = {
  __typename?: "Field";
  dataType: DataType;
  description: Scalars["String"];
  jsonSchema: Scalars["String"];
  name: Scalars["String"];
  pii: PiiType;
};

export enum FinalDataProductGroupCreationMethod {
  JsonUpload = "JSON_UPLOAD",
  NoSchema = "NO_SCHEMA",
  SelectSchema = "SELECT_SCHEMA",
}

export type FinalDataProductManagerOutput = {
  __typename?: "FinalDataProductManagerOutput";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type FinalDataProductOwnerOutput = {
  __typename?: "FinalDataProductOwnerOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ForgotPasswordInput = {
  username: Scalars["String"];
};

export type GeneralUser = {
  __typename?: "GeneralUser";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  policy?: Maybe<OrganizationPolicy>;
  users: Array<User>;
};

export type Goal = {
  __typename?: "Goal";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  metrics: Array<Scalars["String"]>;
  modifiedAt: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type Governance = {
  __typename?: "Governance";
  customPolicies: Array<Maybe<Policy>>;
  goals: Array<Maybe<Goal>>;
  governanceType: GovernanceType;
  id: Scalars["ID"];
  isEditable: Scalars["Boolean"];
  legalDocuments: Array<Maybe<Document>>;
  monitoredPolicies: Array<Maybe<Policy>>;
  referenceDocuments: Array<Maybe<Document>>;
};

export enum GovernanceType {
  Custom = "CUSTOM",
  DataCollaborative = "DATA_COLLABORATIVE",
  DataCommons = "DATA_COMMONS",
  DataExchange = "DATA_EXCHANGE",
  DataMarketplace = "DATA_MARKETPLACE",
  DataPartnership = "DATA_PARTNERSHIP",
  DataTrust = "DATA_TRUST",
}

export type HardDeleteUserInput = {
  userId: Scalars["ID"];
};

export type HealthOutput = {
  __typename?: "HealthOutput";
  openmetadata: Scalars["Boolean"];
};

export type IngestionServiceFilterInput = {
  ingestionServiceId: Scalars["ID"];
};

export type IngestionServiceOutput = {
  __typename?: "IngestionServiceOutput";
  configurations: Scalars["String"];
  createdAt: Scalars["String"];
  dataAssetCount: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  manager?: Maybe<ServiceManagerOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: ServiceEntityOutput;
  provider: IngestionServiceProvider;
  sourceCount: Scalars["Int"];
  status: ServiceStatus;
};

export enum IngestionServiceProvider {
  AirbyteCloud = "AIRBYTE_CLOUD",
  AirbyteOss = "AIRBYTE_OSS",
}

export enum IngestionServiceStatus {
  Connected = "CONNECTED",
  ConnectionFailed = "CONNECTION_FAILED",
  Unknown = "UNKNOWN",
}

export type InputDataAsset = {
  __typename?: "InputDataAsset";
  connectionType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inputDataAssetGroup: InputDataAssetInputDataAssetGroupOutput;
  managers: Array<InputDataAssetManagerOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: InputDataAssetOwnerOutput;
  piiTypes: Array<Scalars["String"]>;
  refreshRate?: Maybe<RefreshRate>;
  sensitivity?: Maybe<Sensitivity>;
  status: WorkflowIssueStatus;
  tags: Array<Scalars["String"]>;
};

export type InputDataAssetFilterInput = {
  dataAssetId?: InputMaybe<Scalars["ID"]>;
  organizationIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum InputDataAssetGroupCreationMethod {
  JsonUpload = "JSON_UPLOAD",
  NoSchema = "NO_SCHEMA",
  SelectSchema = "SELECT_SCHEMA",
}

export type InputDataAssetInputDataAssetGroupOutput = {
  __typename?: "InputDataAssetInputDataAssetGroupOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type InputDataAssetManagerOutput = {
  __typename?: "InputDataAssetManagerOutput";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type InputDataAssetOwnerOutput = {
  __typename?: "InputDataAssetOwnerOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Issue = {
  __typename?: "Issue";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  issueType: IssueType;
  modifiedAt: Scalars["String"];
  status: Scalars["String"];
};

export enum IssueStatus {
  Active = "ACTIVE",
  Resolved = "RESOLVED",
}

export enum IssueType {
  ComplianceIssue = "COMPLIANCE_ISSUE",
  ConnectionIssue = "CONNECTION_ISSUE",
  QualityIssue = "QUALITY_ISSUE",
  ValidationIssue = "VALIDATION_ISSUE",
}

export enum JobState {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Other = "OTHER",
  Queued = "QUEUED",
}

export type LoginChallengeResponse = {
  __typename?: "LoginChallengeResponse";
  name: Scalars["String"];
  organizationName?: Maybe<Scalars["String"]>;
  session?: Maybe<Scalars["String"]>;
};

export type LoginInput = {
  password: Scalars["String"];
  username: Scalars["EmailAddress"];
};

export type LoginOutput = {
  __typename?: "LoginOutput";
  accessToken?: Maybe<Scalars["String"]>;
  challengeResponse?: Maybe<LoginChallengeResponse>;
  idToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
  userToken?: Maybe<Scalars["String"]>;
};

export type LogoUploadContext = {
  __typename?: "LogoUploadContext";
  contentType: Scalars["String"];
  fields: Scalars["String"];
  url: Scalars["String"];
};

export type Member = {
  __typename?: "Member";
  createdAt: Scalars["String"];
  emailAddress: Scalars["EmailAddress"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isReinvitable: Scalars["Boolean"];
  lastAccessed?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  modifiedAt: Scalars["String"];
  organizationName: Scalars["String"];
  projectsContributed: Array<MemberProjectsContributed>;
  status: MemberStatus;
  workspaceRole: WorkspaceRoleEnum;
};

export type MemberProjectsContributed = {
  __typename?: "MemberProjectsContributed";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum MemberStatus {
  Active = "ACTIVE",
  Deactivated = "DEACTIVATED",
  Pending = "PENDING",
}

export type MoveResourceInput = {
  parentId: Scalars["ID"];
  resourceId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type Mutation = {
  __typename?: "Mutation";
  acceptWorkspacePolicy: AcceptWorkspacePolicyOutput;
  addContributingOrganizationToProject: Scalars["Boolean"];
  addDataAssetsToInputDataAssetGroup: Scalars["Boolean"];
  addSchemaToProject: UpdateOutput;
  addSchemaToResource: UpdateOutput;
  addSource: Scalars["Boolean"];
  addTermToResource: UpdateOutput;
  addTermToSchema: UpdateOutput;
  confirmForgotPassword: Scalars["Boolean"];
  confirmUser: LoginOutput;
  createDestination: CreateDestinationOutput;
  createFinalDataProductGroup: CreateFinalDataProductGroupOutput;
  createIngestionService: CreateIngestionServiceOutput;
  createInputDataAssetGroup: CreateInputDataAssetGroupOutput;
  createProject: CreateProjectOutput;
  createResource: CreateResourceOutput;
  createResourceFolder: CreateResourceFolderOutput;
  createSchema: CreateSchemaOutput;
  createTargetSchema: CreateTargetSchemaOutput;
  createTerm: CreateTermOutput;
  createTransformation: CreateTransformationOutput;
  createTransformationService: CreateTransformationServiceOutput;
  createUser: CreateUserOutput;
  createWarehouseService: CreateWarehouseServiceOutput;
  createWorkflowConnection: Scalars["Boolean"];
  deactivateOrganizationUser: DeactivateOrganizationUserOutput;
  deleteDestination: DeleteDestinationOutput;
  deleteFinalDataProductGroup: DeleteFinalDataProductGroupOutput;
  deleteInputDataAssetGroup: DeleteInputDataAssetGroupOutput;
  deleteResource: DeleteResourceOutput;
  deleteTerm: DeleteTermOutput;
  disableUser: DisableUserOutput;
  downloadFileDestination: DownloadFileDestinationOutput;
  duplicateProject: Scalars["Boolean"];
  forgotPassword: Scalars["Boolean"];
  hardDeleteUser: Scalars["Boolean"];
  login: LoginOutput;
  moveResource: UpdateOutput;
  onboardDataAssetToInputDataAssetGroup: OnboardDataAssetToInputDataAssetGroupOutput;
  onboardDataAssetToProject: OnboardDataAssetToProjectOutput;
  onboardDataAssetToWorkspace: OnboardDataAssetToWorkspaceOutput;
  refreshToken: RefreshTokenOutput;
  removeDataAssetFromFinalDataProductGroup: Scalars["Boolean"];
  removeDataAssetFromInputDataAssetGroup: Scalars["Boolean"];
  removeSchemaFromResource: UpdateOutput;
  removeTargetSchemaFromProject: Scalars["Boolean"];
  removeTermFromResource: UpdateOutput;
  removeTermFromSchema: UpdateOutput;
  removeWorkflowConnection: Scalars["Boolean"];
  resendInvite: Scalars["Boolean"];
  runTransformationsInProject: Scalars["Boolean"];
  updateDataAsset: Scalars["Boolean"];
  updateDataAssetFile: UpdateDataAssetFileOutput;
  updateDestination: Scalars["Boolean"];
  updateFinalDataProductGroup: UpdateFinalDataProductGroupOutput;
  updateGovernance: Scalars["Boolean"];
  updateInputDataAssetGroup: UpdateInputDataAssetGroupOutput;
  updateProject: Scalars["Boolean"];
  updateResource: UpdateOutput;
  updateRole: Scalars["Boolean"];
  updateSchema: Scalars["Boolean"];
  updateTargetSchema: UpdateTargetSchemaOutput;
  updateTerm: UpdateOutput;
  updateTransformation: Scalars["Boolean"];
  updateUserDetails: UpdateOutput;
  updateUserOrganizationRole: Scalars["Boolean"];
  updateWorkspace: UpdateWorkspaceOutput;
  updateWorkspaceRoleScope: Scalars["Boolean"];
  updateWorkspaceSchema: UpdateOutput;
  validateTransformationsInProject: Scalars["Boolean"];
};

export type MutationAcceptWorkspacePolicyArgs = {
  input: AcceptWorkspacePolicyInput;
};

export type MutationAddContributingOrganizationToProjectArgs = {
  input: AddContributingOrganizationToProjectInput;
};

export type MutationAddDataAssetsToInputDataAssetGroupArgs = {
  input: AddDataAssetsToInputDataAssetGroupInput;
};

export type MutationAddSchemaToProjectArgs = {
  input: SchemaProjectConnectInput;
};

export type MutationAddSchemaToResourceArgs = {
  input: SchemaResourceConnectInput;
};

export type MutationAddSourceArgs = {
  input: AddSourceInput;
};

export type MutationAddTermToResourceArgs = {
  input: TermResourceConnectInput;
};

export type MutationAddTermToSchemaArgs = {
  input: TermSchemaConnectInput;
};

export type MutationConfirmForgotPasswordArgs = {
  input: ConfirmForgotPasswordInput;
};

export type MutationConfirmUserArgs = {
  input: ConfirmUserInput;
};

export type MutationCreateDestinationArgs = {
  input: CreateDestinationInput;
};

export type MutationCreateFinalDataProductGroupArgs = {
  input: CreateFinalDataProductGroupInput;
};

export type MutationCreateIngestionServiceArgs = {
  input: CreateIngestionServiceInput;
};

export type MutationCreateInputDataAssetGroupArgs = {
  input: CreateInputDataAssetGroupInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateResourceFolderArgs = {
  input: CreateResourceFolderInput;
};

export type MutationCreateSchemaArgs = {
  input: CreateSchemaInput;
};

export type MutationCreateTargetSchemaArgs = {
  input: CreateTargetSchemaInput;
};

export type MutationCreateTermArgs = {
  input: CreateTermInput;
};

export type MutationCreateTransformationArgs = {
  input: CreateTransformationInput;
};

export type MutationCreateTransformationServiceArgs = {
  input: CreateTransformationServiceInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWarehouseServiceArgs = {
  input: CreateWarehouseServiceInput;
};

export type MutationCreateWorkflowConnectionArgs = {
  input: CreateWorkflowConnectionInput;
};

export type MutationDeactivateOrganizationUserArgs = {
  input: DeactivateOrganizationUserInput;
};

export type MutationDeleteDestinationArgs = {
  input: DeleteDestinationInput;
};

export type MutationDeleteFinalDataProductGroupArgs = {
  input: DeleteFinalDataProductGroupInput;
};

export type MutationDeleteInputDataAssetGroupArgs = {
  input: DeleteInputDataAssetGroupInput;
};

export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};

export type MutationDeleteTermArgs = {
  input: DeleteTermInput;
};

export type MutationDisableUserArgs = {
  input: DisableUserInput;
};

export type MutationDownloadFileDestinationArgs = {
  input: DownloadFileDestinationInput;
};

export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationHardDeleteUserArgs = {
  input: HardDeleteUserInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMoveResourceArgs = {
  input: MoveResourceInput;
};

export type MutationOnboardDataAssetToInputDataAssetGroupArgs = {
  input: OnboardDataAssetToInputDataAssetGroupInput;
};

export type MutationOnboardDataAssetToProjectArgs = {
  input: OnboardDataAssetToProjectInput;
};

export type MutationOnboardDataAssetToWorkspaceArgs = {
  input: OnboardDataAssetToWorkspaceInput;
};

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationRemoveDataAssetFromFinalDataProductGroupArgs = {
  input: RemoveDataAssetFromFinalDataProductGroupInput;
};

export type MutationRemoveDataAssetFromInputDataAssetGroupArgs = {
  input: RemoveDataAssetFromInputDataAssetGroupInput;
};

export type MutationRemoveSchemaFromResourceArgs = {
  input: SchemaResourceConnectInput;
};

export type MutationRemoveTargetSchemaFromProjectArgs = {
  input: RemoveTargetSchemaFromProjectInput;
};

export type MutationRemoveTermFromResourceArgs = {
  input: TermResourceConnectInput;
};

export type MutationRemoveTermFromSchemaArgs = {
  input: TermSchemaConnectInput;
};

export type MutationRemoveWorkflowConnectionArgs = {
  input: RemoveWorkflowConnectionInput;
};

export type MutationResendInviteArgs = {
  input: ResendInviteInput;
};

export type MutationRunTransformationsInProjectArgs = {
  input: RunTransformationsInProjectInput;
};

export type MutationUpdateDataAssetArgs = {
  input: UpdateDataAssetInput;
};

export type MutationUpdateDataAssetFileArgs = {
  input: UpdateDataAssetFileInput;
};

export type MutationUpdateDestinationArgs = {
  input: UpdateDestinationInput;
};

export type MutationUpdateFinalDataProductGroupArgs = {
  input: UpdateFinalDataProductGroupInput;
};

export type MutationUpdateGovernanceArgs = {
  input: UpdateGovernanceInput;
};

export type MutationUpdateInputDataAssetGroupArgs = {
  input: UpdateInputDataAssetGroupInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateSchemaArgs = {
  input: UpdateSchemaInput;
};

export type MutationUpdateTargetSchemaArgs = {
  input: UpdateTargetSchemaInput;
};

export type MutationUpdateTermArgs = {
  input: UpdateTermInput;
};

export type MutationUpdateTransformationArgs = {
  input: UpdateTransformationInput;
};

export type MutationUpdateUserDetailsArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserOrganizationRoleArgs = {
  input: UpdateOrganizationRoleInput;
};

export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};

export type MutationUpdateWorkspaceRoleScopeArgs = {
  input: UpdateWorkspaceRoleScopeInput;
};

export type MutationUpdateWorkspaceSchemaArgs = {
  input: UpdateWorkspaceSchemaInput;
};

export type MutationValidateTransformationsInProjectArgs = {
  input: ValidateTransformationsInProjectInput;
};

export type OnboardDataAssetToInputDataAssetGroupInput = {
  inputDataAssetGroupId: Scalars["ID"];
  name: Scalars["String"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type OnboardDataAssetToInputDataAssetGroupOutput = {
  __typename?: "OnboardDataAssetToInputDataAssetGroupOutput";
  dataAssetId?: Maybe<Scalars["ID"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type OnboardDataAssetToProjectInput = {
  name: Scalars["String"];
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type OnboardDataAssetToProjectOutput = {
  __typename?: "OnboardDataAssetToProjectOutput";
  dataAssetId?: Maybe<Scalars["ID"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type OnboardDataAssetToWorkspaceInput = {
  name: Scalars["String"];
  workspaceId: Scalars["ID"];
};

export type OnboardDataAssetToWorkspaceOutput = {
  __typename?: "OnboardDataAssetToWorkspaceOutput";
  dataAssetId?: Maybe<Scalars["ID"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type Organization = {
  __typename?: "Organization";
  adminGroup?: Maybe<OrganizationAdmin>;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  externalWorkspaces: Array<Workspace>;
  generalUserGroup?: Maybe<GeneralUser>;
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  ownedDataAssets: Array<DataAsset>;
  ownedWorkspaces: Array<Workspace>;
};

export type OrganizationAdmin = {
  __typename?: "OrganizationAdmin";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  policy?: Maybe<OrganizationPolicy>;
  users: Array<User>;
};

export type OrganizationDataAssetOutput = {
  __typename?: "OrganizationDataAssetOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OrganizationFilterInput = {
  organizationId: Scalars["ID"];
};

export type OrganizationOutput = {
  __typename?: "OrganizationOutput";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  domain: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export type OrganizationPolicy = {
  __typename?: "OrganizationPolicy";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  memberCreate: Scalars["Boolean"];
  memberDelete: Scalars["Boolean"];
  memberRead: Scalars["Boolean"];
  memberUpdate: Scalars["Boolean"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  orgCreate: Scalars["Boolean"];
  orgDelete: Scalars["Boolean"];
  orgRead: Scalars["Boolean"];
  orgUpdate: Scalars["Boolean"];
  organizationRole?: Maybe<OrganizationRole>;
  userRead: Scalars["Boolean"];
  workspaceCreate: Scalars["Boolean"];
};

export type OrganizationRole = GeneralUser | OrganizationAdmin;

export enum OrganizationRoleEnum {
  Admin = "ADMIN",
  GeneralUser = "GENERAL_USER",
}

export enum PiiTagType {
  None = "NONE",
  NonSensitive = "NON_SENSITIVE",
  Sensitive = "SENSITIVE",
}

export enum PiiType {
  None = "NONE",
  NonSensitive = "NON_SENSITIVE",
  Sensitive = "SENSITIVE",
}

export type Policy = {
  __typename?: "Policy";
  addedBy: PolicyEditor;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  policyCategory: PolicyCategory;
  referenceDocuments?: Maybe<Array<Maybe<Document>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export enum PolicyCategory {
  Access = "ACCESS",
  Other = "OTHER",
  Privacy = "PRIVACY",
  Quality = "QUALITY",
  Security = "SECURITY",
  Transparency = "TRANSPARENCY",
  Use = "USE",
}

export type PolicyEditor = {
  __typename?: "PolicyEditor";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export enum PolicyType {
  Custom = "CUSTOM",
  Monitored = "MONITORED",
}

export type PreSignedUrlInput = {
  access: AccessMode;
  directory: Directory;
  name: Scalars["String"];
  readInfo?: InputMaybe<PreSignedUrlReadInput>;
  workspaceId?: InputMaybe<Scalars["ID"]>;
  writeInfo?: InputMaybe<PreSignedUrlWriteInput>;
};

export type PreSignedUrlOutput = {
  __typename?: "PreSignedUrlOutput";
  key?: Maybe<Scalars["String"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type PreSignedUrlReadInput = {
  key: Scalars["String"];
};

export type PreSignedUrlWriteInput = {
  fileExt: Scalars["String"];
  uploaderProvider: Scalars["String"];
};

export type Project = {
  __typename?: "Project";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  endDate: Scalars["String"];
  id: Scalars["ID"];
  managers: Array<User>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  ownedDataAssets: Array<DataAsset>;
  startDate: Scalars["String"];
  workspace: Workspace;
};

export type ProjectCollaboratingMember = {
  __typename?: "ProjectCollaboratingMember";
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
};

export type ProjectDataAssetOutput = {
  __typename?: "ProjectDataAssetOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ProjectDestinationOutput = {
  __typename?: "ProjectDestinationOutput";
  description?: Maybe<Scalars["String"]>;
  destinationType: DestinationType;
  destinationUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ProjectEntityInput = {
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type ProjectOrganizationContributor = {
  __typename?: "ProjectOrganizationContributor";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  isProjectManager: Scalars["Boolean"];
  lastName: Scalars["String"];
};

export type ProjectOrganizationOutput = {
  __typename?: "ProjectOrganizationOutput";
  contributors: Array<Maybe<ProjectOrganizationContributor>>;
  dataProductCount: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inputDataAssetCount: Scalars["Int"];
  issueState?: Maybe<WorkflowIssueStatus>;
  name: Scalars["String"];
  projectRole: ProjectOrganizationRole;
};

export enum ProjectOrganizationRole {
  CollaboratingOrganization = "COLLABORATING_ORGANIZATION",
  ProjectOwner = "PROJECT_OWNER",
}

export type ProjectOutput = {
  __typename?: "ProjectOutput";
  backgroundMaterials: Array<Maybe<Document>>;
  collaboratingMembers: Array<ProjectCollaboratingMember>;
  collaboratingOrganizations: Array<Scalars["String"]>;
  createdAt: Scalars["String"];
  createdDataProducts: Array<CreatedFinalProduct>;
  dataConsumers: Array<Scalars["String"]>;
  dataProductCount: Scalars["Int"];
  dataProductType: DataProductType;
  description?: Maybe<Scalars["String"]>;
  destinations: Array<Maybe<ProjectDestinationOutput>>;
  endDate?: Maybe<Scalars["String"]>;
  goals: Array<Goal>;
  id: Scalars["ID"];
  inputAssetCount: Scalars["Int"];
  inputDataAssets: Array<InputDataAsset>;
  isEditable: Scalars["Boolean"];
  issueCount: Scalars["Int"];
  issueState: WorkflowIssueStatus;
  managers: Array<UserOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  organizations: Array<Maybe<ProjectOrganizationOutput>>;
  owner: OrganizationOutput;
  sensitivity: Array<Sensitivity>;
  startDate?: Maybe<Scalars["String"]>;
  status: ProjectStatus;
  tags: Array<Scalars["String"]>;
  targetSchemas: Array<TargetSchema>;
  template?: Maybe<ProjectTemplateOutput>;
  workflowState: WorkflowState;
};

export type ProjectOutputCreatedDataProductsArgs = {
  createdDataProductFilter?: InputMaybe<CreatedDataProductFilterInput>;
};

export type ProjectOutputInputDataAssetsArgs = {
  inputDataAssetFilter?: InputMaybe<InputDataAssetFilterInput>;
};

export type ProjectOutputOrganizationsArgs = {
  organizationFilter?: InputMaybe<OrganizationFilterInput>;
};

export type ProjectOutputTargetSchemasArgs = {
  targetSchemaFilter?: InputMaybe<TargetSchemaFilterInput>;
};

export enum ProjectStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Complete = "COMPLETE",
  Draft = "DRAFT",
  Private = "PRIVATE",
  Published = "PUBLISHED",
}

export type ProjectTemplateOutput = {
  __typename?: "ProjectTemplateOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum ProjectUsage {
  InUsage = "IN_USAGE",
}

export type Query = {
  __typename?: "Query";
  currentUser: CurrentUserOutput;
  dataAssetManagerCandidates: Array<EntityOutput>;
  exportCSV?: Maybe<Scalars["String"]>;
  health: HealthOutput;
  preSignedUrl: PreSignedUrlOutput;
  projectManagerCandidates: Array<EntityOutput>;
  projectOwnerCandidates: Array<EntityOutput>;
  refreshUserToken: RefreshUserTokenOutput;
  workflow?: Maybe<Workflow>;
  workspace?: Maybe<Workspace>;
};

export type QueryDataAssetManagerCandidatesArgs = {
  input: DataAssetEntityInput;
};

export type QueryExportCsvArgs = {
  data: Array<Scalars["JSON"]>;
};

export type QueryPreSignedUrlArgs = {
  input: PreSignedUrlInput;
};

export type QueryProjectManagerCandidatesArgs = {
  input: ProjectEntityInput;
};

export type QueryProjectOwnerCandidatesArgs = {
  input: ProjectEntityInput;
};

export type QueryWorkflowArgs = {
  workflowFilterInput: WorkflowFilterInput;
};

export type QueryWorkspaceArgs = {
  input: WorkspaceInput;
};

export enum RefreshRate {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  None = "NONE",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export enum RefreshStatus {
  Current = "CURRENT",
  OutOfDate = "OUT_OF_DATE",
}

export type RefreshTokenInput = {
  refreshToken: Scalars["String"];
};

export type RefreshTokenOutput = {
  __typename?: "RefreshTokenOutput";
  accessToken?: Maybe<Scalars["String"]>;
  idToken?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type RefreshUserTokenOutput = {
  __typename?: "RefreshUserTokenOutput";
  success: Scalars["Boolean"];
  userToken?: Maybe<Scalars["String"]>;
};

export type RemoveDataAssetFromFinalDataProductGroupInput = {
  dataAssetId: Scalars["ID"];
  finalDataProductGroupId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type RemoveDataAssetFromInputDataAssetGroupInput = {
  dataAssetId: Scalars["ID"];
  inputDataAssetGroupId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type RemoveTargetSchemaFromProjectInput = {
  projectId: Scalars["ID"];
  targetSchemaId: Scalars["ID"];
};

export type RemoveWorkflowConnectionInput = {
  projectId: Scalars["ID"];
  sourceId: Scalars["ID"];
  sourceNodeType: WorkflowNodeType;
  targetId: Scalars["ID"];
  targetNodeType: WorkflowNodeType;
  workspaceId: Scalars["ID"];
};

export type ResendInviteInput = {
  userId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type ResourceFolderOutput = {
  __typename?: "ResourceFolderOutput";
  createdAt: Scalars["String"];
  creator: UserOutput;
  id: Scalars["ID"];
  isFolder: Scalars["Boolean"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export type ResourceOrFoldersOutput = ResourceFolderOutput | ResourceOutput;

export type ResourceOutput = {
  __typename?: "ResourceOutput";
  createdAt: Scalars["String"];
  creator: UserOutput;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  internal: Scalars["Boolean"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  path: Scalars["String"];
  schemas?: Maybe<Array<TargetSchema>>;
  tags?: Maybe<Array<Tag>>;
  terms?: Maybe<Array<TermOutput>>;
  type: ResourceType;
};

export enum ResourceType {
  Document = "DOCUMENT",
  Image = "IMAGE",
  Link = "LINK",
  Video = "VIDEO",
}

export enum Role {
  Admin = "ADMIN",
  Collaborator = "COLLABORATOR",
  Contributor = "CONTRIBUTOR",
  Viewer = "VIEWER",
}

export type RoleInput = {
  resourceId: Scalars["ID"];
  roleType: RoleType;
  userId: Scalars["ID"];
};

export type RoleOutput = {
  __typename?: "RoleOutput";
  currentRole?: Maybe<Role>;
};

export enum RoleType {
  Organization = "Organization",
  Workspace = "Workspace",
}

export type RunTransformationsInProjectInput = {
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type SchemaProjectConnectInput = {
  projectId: Scalars["ID"];
  schemaId: Scalars["ID"];
};

export type SchemaResourceConnectInput = {
  resourceId: Scalars["ID"];
  schemaId: Scalars["ID"];
};

export enum SchemaType {
  Input = "INPUT",
  Output = "OUTPUT",
}

export enum ScopeType {
  DataAsset = "DATA_ASSET",
  FinalDataProduct = "FINAL_DATA_PRODUCT",
  InputDataAssetGroup = "INPUT_DATA_ASSET_GROUP",
  Organization = "ORGANIZATION",
  Project = "PROJECT",
  Workspace = "WORKSPACE",
}

export enum Sensitivity {
  Tier_1 = "TIER_1",
  Tier_2 = "TIER_2",
  Tier_3 = "TIER_3",
}

export type ServiceEntityOutput = {
  __typename?: "ServiceEntityOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ServiceManagerOutput = {
  __typename?: "ServiceManagerOutput";
  emailAddress: Scalars["EmailAddress"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export enum ServiceStatus {
  Connected = "CONNECTED",
  ConnectionFailed = "CONNECTION_FAILED",
  Unknown = "UNKNOWN",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type SourceDefinitionOutput = {
  __typename?: "SourceDefinitionOutput";
  documentationUrl: Scalars["String"];
  icon: Scalars["String"];
  name: Scalars["String"];
  sourceDefinitionId: Scalars["String"];
};

export type SourceDefinitionSpecFilter = {
  sourceDefinitionId: Scalars["ID"];
};

export type SourceDefinitionSpecOutput = {
  __typename?: "SourceDefinitionSpecOutput";
  connectionSpecification: Scalars["String"];
  documentationUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type SourceDefinitionsFilter = {
  ingestionServiceId: Scalars["ID"];
};

export type SourceFilterInput = {
  sourceId?: InputMaybe<Scalars["ID"]>;
  sourceType?: InputMaybe<SourceType>;
};

export type SourceManagerOutput = {
  __typename?: "SourceManagerOutput";
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type SourceOutput = {
  __typename?: "SourceOutput";
  dataAssets: Scalars["Int"];
  description: Scalars["String"];
  documentationUrl: Scalars["String"];
  id: Scalars["ID"];
  logoUrl: Scalars["String"];
  manager: SourceManagerOutput;
  name: Scalars["String"];
  owner: SourceOwnerOutput;
  sourceType: SourceType;
  status: IngestionServiceStatus;
  tags: Array<Scalars["String"]>;
};

export type SourceOwnerOutput = {
  __typename?: "SourceOwnerOutput";
  id: Scalars["ID"];
  name: Scalars["ID"];
};

export enum SourceType {
  Internal = "INTERNAL",
}

export type SystemAdmin = {
  __typename?: "SystemAdmin";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  organization?: Maybe<AdminOrganization>;
  policy?: Maybe<SystemAdminPolicy>;
  users: Array<User>;
};

export type SystemAdminPolicy = {
  __typename?: "SystemAdminPolicy";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  memberCreate: Scalars["Boolean"];
  memberDelete: Scalars["Boolean"];
  memberRead: Scalars["Boolean"];
  memberUpdate: Scalars["Boolean"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  orgCreate: Scalars["Boolean"];
  orgDelete: Scalars["Boolean"];
  orgRead: Scalars["Boolean"];
  orgUpdate: Scalars["Boolean"];
  systemAdminRole?: Maybe<SystemAdmin>;
  userRead: Scalars["Boolean"];
  workspaceCreate: Scalars["Boolean"];
};

export type Tag = {
  __typename?: "Tag";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type TagUpdate = {
  toAdd?: InputMaybe<Array<Scalars["String"]>>;
  toRemove?: InputMaybe<Array<Scalars["String"]>>;
};

export type TargetSchema = {
  __typename?: "TargetSchema";
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  jsonSchema?: Maybe<Scalars["String"]>;
  managers: Array<TargetSchemaManagerOutput>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner?: Maybe<TargetSchemaOwnerOutput>;
  piiTypes: Array<Scalars["String"]>;
  status?: Maybe<TargetSchemaStatus>;
  tags: Array<Scalars["String"]>;
  terms?: Maybe<Array<Maybe<TermOutput>>>;
  types?: Maybe<Array<Maybe<SchemaType>>>;
  versionNumber?: Maybe<Scalars["Int"]>;
};

export type TargetSchemaFilterInput = {
  schemaId: Scalars["ID"];
  versionNumber?: InputMaybe<Scalars["Int"]>;
};

export type TargetSchemaManagerOutput = {
  __typename?: "TargetSchemaManagerOutput";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type TargetSchemaOwnerOutput = {
  __typename?: "TargetSchemaOwnerOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum TargetSchemaStatus {
  Draft = "DRAFT",
  Private = "PRIVATE",
  Published = "PUBLISHED",
}

export type TermOutput = {
  __typename?: "TermOutput";
  addedOn: Scalars["String"];
  creator: UserOutput;
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  scope: ScopeType;
  tagNames?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<Tag>>;
  type: Scalars["String"];
};

export type TermResourceConnectInput = {
  resourceId: Scalars["ID"];
  termId: Scalars["ID"];
};

export type TermSchemaConnectInput = {
  schemaId: Scalars["ID"];
  termId: Scalars["ID"];
};

export enum TransformationMethod {
  DbtCloud = "DBT_CLOUD",
  OffPlatform = "OFF_PLATFORM",
  OnPlatform = "ON_PLATFORM",
  OtherRepository = "OTHER_REPOSITORY",
}

export type TransformationServiceFilterInput = {
  transformationServiceId: Scalars["ID"];
};

export type TransformationServiceJobOutput = {
  __typename?: "TransformationServiceJobOutput";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  projectUsage: ProjectUsage;
  projects: Array<ServiceEntityOutput>;
  sourceUrl?: Maybe<Scalars["String"]>;
};

export enum TransformationServiceLanguage {
  Python = "PYTHON",
  Sql = "SQL",
}

export type TransformationServiceOutput = {
  __typename?: "TransformationServiceOutput";
  configurations?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  jobCount: Scalars["Int"];
  languages: Array<TransformationServiceLanguage>;
  manager?: Maybe<ServiceManagerOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: ServiceEntityOutput;
  projectCount: Scalars["Int"];
  provider: TransformationServiceProvider;
  status: ServiceStatus;
};

export enum TransformationServiceProvider {
  DbtCloud = "DBT_CLOUD",
  Deepnote = "DEEPNOTE",
}

export type UpdateAssetPoliciesInput = {
  create: Array<CreateAssetPolicyInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateAssetPolicyInput>;
};

export type UpdateAssetPolicyInput = {
  description: Scalars["String"];
  href: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateCustomPoliciesInput = {
  create: Array<CreateCustomPolicyInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateCustomPolicyInput>;
};

export type UpdateCustomPolicyInput = {
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  policyCategory: PolicyCategory;
  referenceDocuments?: InputMaybe<UpdateReferenceDocumentsInput>;
};

export type UpdateDataAssetFileInput = {
  dataAssetId: Scalars["ID"];
  name: Scalars["String"];
  workspaceId: Scalars["ID"];
  writeMode: UploaderWriteMode;
};

export type UpdateDataAssetFileOutput = {
  __typename?: "UpdateDataAssetFileOutput";
  dataAssetId?: Maybe<Scalars["ID"]>;
  presignedUrl?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type UpdateDataAssetInput = {
  accessStatus?: InputMaybe<AccessStatus>;
  assetPolicies?: InputMaybe<UpdateAssetPoliciesInput>;
  dataAssetId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  refreshRate?: InputMaybe<RefreshRate>;
  sensitivity?: InputMaybe<Sensitivity>;
  source?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<UpdateTagsInput>;
  workspaceId: Scalars["ID"];
};

export type UpdateDataConsumersInput = {
  connectOrCreate: Array<Scalars["String"]>;
  disconnect: Array<Scalars["String"]>;
};

export type UpdateDestinationInput = {
  description?: InputMaybe<Scalars["String"]>;
  destinationId: Scalars["ID"];
  destinationType?: InputMaybe<DestinationType>;
  destinationUrl?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type UpdateFinalDataProductGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  finalDataProductGroupId: Scalars["ID"];
  jsonSchema?: InputMaybe<Scalars["String"]>;
  method?: InputMaybe<FinalDataProductGroupCreationMethod>;
  name?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  targetSchemaId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type UpdateFinalDataProductGroupOutput = {
  __typename?: "UpdateFinalDataProductGroupOutput";
  finalDataProductGroupId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
  targetSchemaId?: Maybe<Scalars["ID"]>;
};

export type UpdateGoalInput = {
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateGoalsInput = {
  create: Array<CreateGoalInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateGoalInput>;
};

export type UpdateGovernanceInput = {
  customPolicies?: InputMaybe<UpdateCustomPoliciesInput>;
  goals?: InputMaybe<UpdateGoalsInput>;
  legalDocuments?: InputMaybe<UpdateLegalDocumentsInput>;
  monitoredPolicies?: InputMaybe<UpdateMonitoredPoliciesInput>;
  referenceDocuments?: InputMaybe<UpdateReferenceDocumentsInput>;
  workspaceDescription?: InputMaybe<Scalars["String"]>;
  workspaceId: Scalars["ID"];
  workspaceName?: InputMaybe<Scalars["String"]>;
};

export type UpdateInputDataAssetGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  inputDataAssetGroupId: Scalars["ID"];
  jsonSchema?: InputMaybe<Scalars["String"]>;
  method?: InputMaybe<InputDataAssetGroupCreationMethod>;
  name?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  targetSchemaId?: InputMaybe<Scalars["ID"]>;
  workspaceId: Scalars["ID"];
};

export type UpdateInputDataAssetGroupOutput = {
  __typename?: "UpdateInputDataAssetGroupOutput";
  inputDataAssetGroupId?: Maybe<Scalars["ID"]>;
  success: Scalars["Boolean"];
  targetSchemaId?: Maybe<Scalars["ID"]>;
};

export type UpdateLegalDocumentInput = {
  href: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateLegalDocumentsInput = {
  create: Array<CreateLegalDocumentInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateLegalDocumentInput>;
};

export type UpdateManagersInput = {
  connect: Array<Scalars["ID"]>;
  disconnect: Array<Scalars["ID"]>;
};

export type UpdateMonitoredPoliciesInput = {
  connect: Array<Scalars["ID"]>;
  disconnect: Array<Scalars["ID"]>;
};

export type UpdateOrganizationRoleInput = {
  newRole: OrganizationRoleEnum;
  organizationId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type UpdateOutput = {
  __typename?: "UpdateOutput";
  success: Scalars["Boolean"];
};

export type UpdateProjectGoalInput = {
  description: Scalars["String"];
  id: Scalars["ID"];
  metrics: Array<Scalars["String"]>;
};

export type UpdateProjectGoalsInput = {
  create: Array<CreateProjectGoalInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateProjectGoalInput>;
};

export type UpdateProjectInput = {
  backgroundMaterials?: InputMaybe<UpdateReferenceDocumentsInput>;
  dataConsumers?: InputMaybe<UpdateDataConsumersInput>;
  dataProductType?: InputMaybe<DataProductType>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  goals?: InputMaybe<UpdateProjectGoalsInput>;
  managers?: InputMaybe<UpdateProjectManagersInput>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  projectId: Scalars["ID"];
  projectStatus?: InputMaybe<ProjectStatus>;
  startDate?: InputMaybe<Scalars["Date"]>;
  tags?: InputMaybe<UpdateTagsInput>;
  workspaceId: Scalars["ID"];
};

export type UpdateProjectManagersInput = {
  connect: Array<Scalars["ID"]>;
  disconnect: Array<Scalars["ID"]>;
};

export type UpdateReferenceDocumentInput = {
  href: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateReferenceDocumentsInput = {
  create: Array<CreateReferenceDocumentInput>;
  delete: Array<Scalars["ID"]>;
  update: Array<UpdateReferenceDocumentInput>;
};

export type UpdateResourceInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  resourceId: Scalars["ID"];
  tags?: InputMaybe<Array<Scalars["String"]>>;
  workspaceId: Scalars["ID"];
};

export type UpdateRoleInput = {
  newRole: Role;
  userId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type UpdateSchemaInput = {
  columnIndex: Scalars["Int"];
  dataAssetId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  piiTag?: InputMaybe<PiiTagType>;
  workspaceId: Scalars["ID"];
};

export type UpdateTagsInput = {
  connectOrCreate: Array<Scalars["String"]>;
  disconnect: Array<Scalars["String"]>;
};

export type UpdateTargetSchemaInput = {
  description?: InputMaybe<Scalars["String"]>;
  jsonSchema?: InputMaybe<Scalars["String"]>;
  managers?: InputMaybe<UpdateManagersInput>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  piiTypes?: InputMaybe<UpdateTagsInput>;
  projectId: Scalars["ID"];
  status?: InputMaybe<TargetSchemaStatus>;
  tags?: InputMaybe<UpdateTagsInput>;
  targetSchemaId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type UpdateTargetSchemaOutput = {
  __typename?: "UpdateTargetSchemaOutput";
  success: Scalars["Boolean"];
  targetSchema?: Maybe<TargetSchema>;
};

export type UpdateTermInput = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  tags?: InputMaybe<Array<Scalars["String"]>>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UpdateTransformationInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  sourceUrl?: InputMaybe<Scalars["String"]>;
  transformationId: Scalars["ID"];
  transformationMethod?: InputMaybe<TransformationMethod>;
  workspaceId: Scalars["ID"];
};

export type UpdateUserInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type UpdateWorkspaceInput = {
  logo?: InputMaybe<UpdateWorkspaceLogoInput>;
  name?: InputMaybe<Scalars["String"]>;
  workspaceId: Scalars["ID"];
};

export type UpdateWorkspaceLogoInput = {
  mimeType: Scalars["String"];
};

export type UpdateWorkspaceOutput = {
  __typename?: "UpdateWorkspaceOutput";
  logoUploadContext?: Maybe<LogoUploadContext>;
  success: Scalars["Boolean"];
};

export type UpdateWorkspaceRoleScopeInput = {
  projectsToAdd: Array<Scalars["ID"]>;
  projectsToRemove: Array<Scalars["ID"]>;
  userId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type UpdateWorkspaceSchemaInput = {
  description?: InputMaybe<Scalars["String"]>;
  jsonSchema?: InputMaybe<Scalars["String"]>;
  managers?: InputMaybe<UpdateManagersInput>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  piiTypes?: InputMaybe<UpdateTagsInput>;
  schemaId: Scalars["ID"];
  status?: InputMaybe<TargetSchemaStatus>;
  tags?: InputMaybe<UpdateTagsInput>;
  workspaceId: Scalars["ID"];
};

export enum UploaderWriteMode {
  Append = "APPEND",
  Overwrite = "OVERWRITE",
}

export type User = {
  __typename?: "User";
  active: Scalars["Boolean"];
  createdAt: Scalars["String"];
  emailAddress: Scalars["EmailAddress"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  modifiedAt: Scalars["String"];
  organizationRole?: Maybe<OrganizationRole>;
  systemAdminRole?: Maybe<SystemAdmin>;
  workspaceRoles: Array<WorkspaceRole>;
};

export type UserDataAssetOutput = {
  __typename?: "UserDataAssetOutput";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type UserOutput = {
  __typename?: "UserOutput";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
};

export type ValidateTransformationsInProjectInput = {
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export enum ValidationStatus {
  Invalid = "INVALID",
  Valid = "VALID",
}

export type Viewer = {
  __typename?: "Viewer";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  policy?: Maybe<WorkspacePolicy>;
  users: Array<User>;
  workspace?: Maybe<Workspace>;
};

export type WarehouseServiceFilterInput = {
  warehouseServiceId: Scalars["ID"];
};

export type WarehouseServiceOutput = {
  __typename?: "WarehouseServiceOutput";
  configurations: Scalars["String"];
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  manager?: Maybe<ServiceManagerOutput>;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  owner: ServiceEntityOutput;
  provider: WarehouseServiceProvider;
  status: ServiceStatus;
};

export enum WarehouseServiceProvider {
  Redshift = "REDSHIFT",
  Snowflake = "SNOWFLAKE",
}

export type Workflow = {
  __typename?: "Workflow";
  destinations: Array<Maybe<WorkflowDestinationOutput>>;
  finalDataProducts: Array<Maybe<WorkflowFinalDataProductGroupOutput>>;
  inputDataAssetGroups: Array<Maybe<WorkflowInputDataAssetGroupOutput>>;
  organizations: Array<Maybe<WorkflowOrganizationOutput>>;
  projectName: Scalars["String"];
  state: WorkflowState;
  transformations: Array<Maybe<WorkflowTransformationOutput>>;
};

export type WorkflowDestinationsArgs = {
  destinationFilter?: InputMaybe<WorkflowDestinationFilterInput>;
};

export type WorkflowFinalDataProductsArgs = {
  finalDataProductGroupFilter?: InputMaybe<WorkflowFinalDataProductGroupFilterInput>;
};

export type WorkflowInputDataAssetGroupsArgs = {
  inputDataAssetGroupFilter?: InputMaybe<WorkflowInputDataAssetGroupFilterInput>;
};

export type WorkflowOrganizationsArgs = {
  organizationFilter?: InputMaybe<WorkflowOrganizationFilterInput>;
};

export type WorkflowTransformationsArgs = {
  transformationFilter?: InputMaybe<WorkflowTransformationFilterInput>;
};

export type WorkflowDataAssetGroupDataAssetOutput = {
  __typename?: "WorkflowDataAssetGroupDataAssetOutput";
  id: Scalars["ID"];
  name: Scalars["String"];
  ownerName: Scalars["String"];
};

export type WorkflowDataAssetGroupOutput = {
  __typename?: "WorkflowDataAssetGroupOutput";
  dataAssets: Array<Maybe<WorkflowDataAssetGroupDataAssetOutput>>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  linkedNodes: Array<Scalars["ID"]>;
  name: Scalars["String"];
  pii: Scalars["Boolean"];
  schemaId?: Maybe<Scalars["ID"]>;
};

export type WorkflowDestinationFilterInput = {
  destinationId: Scalars["ID"];
};

export type WorkflowDestinationOutput = {
  __typename?: "WorkflowDestinationOutput";
  connectionSettings?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  destinationType: DestinationType;
  destinationUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
};

export type WorkflowFilterInput = {
  projectId: Scalars["ID"];
  workspaceId: Scalars["ID"];
};

export type WorkflowFinalDataProductGroupDataAsset = {
  __typename?: "WorkflowFinalDataProductGroupDataAsset";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  name: Scalars["String"];
  ownerName: Scalars["String"];
  pii: Scalars["Boolean"];
};

export type WorkflowFinalDataProductGroupDataAssetFilterInput = {
  dataAssetId: Scalars["ID"];
};

export type WorkflowFinalDataProductGroupFilterInput = {
  finalDataProductGroupId: Scalars["ID"];
};

export type WorkflowFinalDataProductGroupOutput = {
  __typename?: "WorkflowFinalDataProductGroupOutput";
  dataAssets: Array<Maybe<WorkflowFinalDataProductGroupDataAsset>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  linkedNodes: Array<Scalars["ID"]>;
  name: Scalars["String"];
  pii: Scalars["Boolean"];
  schemaId?: Maybe<Scalars["ID"]>;
  targetSchema?: Maybe<TargetSchema>;
};

export type WorkflowFinalDataProductGroupOutputDataAssetsArgs = {
  dataAssetFilter?: InputMaybe<WorkflowFinalDataProductGroupDataAssetFilterInput>;
};

export type WorkflowInputDataAssetGroupDataAsset = {
  __typename?: "WorkflowInputDataAssetGroupDataAsset";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  name: Scalars["String"];
  ownerName: Scalars["String"];
  pii: Scalars["Boolean"];
};

export type WorkflowInputDataAssetGroupDataAssetFilterInput = {
  dataAssetId: Scalars["ID"];
};

export type WorkflowInputDataAssetGroupDataAssetSuggestion = {
  __typename?: "WorkflowInputDataAssetGroupDataAssetSuggestion";
  accessStatus: AccessStatus;
  description: Scalars["String"];
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  managers: Array<UserDataAssetOutput>;
  name: Scalars["String"];
  owner: InputDataAssetOwnerOutput;
  pii: Scalars["Boolean"];
  tags: Array<Scalars["String"]>;
};

export type WorkflowInputDataAssetGroupFilterInput = {
  inputDataAssetGroupId: Scalars["ID"];
};

export type WorkflowInputDataAssetGroupOutput = {
  __typename?: "WorkflowInputDataAssetGroupOutput";
  dataAssetSuggestions: Array<WorkflowInputDataAssetGroupDataAssetSuggestion>;
  dataAssets: Array<Maybe<WorkflowInputDataAssetGroupDataAsset>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  linkedNodes: Array<Scalars["ID"]>;
  name: Scalars["String"];
  pii: Scalars["Boolean"];
  schemaId?: Maybe<Scalars["ID"]>;
  targetSchema?: Maybe<TargetSchema>;
};

export type WorkflowInputDataAssetGroupOutputDataAssetsArgs = {
  dataAssetFilter?: InputMaybe<WorkflowInputDataAssetGroupDataAssetFilterInput>;
};

export enum WorkflowIssueStatus {
  Incomplete = "INCOMPLETE",
  Major = "MAJOR",
  Minor = "MINOR",
  None = "NONE",
}

export enum WorkflowNodeType {
  Destination = "DESTINATION",
  FinalDataProductGroup = "FINAL_DATA_PRODUCT_GROUP",
  InputDataAssetGroup = "INPUT_DATA_ASSET_GROUP",
  Transformation = "TRANSFORMATION",
}

export type WorkflowOrganizationContributor = {
  __typename?: "WorkflowOrganizationContributor";
  firstName: Scalars["String"];
  id: Scalars["ID"];
  isProjectManager: Scalars["Boolean"];
  lastName: Scalars["String"];
};

export type WorkflowOrganizationFilterInput = {
  organizationId: Scalars["ID"];
};

export type WorkflowOrganizationOutput = {
  __typename?: "WorkflowOrganizationOutput";
  contributors: Array<Maybe<ProjectOrganizationContributor>>;
  dataAssetCount: Scalars["Int"];
  dataProductCount: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inputDataAssetCount: Scalars["Int"];
  issueStatus: WorkflowIssueStatus;
  linkedNodes: Array<Scalars["ID"]>;
  name: Scalars["String"];
  projectRole: ProjectOrganizationRole;
};

export enum WorkflowState {
  Active = "ACTIVE",
  Paused = "PAUSED",
}

export type WorkflowTransformationFilterInput = {
  transformationId: Scalars["ID"];
};

export type WorkflowTransformationJob = {
  __typename?: "WorkflowTransformationJob";
  arguments?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  endedAt?: Maybe<Scalars["String"]>;
  facets?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nominalEndTime?: Maybe<Scalars["String"]>;
  nominalStartTime?: Maybe<Scalars["String"]>;
  runId?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["String"]>;
  state?: Maybe<JobState>;
};

export type WorkflowTransformationOutput = {
  __typename?: "WorkflowTransformationOutput";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issueStatus: WorkflowIssueStatus;
  job?: Maybe<WorkflowTransformationJob>;
  jobCount: Scalars["Int"];
  linkedNodes: Array<Scalars["ID"]>;
  linkedTransformations: Array<Scalars["ID"]>;
  name: Scalars["String"];
  sourceUrl?: Maybe<Scalars["String"]>;
  transformationMethod?: Maybe<TransformationMethod>;
};

export type Workspace = {
  __typename?: "Workspace";
  createdAt?: Maybe<Scalars["String"]>;
  dataAssets?: Maybe<Array<Maybe<DataAsset>>>;
  description?: Maybe<Scalars["String"]>;
  governance?: Maybe<Governance>;
  id?: Maybe<Scalars["ID"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  members: Array<Member>;
  modifiedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner?: Maybe<OrganizationOutput>;
  participatingOrganizations?: Maybe<Array<Maybe<OrganizationOutput>>>;
  projects?: Maybe<Array<Maybe<ProjectOutput>>>;
  resources?: Maybe<Array<Maybe<ResourceOrFoldersOutput>>>;
  schemas?: Maybe<Array<Maybe<TargetSchema>>>;
  services: WorkspaceServices;
  terms?: Maybe<Array<Maybe<TermOutput>>>;
};

export type WorkspaceDataAssetsArgs = {
  dataAssetFilter?: InputMaybe<DataAssetFilterInput>;
};

export type WorkspaceProjectsArgs = {
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
};

export type WorkspaceResourcesArgs = {
  resourceFilter?: InputMaybe<WorkspaceResourceFilterInput>;
};

export type WorkspaceSchemasArgs = {
  schemaId?: InputMaybe<Scalars["ID"]>;
};

export type WorkspaceAdmin = {
  __typename?: "WorkspaceAdmin";
  createdAt: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  policy?: Maybe<WorkspacePolicy>;
  users: Array<User>;
  workspace?: Maybe<Workspace>;
};

export type WorkspaceInput = {
  workspaceId: Scalars["ID"];
};

export type WorkspacePolicy = {
  __typename?: "WorkspacePolicy";
  createdAt: Scalars["String"];
  dataAssetCreate: Scalars["Boolean"];
  dataAssetRead: Scalars["Boolean"];
  description: Scalars["String"];
  governanceCreate: Scalars["Boolean"];
  governanceDelete: Scalars["Boolean"];
  governanceRead: Scalars["Boolean"];
  governanceUpdate: Scalars["Boolean"];
  id: Scalars["ID"];
  memberCreate: Scalars["Boolean"];
  memberDelete: Scalars["Boolean"];
  memberRead: Scalars["Boolean"];
  memberUpdate: Scalars["Boolean"];
  modifiedAt: Scalars["String"];
  name: Scalars["String"];
  projectCreate: Scalars["Boolean"];
  projectDelete: Scalars["Boolean"];
  projectRead: Scalars["Boolean"];
  projectUpdate: Scalars["Boolean"];
  workspaceRole?: Maybe<WorkspaceRole>;
};

export type WorkspaceProjectFilterInput = {
  organizationIds?: InputMaybe<Array<Scalars["ID"]>>;
  projectId?: InputMaybe<Scalars["ID"]>;
};

export type WorkspaceResourceFilterInput = {
  folderId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type WorkspaceRole = Collaborator | Viewer | WorkspaceAdmin;

export enum WorkspaceRoleEnum {
  Admin = "ADMIN",
  Collaborator = "COLLABORATOR",
  Contributor = "CONTRIBUTOR",
  None = "NONE",
  Viewer = "VIEWER",
}

export type WorkspaceServices = {
  __typename?: "WorkspaceServices";
  ingestionServices: Array<IngestionServiceOutput>;
  sourceDefinitionSpecification?: Maybe<SourceDefinitionSpecOutput>;
  sourceDefinitions: Array<SourceDefinitionOutput>;
  sources: Array<SourceOutput>;
  transformationServices: Array<TransformationServiceOutput>;
  warehouseServices: Array<WarehouseServiceOutput>;
};

export type WorkspaceServicesIngestionServicesArgs = {
  ingestionServiceFilter?: InputMaybe<IngestionServiceFilterInput>;
};

export type WorkspaceServicesSourceDefinitionSpecificationArgs = {
  sourceDefinitionSpecFilter: SourceDefinitionSpecFilter;
};

export type WorkspaceServicesSourcesArgs = {
  sourceFilter: SourceFilterInput;
};

export type WorkspaceServicesTransformationServicesArgs = {
  transformationServiceFilter?: InputMaybe<TransformationServiceFilterInput>;
};

export type WorkspaceServicesWarehouseServicesArgs = {
  warehouseServiceFilter?: InputMaybe<WarehouseServiceFilterInput>;
};

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  __typename?: "Mutation";
  forgotPassword: boolean;
};

export type DeleteDestinationMutationVariables = Exact<{
  input: DeleteDestinationInput;
}>;

export type DeleteDestinationMutation = {
  __typename?: "Mutation";
  deleteDestination: {
    __typename?: "DeleteDestinationOutput";
    success: boolean;
  };
};

export type AcceptWorkspacePolicyMutationVariables = Exact<{
  input: AcceptWorkspacePolicyInput;
}>;

export type AcceptWorkspacePolicyMutation = {
  __typename?: "Mutation";
  acceptWorkspacePolicy: {
    __typename?: "AcceptWorkspacePolicyOutput";
    emailAddress?: any | null;
  };
};

export type AddDataAssetsToInputDataAssetGroupMutationVariables = Exact<{
  input: AddDataAssetsToInputDataAssetGroupInput;
}>;

export type AddDataAssetsToInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  addDataAssetsToInputDataAssetGroup: boolean;
};

export type AddSchemaToProjectMutationVariables = Exact<{
  input: SchemaProjectConnectInput;
}>;

export type AddSchemaToProjectMutation = {
  __typename?: "Mutation";
  addSchemaToProject: { __typename?: "UpdateOutput"; success: boolean };
};

export type AddSchemaToResourceMutationVariables = Exact<{
  input: SchemaResourceConnectInput;
}>;

export type AddSchemaToResourceMutation = {
  __typename?: "Mutation";
  addSchemaToResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type AddSourceMutationVariables = Exact<{
  input: AddSourceInput;
}>;

export type AddSourceMutation = { __typename?: "Mutation"; addSource: boolean };

export type AddTermToResourceMutationVariables = Exact<{
  input: TermResourceConnectInput;
}>;

export type AddTermToResourceMutation = {
  __typename?: "Mutation";
  addTermToResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type ConfirmForgotPasswordMutationVariables = Exact<{
  input: ConfirmForgotPasswordInput;
}>;

export type ConfirmForgotPasswordMutation = {
  __typename?: "Mutation";
  confirmForgotPassword: boolean;
};

export type ConfirmUserMutationVariables = Exact<{
  user: ConfirmUserInput;
}>;

export type ConfirmUserMutation = {
  __typename?: "Mutation";
  confirmUser: {
    __typename?: "LoginOutput";
    accessToken?: string | null;
    idToken?: string | null;
    success: boolean;
    refreshToken?: string | null;
  };
};

export type CreateDestinationMutationVariables = Exact<{
  input: CreateDestinationInput;
}>;

export type CreateDestinationMutation = {
  __typename?: "Mutation";
  createDestination: {
    __typename?: "CreateDestinationOutput";
    destinationId?: string | null;
    success: boolean;
  };
};

export type CreatefinalDataProductGroupMutationVariables = Exact<{
  input: CreateFinalDataProductGroupInput;
}>;

export type CreatefinalDataProductGroupMutation = {
  __typename?: "Mutation";
  createFinalDataProductGroup: {
    __typename?: "CreateFinalDataProductGroupOutput";
    finalDataProductGroupId?: string | null;
    success: boolean;
    targetSchemaId?: string | null;
  };
};

export type CreateIngestionServiceMutationVariables = Exact<{
  input: CreateIngestionServiceInput;
}>;

export type CreateIngestionServiceMutation = {
  __typename?: "Mutation";
  createIngestionService: {
    __typename?: "CreateIngestionServiceOutput";
    ingestionServiceId?: string | null;
    success: boolean;
  };
};

export type CreateInputDataAssetGroupMutationVariables = Exact<{
  input: CreateInputDataAssetGroupInput;
}>;

export type CreateInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  createInputDataAssetGroup: {
    __typename?: "CreateInputDataAssetGroupOutput";
    inputDataAssetGroupId?: string | null;
    success: boolean;
    targetSchemaId?: string | null;
  };
};

export type CreateProjectMutationVariables = Exact<{
  workspaceId: Scalars["ID"];
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject: {
    __typename?: "CreateProjectOutput";
    projectId: string;
    success: boolean;
  };
};

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;

export type CreateResourceMutation = {
  __typename?: "Mutation";
  createResource: {
    __typename?: "CreateResourceOutput";
    success: boolean;
    resourceId: string;
  };
};

export type CreateResourceFolderMutationVariables = Exact<{
  input: CreateResourceFolderInput;
}>;

export type CreateResourceFolderMutation = {
  __typename?: "Mutation";
  createResourceFolder: {
    __typename?: "CreateResourceFolderOutput";
    resourceFolderId: string;
    success: boolean;
  };
};

export type CreateSchemaMutationVariables = Exact<{
  input: CreateSchemaInput;
}>;

export type CreateSchemaMutation = {
  __typename?: "Mutation";
  createSchema: {
    __typename?: "CreateSchemaOutput";
    schemaId?: string | null;
    success: boolean;
  };
};

export type CreateTargetSchemaMutationVariables = Exact<{
  input: CreateTargetSchemaInput;
}>;

export type CreateTargetSchemaMutation = {
  __typename?: "Mutation";
  createTargetSchema: {
    __typename?: "CreateTargetSchemaOutput";
    success: boolean;
    targetSchemaId?: string | null;
  };
};

export type CreateTermMutationVariables = Exact<{
  input: CreateTermInput;
}>;

export type CreateTermMutation = {
  __typename?: "Mutation";
  createTerm: { __typename?: "CreateTermOutput"; success: boolean };
};

export type CreateTransformationMutationVariables = Exact<{
  input: CreateTransformationInput;
}>;

export type CreateTransformationMutation = {
  __typename?: "Mutation";
  createTransformation: {
    __typename?: "CreateTransformationOutput";
    transformationId?: string | null;
  };
};

export type CreateTransformationServiceMutationVariables = Exact<{
  input: CreateTransformationServiceInput;
}>;

export type CreateTransformationServiceMutation = {
  __typename?: "Mutation";
  createTransformationService: {
    __typename?: "CreateTransformationServiceOutput";
    success: boolean;
    transformationServiceId?: string | null;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "CreateUserOutput";
    emailAddress?: any | null;
    organizationId?: string | null;
    success: boolean;
  };
};

export type CreateWarehouseServiceMutationVariables = Exact<{
  input: CreateWarehouseServiceInput;
}>;

export type CreateWarehouseServiceMutation = {
  __typename?: "Mutation";
  createWarehouseService: {
    __typename?: "CreateWarehouseServiceOutput";
    success: boolean;
    warehouseServiceId?: string | null;
  };
};

export type CreateWorkflowConnectionMutationVariables = Exact<{
  input: CreateWorkflowConnectionInput;
}>;

export type CreateWorkflowConnectionMutation = {
  __typename?: "Mutation";
  createWorkflowConnection: boolean;
};

export type DeactivateOrganizationUserMutationVariables = Exact<{
  input: DeactivateOrganizationUserInput;
}>;

export type DeactivateOrganizationUserMutation = {
  __typename?: "Mutation";
  deactivateOrganizationUser: {
    __typename?: "DeactivateOrganizationUserOutput";
    success: boolean;
  };
};

export type DeleteFinalDataProductGroupMutationVariables = Exact<{
  input: DeleteFinalDataProductGroupInput;
}>;

export type DeleteFinalDataProductGroupMutation = {
  __typename?: "Mutation";
  deleteFinalDataProductGroup: {
    __typename?: "DeleteFinalDataProductGroupOutput";
    success: boolean;
  };
};

export type DeleteInputDataAssetGroupMutationVariables = Exact<{
  input: DeleteInputDataAssetGroupInput;
}>;

export type DeleteInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  deleteInputDataAssetGroup: {
    __typename?: "DeleteInputDataAssetGroupOutput";
    success: boolean;
  };
};

export type DeleteResourceMutationVariables = Exact<{
  input: DeleteResourceInput;
}>;

export type DeleteResourceMutation = {
  __typename?: "Mutation";
  deleteResource: { __typename?: "DeleteResourceOutput"; success: boolean };
};

export type DeleteTermMutationVariables = Exact<{
  deleteTermInput2: DeleteTermInput;
}>;

export type DeleteTermMutation = {
  __typename?: "Mutation";
  deleteTerm: { __typename?: "DeleteTermOutput"; success: boolean };
};

export type DisableUserMutationVariables = Exact<{
  user: DisableUserInput;
}>;

export type DisableUserMutation = {
  __typename?: "Mutation";
  disableUser: { __typename?: "DisableUserOutput"; success: boolean };
};

export type DownloadFileDestinationMutationVariables = Exact<{
  input: DownloadFileDestinationInput;
}>;

export type DownloadFileDestinationMutation = {
  __typename?: "Mutation";
  downloadFileDestination: {
    __typename?: "DownloadFileDestinationOutput";
    success: boolean;
    destinationId?: string | null;
    presignedUrl?: string | null;
  };
};

export type DuplicateProjectMutationVariables = Exact<{
  input: DuplicateProjectInput;
}>;

export type DuplicateProjectMutation = {
  __typename?: "Mutation";
  duplicateProject: boolean;
};

export type HardDeleteUserMutationVariables = Exact<{
  input: HardDeleteUserInput;
}>;

export type HardDeleteUserMutation = {
  __typename?: "Mutation";
  hardDeleteUser: boolean;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "LoginOutput";
    accessToken?: string | null;
    idToken?: string | null;
    refreshToken?: string | null;
    success: boolean;
    userToken?: string | null;
    challengeResponse?: {
      __typename?: "LoginChallengeResponse";
      organizationName?: string | null;
      name: string;
      session?: string | null;
    } | null;
  };
};

export type MoveResourceMutationVariables = Exact<{
  input: MoveResourceInput;
}>;

export type MoveResourceMutation = {
  __typename?: "Mutation";
  moveResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type PreSignedUrlQueryVariables = Exact<{
  input: PreSignedUrlInput;
}>;

export type PreSignedUrlQuery = {
  __typename?: "Query";
  preSignedUrl: {
    __typename?: "PreSignedUrlOutput";
    presignedUrl?: string | null;
    key?: string | null;
    success: boolean;
  };
};

export type RefreshTokenMutationVariables = Exact<{
  input: RefreshTokenInput;
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken: {
    __typename?: "RefreshTokenOutput";
    accessToken?: string | null;
    idToken?: string | null;
    success: boolean;
  };
};

export type RemoveDataAssetFromFinalDataProductGroupMutationVariables = Exact<{
  input: RemoveDataAssetFromFinalDataProductGroupInput;
}>;

export type RemoveDataAssetFromFinalDataProductGroupMutation = {
  __typename?: "Mutation";
  removeDataAssetFromFinalDataProductGroup: boolean;
};

export type RemoveDataAssetFromInputDataAssetGroupMutationVariables = Exact<{
  input: RemoveDataAssetFromInputDataAssetGroupInput;
}>;

export type RemoveDataAssetFromInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  removeDataAssetFromInputDataAssetGroup: boolean;
};

export type RemoveSchemaFromResourceMutationVariables = Exact<{
  input: SchemaResourceConnectInput;
}>;

export type RemoveSchemaFromResourceMutation = {
  __typename?: "Mutation";
  removeSchemaFromResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type RemoveTargetSchemaFromProjectMutationVariables = Exact<{
  input: RemoveTargetSchemaFromProjectInput;
}>;

export type RemoveTargetSchemaFromProjectMutation = {
  __typename?: "Mutation";
  removeTargetSchemaFromProject: boolean;
};

export type RemoveTermFromResourceMutationVariables = Exact<{
  input: TermResourceConnectInput;
}>;

export type RemoveTermFromResourceMutation = {
  __typename?: "Mutation";
  removeTermFromResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type ResendInviteMutationVariables = Exact<{
  input: ResendInviteInput;
}>;

export type ResendInviteMutation = {
  __typename?: "Mutation";
  resendInvite: boolean;
};

export type RunTransformationsInProjectMutationVariables = Exact<{
  input: RunTransformationsInProjectInput;
}>;

export type RunTransformationsInProjectMutation = {
  __typename?: "Mutation";
  runTransformationsInProject: boolean;
};

export type UpdateDataAssetMutationVariables = Exact<{
  updatedDataAsset: UpdateDataAssetInput;
}>;

export type UpdateDataAssetMutation = {
  __typename?: "Mutation";
  updateDataAsset: boolean;
};

export type UpdateDestinationMutationVariables = Exact<{
  input: UpdateDestinationInput;
}>;

export type UpdateDestinationMutation = {
  __typename?: "Mutation";
  updateDestination: boolean;
};

export type UpdateFinalDataProductGroupMutationVariables = Exact<{
  input: UpdateFinalDataProductGroupInput;
}>;

export type UpdateFinalDataProductGroupMutation = {
  __typename?: "Mutation";
  updateFinalDataProductGroup: {
    __typename?: "UpdateFinalDataProductGroupOutput";
    finalDataProductGroupId?: string | null;
    success: boolean;
  };
};

export type UpdateGovernanceMutationVariables = Exact<{
  input: UpdateGovernanceInput;
}>;

export type UpdateGovernanceMutation = {
  __typename?: "Mutation";
  updateGovernance: boolean;
};

export type UpdateInputDataAssetGroupMutationVariables = Exact<{
  input: UpdateInputDataAssetGroupInput;
}>;

export type UpdateInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  updateInputDataAssetGroup: {
    __typename?: "UpdateInputDataAssetGroupOutput";
    inputDataAssetGroupId?: string | null;
    success: boolean;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: "Mutation";
  result: boolean;
};

export type UpdateProjectNewMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectNewMutation = {
  __typename?: "Mutation";
  updateProject: boolean;
};

export type UpdateResourceMutationVariables = Exact<{
  input: UpdateResourceInput;
}>;

export type UpdateResourceMutation = {
  __typename?: "Mutation";
  updateResource: { __typename?: "UpdateOutput"; success: boolean };
};

export type UpdateRoleMutationVariables = Exact<{
  workspaceId: Scalars["ID"];
  userId: Scalars["ID"];
  newRole: Role;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: boolean;
};

export type UpdateWorkspaceSchemaMutationVariables = Exact<{
  input: UpdateWorkspaceSchemaInput;
}>;

export type UpdateWorkspaceSchemaMutation = {
  __typename?: "Mutation";
  updateWorkspaceSchema: { __typename?: "UpdateOutput"; success: boolean };
};

export type UpdateSchemaMutationVariables = Exact<{
  input: UpdateSchemaInput;
}>;

export type UpdateSchemaMutation = {
  __typename?: "Mutation";
  updateSchema: boolean;
};

export type UpdateTargetSchemaMutationVariables = Exact<{
  input: UpdateTargetSchemaInput;
}>;

export type UpdateTargetSchemaMutation = {
  __typename?: "Mutation";
  updateTargetSchema: {
    __typename?: "UpdateTargetSchemaOutput";
    success: boolean;
  };
};

export type UpdateTermMutationVariables = Exact<{
  input: UpdateTermInput;
}>;

export type UpdateTermMutation = {
  __typename?: "Mutation";
  updateTerm: { __typename?: "UpdateOutput"; success: boolean };
};

export type UpdateTransformationMutationVariables = Exact<{
  input: UpdateTransformationInput;
}>;

export type UpdateTransformationMutation = {
  __typename?: "Mutation";
  updateTransformation: boolean;
};

export type UpdateUserDetailsMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserDetailsMutation = {
  __typename?: "Mutation";
  updateUserDetails: { __typename?: "UpdateOutput"; success: boolean };
};

export type UpdateUserOrganizationRoleMutationVariables = Exact<{
  input: UpdateOrganizationRoleInput;
}>;

export type UpdateUserOrganizationRoleMutation = {
  __typename?: "Mutation";
  updateUserOrganizationRole: boolean;
};

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: "Mutation";
  updateWorkspace: {
    __typename?: "UpdateWorkspaceOutput";
    success: boolean;
    logoUploadContext?: {
      __typename?: "LogoUploadContext";
      url: string;
      fields: string;
      contentType: string;
    } | null;
  };
};

export type UpdateWorkspaceRoleScopeMutationVariables = Exact<{
  input: UpdateWorkspaceRoleScopeInput;
}>;

export type UpdateWorkspaceRoleScopeMutation = {
  __typename?: "Mutation";
  updateWorkspaceRoleScope: boolean;
};

export type ValidateTransformationsInProjectMutationVariables = Exact<{
  input: ValidateTransformationsInProjectInput;
}>;

export type ValidateTransformationsInProjectMutation = {
  __typename?: "Mutation";
  validateTransformationsInProject: boolean;
};

export type GetADataAssetQueryVariables = Exact<{
  input: WorkspaceInput;
  dataAssetFilter?: InputMaybe<DataAssetFilterInput>;
}>;

export type GetADataAssetQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    dataAssets?: Array<{
      __typename?: "DataAsset";
      id: string;
      description?: string | null;
      accessStatus?: AccessStatus | null;
      assetSize?: string | null;
      tableName?: string | null;
      childAssetCount?: number | null;
      connectionType?: string | null;
      connectionDescription?: string | null;
      createdAt?: string | null;
      isEditable: boolean;
      name?: string | null;
      piiTypes?: Array<PiiType> | null;
      projectCount?: number | null;
      refreshRate?: RefreshRate | null;
      refreshStatus?: RefreshStatus | null;
      sensitivity?: Sensitivity | null;
      source?: string | null;
      storageService?: string | null;
      tags: Array<string>;
      updatedAt?: string | null;
      validationStatus?: ValidationStatus | null;
      fields?: Array<{
        __typename?: "Field";
        description: string;
      } | null> | null;
      assetPolicies?: Array<{
        __typename?: "AssetPolicy";
        id: string;
        name: string;
        description: string;
        href: string;
      } | null> | null;
      issues?: Array<{
        __typename?: "Issue";
        title: IssueType;
        description: string;
      } | null> | null;
      managers?: Array<{
        __typename?: "UserDataAssetOutput";
        firstName: string;
        lastName: string;
        id: string;
      } | null> | null;
      owner?:
        | {
            __typename?: "OrganizationDataAssetOutput";
            id: string;
            name: string;
          }
        | { __typename?: "ProjectDataAssetOutput"; id: string; name: string }
        | null;
    } | null> | null;
  } | null;
};

export type GetAgreementPolicyQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetAgreementPolicyQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    name: string;
    owner?: { __typename?: "OrganizationOutput"; name: string } | null;
    governance?: {
      __typename?: "Governance";
      customPolicies: Array<{
        __typename?: "Policy";
        name: string;
        description: string;
      } | null>;
      monitoredPolicies: Array<{
        __typename?: "Policy";
        name: string;
        description: string;
      } | null>;
    } | null;
  } | null;
};

export type GetCreatedDataProductsQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
  createdDataProductFilter?: InputMaybe<CreatedDataProductFilterInput>;
}>;

export type GetCreatedDataProductsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      createdDataProducts: Array<{
        __typename?: "CreatedFinalProduct";
        connectionType?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        modifiedAt: string;
        name: string;
        refreshRate?: RefreshRate | null;
        sensitivity?: Sensitivity | null;
        status: WorkflowIssueStatus;
        tags: Array<string>;
        piiTypes: Array<string>;
        managers: Array<{
          __typename?: "FinalDataProductManagerOutput";
          firstName: string;
          lastName: string;
          id: string;
        }>;
        owner: {
          __typename?: "FinalDataProductOwnerOutput";
          id: string;
          name: string;
        };
        finalDataProductGroup?: {
          __typename?: "CreatedFinalProductFinalDataProductGroupOutput";
          id: string;
          name: string;
        } | null;
      }>;
    } | null> | null;
  } | null;
};

export type GetCustomPoliciesQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetCustomPoliciesQuery = {
  __typename?: "Query";
  isEditable?: {
    __typename?: "Workspace";
    governance?: { __typename?: "Governance"; isEditable: boolean } | null;
  } | null;
  categories?: {
    __typename?: "Workspace";
    governance?: {
      __typename?: "Governance";
      policies: Array<{
        __typename?: "Policy";
        id: string;
        name: string;
        policyCategory: PolicyCategory;
        description: string;
        title: string;
        type: PolicyCategory;
        dateTime: string;
        addedBy: {
          __typename?: "PolicyEditor";
          firstName: string;
          lastName: string;
        };
        tags?: Array<{ __typename?: "Tag"; name: string } | null> | null;
        referenceDocuments?: Array<{
          __typename?: "Document";
          id: string;
          name: string;
          href: string;
        } | null> | null;
      } | null>;
    } | null;
  } | null;
};

export type GetDataAssetManagerQueryVariables = Exact<{
  workspaceId: Scalars["ID"];
  dataAssetId: Scalars["ID"];
}>;

export type GetDataAssetManagerQuery = {
  __typename?: "Query";
  dataAssetManagerCandidates: Array<{
    __typename?: "EntityOutput";
    id: string;
    name: string;
  }>;
};

export type GetDataAssetSuggestionsQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
}>;

export type GetDataAssetSuggestionsQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    inputDataAssetGroups: Array<{
      __typename?: "WorkflowInputDataAssetGroupOutput";
      dataAssetSuggestions: Array<{
        __typename?: "WorkflowInputDataAssetGroupDataAssetSuggestion";
        accessStatus: AccessStatus;
        description: string;
        id: string;
        issueStatus: WorkflowIssueStatus;
        name: string;
        pii: boolean;
        tags: Array<string>;
        managers: Array<{
          __typename?: "UserDataAssetOutput";
          firstName: string;
          id: string;
          lastName: string;
        }>;
        owner: {
          __typename?: "InputDataAssetOwnerOutput";
          id: string;
          name: string;
        };
      }>;
    } | null>;
  } | null;
};

export type GetDataAssetsQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetDataAssetsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    dataAssets?: Array<{
      __typename?: "DataAsset";
      id: string;
      name?: string | null;
      isEditable: boolean;
      accessStatus?: AccessStatus | null;
      projectCount?: number | null;
      childAssetCount?: number | null;
      sensitivity?: Sensitivity | null;
      refreshRate?: RefreshRate | null;
      refreshStatus?: RefreshStatus | null;
      connectionType?: string | null;
      validationStatus?: ValidationStatus | null;
      updatedAt?: string | null;
      tags: Array<string>;
      piiTypes?: Array<PiiType> | null;
      createdAt?: string | null;
      issues?: Array<{ __typename?: "Issue"; status: string } | null> | null;
      owner?:
        | { __typename: "OrganizationDataAssetOutput"; name: string }
        | { __typename: "ProjectDataAssetOutput"; name: string }
        | null;
      managers?: Array<{
        __typename?: "UserDataAssetOutput";
        firstName: string;
        lastName: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetDataProductQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  finalDataProductGroupFilter?: InputMaybe<WorkflowFinalDataProductGroupFilterInput>;
  dataAssetFilter?: InputMaybe<WorkflowFinalDataProductGroupDataAssetFilterInput>;
}>;

export type GetDataProductQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    finalDataProducts: Array<{
      __typename?: "WorkflowFinalDataProductGroupOutput";
      dataAssets: Array<{
        __typename?: "WorkflowFinalDataProductGroupDataAsset";
        description?: string | null;
        id: string;
        issueStatus: WorkflowIssueStatus;
        name: string;
        ownerName: string;
        pii: boolean;
      } | null>;
    } | null>;
  } | null;
};

export type GetDataProductAssetQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  finalDataProductGroupFilter?: InputMaybe<WorkflowFinalDataProductGroupFilterInput>;
}>;

export type GetDataProductAssetQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    finalDataProducts: Array<{
      __typename?: "WorkflowFinalDataProductGroupOutput";
      description?: string | null;
      id: string;
      name: string;
      pii: boolean;
      schemaId?: string | null;
      issueStatus: WorkflowIssueStatus;
      targetSchema?: {
        __typename?: "TargetSchema";
        id: string;
        name: string;
        modifiedAt?: string | null;
        jsonSchema?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetDestinationSheetsQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  destinationFilter?: InputMaybe<WorkflowDestinationFilterInput>;
}>;

export type GetDestinationSheetsQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    destinations: Array<{
      __typename?: "WorkflowDestinationOutput";
      createdAt: string;
      description?: string | null;
      destinationType: DestinationType;
      destinationUrl?: string | null;
      id: string;
      issueStatus: WorkflowIssueStatus;
      modifiedAt: string;
      name: string;
      connectionSettings?: string | null;
    } | null>;
  } | null;
};

export type GetExportCsvQueryVariables = Exact<{
  data: Array<Scalars["JSON"]> | Scalars["JSON"];
}>;

export type GetExportCsvQuery = {
  __typename?: "Query";
  exportCSV?: string | null;
};

export type GetIngestionServicesQueryVariables = Exact<{
  input: WorkspaceInput;
  ingestionServiceFilter?: InputMaybe<IngestionServiceFilterInput>;
}>;

export type GetIngestionServicesQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      ingestionServices: Array<{
        __typename?: "IngestionServiceOutput";
        id: string;
        createdAt: string;
        modifiedAt: string;
        name: string;
        description?: string | null;
        provider: IngestionServiceProvider;
        status: ServiceStatus;
        dataAssetCount: number;
        sourceCount: number;
        configurations: string;
        owner: { __typename?: "ServiceEntityOutput"; id: string; name: string };
        manager?: {
          __typename?: "ServiceManagerOutput";
          id: string;
          firstName: string;
          lastName: string;
          emailAddress: any;
        } | null;
      }>;
    };
  } | null;
};

export type GetInputSchemaSetQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  inputDataAssetGroupFilter?: InputMaybe<WorkflowInputDataAssetGroupFilterInput>;
}>;

export type GetInputSchemaSetQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    inputDataAssetGroups: Array<{
      __typename?: "WorkflowInputDataAssetGroupOutput";
      description?: string | null;
      id: string;
      issueStatus: WorkflowIssueStatus;
      linkedNodes: Array<string>;
      name: string;
      pii: boolean;
      schemaId?: string | null;
      targetSchema?: {
        __typename?: "TargetSchema";
        id: string;
        name: string;
        modifiedAt?: string | null;
        jsonSchema?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetMonitoredPoliciesQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetMonitoredPoliciesQuery = {
  __typename?: "Query";
  isEditable?: {
    __typename?: "Workspace";
    governance?: { __typename?: "Governance"; isEditable: boolean } | null;
  } | null;
  categories?: {
    __typename?: "Workspace";
    governance?: {
      __typename?: "Governance";
      policies: Array<{
        __typename?: "Policy";
        id: string;
        policyCategory: PolicyCategory;
        description: string;
        title: string;
        type: PolicyCategory;
        dateTime: string;
        addedBy: {
          __typename?: "PolicyEditor";
          firstName: string;
          lastName: string;
        };
        tags?: Array<{ __typename?: "Tag"; name: string } | null> | null;
        referenceDocuments?: Array<{
          __typename?: "Document";
          name: string;
          href: string;
        } | null> | null;
      } | null>;
    } | null;
  } | null;
};

export type GetOrganizationMembersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrganizationMembersQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "CurrentUserOutput";
    organization?: {
      __typename?: "CurrentUserOrganizationOutput";
      id: string;
      isEditable: boolean;
      members: Array<{
        __typename?: "CurrentUserOrganizationMemberOutput";
        assetManagedCount: number;
        createdAt: string;
        id: string;
        emailAddress: string;
        name: string;
        organizationRole: OrganizationRoleEnum;
        projectManagedCount: number;
        status: boolean;
        workspaceCount: number;
      }>;
    } | null;
  };
};

export type GetOrganizationSheetQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  organizationFilter?: InputMaybe<WorkflowOrganizationFilterInput>;
}>;

export type GetOrganizationSheetQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    organizations: Array<{
      __typename?: "WorkflowOrganizationOutput";
      dataProductCount: number;
      dataAssetCount: number;
      description?: string | null;
      id: string;
      inputDataAssetCount: number;
      linkedNodes: Array<string>;
      name: string;
      projectRole: ProjectOrganizationRole;
      issueStatus: WorkflowIssueStatus;
      contributors: Array<{
        __typename?: "ProjectOrganizationContributor";
        firstName: string;
        lastName: string;
      } | null>;
    } | null>;
  } | null;
};

export type GetOrganizationsQueryVariables = Exact<{
  workspaceId: Scalars["ID"];
}>;

export type GetOrganizationsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    participatingOrganizations?: Array<{
      __typename?: "OrganizationOutput";
      id: string;
      name: string;
      domain: string;
    } | null> | null;
  } | null;
};

export type GetProjectDataAssetQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
  inputDataAssetsInputDataAssetFilter2?: InputMaybe<InputDataAssetFilterInput>;
}>;

export type GetProjectDataAssetQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      inputDataAssets: Array<{
        __typename?: "InputDataAsset";
        connectionType?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        modifiedAt: string;
        name: string;
        refreshRate?: RefreshRate | null;
        sensitivity?: Sensitivity | null;
        status: WorkflowIssueStatus;
        tags: Array<string>;
        inputDataAssetGroup: {
          __typename?: "InputDataAssetInputDataAssetGroupOutput";
          id: string;
          name: string;
        };
        managers: Array<{
          __typename?: "InputDataAssetManagerOutput";
          id: string;
          firstName: string;
          lastName: string;
        }>;
        owner: {
          __typename?: "InputDataAssetOwnerOutput";
          id: string;
          name: string;
        };
      }>;
    } | null> | null;
  } | null;
};

export type GetProjectManagerCandidatesQueryVariables = Exact<{
  input: ProjectEntityInput;
}>;

export type GetProjectManagerCandidatesQuery = {
  __typename?: "Query";
  projectManagerCandidates: Array<{
    __typename?: "EntityOutput";
    id: string;
    name: string;
  }>;
};

export type GetProjectManagersQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
}>;

export type GetProjectManagersQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      managers: Array<{
        __typename?: "UserOutput";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null> | null;
  } | null;
};

export type GetProjectManagersAndOwnersQueryVariables = Exact<{
  workspaceId: Scalars["ID"];
  projectId: Scalars["ID"];
}>;

export type GetProjectManagersAndOwnersQuery = {
  __typename?: "Query";
  managers: Array<{ __typename?: "EntityOutput"; id: string; name: string }>;
  owners: Array<{ __typename?: "EntityOutput"; id: string; name: string }>;
};

export type GetProjectTargetSchemasQueryVariables = Exact<{
  targetSchemaFilter: TargetSchemaFilterInput;
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
}>;

export type GetProjectTargetSchemasQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      targetSchemas: Array<{
        __typename?: "TargetSchema";
        createdAt?: string | null;
        description?: string | null;
        id: string;
        jsonSchema?: string | null;
        modifiedAt?: string | null;
        name: string;
      }>;
    } | null> | null;
  } | null;
};

export type GetResourcesQueryVariables = Exact<{
  input: WorkspaceInput;
  resourceFilter?: InputMaybe<WorkspaceResourceFilterInput>;
}>;

export type GetResourcesQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    resources?: Array<
      | {
          __typename?: "ResourceFolderOutput";
          id: string;
          name: string;
          createdAt: string;
          modifiedAt: string;
          isFolder: boolean;
          creator: {
            __typename?: "UserOutput";
            id: string;
            firstName: string;
            lastName: string;
          };
        }
      | {
          __typename?: "ResourceOutput";
          id: string;
          name: string;
          path: string;
          type: ResourceType;
          createdAt: string;
          modifiedAt: string;
          description?: string | null;
          internal: boolean;
          creator: {
            __typename?: "UserOutput";
            id: string;
            firstName: string;
            lastName: string;
          };
          tags?: Array<{ __typename?: "Tag"; id: string; name: string }> | null;
          terms?: Array<{
            __typename?: "TermOutput";
            id: string;
            name: string;
          }> | null;
          schemas?: Array<{
            __typename?: "TargetSchema";
            id: string;
            name: string;
          }> | null;
        }
      | null
    > | null;
  } | null;
};

export type GetSchemaManagersQueryVariables = Exact<{
  workspaceInput2: WorkspaceInput;
}>;

export type GetSchemaManagersQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    schemas?: Array<{
      __typename?: "TargetSchema";
      managers: Array<{
        __typename?: "TargetSchemaManagerOutput";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null> | null;
  } | null;
};

export type GetSchemasQueryVariables = Exact<{
  input: WorkspaceInput;
  schemaId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetSchemasQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    schemas?: Array<{
      __typename?: "TargetSchema";
      id: string;
      name: string;
      createdAt?: string | null;
      modifiedAt?: string | null;
      description?: string | null;
      status?: TargetSchemaStatus | null;
      jsonSchema?: string | null;
      versionNumber?: number | null;
      types?: Array<SchemaType | null> | null;
      tags: Array<string>;
      piiTypes: Array<string>;
      owner?: {
        __typename?: "TargetSchemaOwnerOutput";
        id: string;
        name: string;
      } | null;
      managers: Array<{
        __typename?: "TargetSchemaManagerOutput";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null> | null;
  } | null;
};

export type GetSourceDefinitionSpecificationQueryVariables = Exact<{
  sourceDefinitionSpecFilter: SourceDefinitionSpecFilter;
  input: WorkspaceInput;
}>;

export type GetSourceDefinitionSpecificationQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      sourceDefinitionSpecification?: {
        __typename?: "SourceDefinitionSpecOutput";
        id: string;
        documentationUrl?: string | null;
        connectionSpecification: string;
      } | null;
    };
  } | null;
};

export type GetSourcesQueryVariables = Exact<{
  input: WorkspaceInput;
  sourceFilter: SourceFilterInput;
}>;

export type GetSourcesQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      sources: Array<{
        __typename?: "SourceOutput";
        id: string;
        name: string;
        sourceType: SourceType;
        tags: Array<string>;
        logoUrl: string;
        description: string;
        status: IngestionServiceStatus;
        dataAssets: number;
        documentationUrl: string;
        owner: { __typename?: "SourceOwnerOutput"; id: string; name: string };
        manager: {
          __typename?: "SourceManagerOutput";
          id: string;
          firstName: string;
          lastName: string;
          emailAddress: string;
        };
      }>;
    };
  } | null;
};

export type GetSourcesInternalQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetSourcesInternalQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      sourceDefinitions: Array<{
        __typename?: "SourceDefinitionOutput";
        documentationUrl: string;
        name: string;
        sourceDefinitionId: string;
        icon: string;
      }>;
    };
  } | null;
};

export type GetTargetSchemasQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
  targetSchemaFilter?: InputMaybe<TargetSchemaFilterInput>;
}>;

export type GetTargetSchemasQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      targetSchemas: Array<{
        __typename?: "TargetSchema";
        createdAt?: string | null;
        id: string;
        jsonSchema?: string | null;
        description?: string | null;
        name: string;
        piiTypes: Array<string>;
        status?: TargetSchemaStatus | null;
        tags: Array<string>;
        types?: Array<SchemaType | null> | null;
        modifiedAt?: string | null;
        versionNumber?: number | null;
        owner?: {
          __typename?: "TargetSchemaOwnerOutput";
          name: string;
          id: string;
        } | null;
        managers: Array<{
          __typename?: "TargetSchemaManagerOutput";
          firstName: string;
          lastName: string;
          id: string;
        }>;
      }>;
    } | null> | null;
  } | null;
};

export type TermsQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type TermsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    terms?: Array<{
      __typename?: "TermOutput";
      addedOn: string;
      description: string;
      id: string;
      modifiedAt: string;
      name: string;
      type: string;
      creator: {
        __typename?: "UserOutput";
        lastName: string;
        id: string;
        firstName: string;
      };
      tagNames?: Array<{ __typename?: "Tag"; name: string }> | null;
    } | null> | null;
  } | null;
};

export type GetTransformationQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  transformationFilter?: InputMaybe<WorkflowTransformationFilterInput>;
}>;

export type GetTransformationQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    transformations: Array<{
      __typename?: "WorkflowTransformationOutput";
      description?: string | null;
      id: string;
      issueStatus: WorkflowIssueStatus;
      jobCount: number;
      name: string;
      sourceUrl?: string | null;
      transformationMethod?: TransformationMethod | null;
      job?: {
        __typename?: "WorkflowTransformationJob";
        arguments?: string | null;
        createdAt?: string | null;
        description?: string | null;
        duration?: string | null;
        endedAt?: string | null;
        facets?: string | null;
        id: string;
        modifiedAt?: string | null;
        name: string;
        nominalEndTime?: string | null;
        nominalStartTime?: string | null;
        runId?: string | null;
        startedAt?: string | null;
        state?: JobState | null;
      } | null;
    } | null>;
  } | null;
};

export type GetTransformationServicesQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetTransformationServicesQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      transformationServices: Array<{
        __typename?: "TransformationServiceOutput";
        id: string;
        createdAt: string;
        modifiedAt: string;
        name: string;
        description?: string | null;
        provider: TransformationServiceProvider;
        languages: Array<TransformationServiceLanguage>;
        status: ServiceStatus;
        projectCount: number;
        jobCount: number;
        configurations?: string | null;
        owner: { __typename?: "ServiceEntityOutput"; id: string; name: string };
        manager?: {
          __typename?: "ServiceManagerOutput";
          id: string;
          firstName: string;
          lastName: string;
          emailAddress: any;
        } | null;
      }>;
    };
  } | null;
};

export type GetWarehouseServicesQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetWarehouseServicesQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    services: {
      __typename?: "WorkspaceServices";
      warehouseServices: Array<{
        __typename?: "WarehouseServiceOutput";
        id: string;
        createdAt: string;
        modifiedAt: string;
        name: string;
        description?: string | null;
        provider: WarehouseServiceProvider;
        configurations: string;
        status: ServiceStatus;
        owner: { __typename?: "ServiceEntityOutput"; id: string; name: string };
        manager?: {
          __typename?: "ServiceManagerOutput";
          id: string;
          firstName: string;
          lastName: string;
          emailAddress: any;
        } | null;
      }>;
    };
  } | null;
};

export type GetWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspacesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "CurrentUserOutput";
    workspaces: Array<{
      __typename?: "CurrentUserWorkspaceOutput";
      id: string;
      name: string;
      ownerOrganizationName: string;
      acceptedPolicies: boolean;
      policies: Array<{
        __typename?: "CurrentUserWorkspacePolicy";
        name: string;
        description: string;
        createdAt: string;
        modifiedAt: string;
      }>;
    } | null>;
  };
};

export type GetGovernanceInfoQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetGovernanceInfoQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    id?: string | null;
    name: string;
    description?: string | null;
    governance?: {
      __typename?: "Governance";
      governanceType: GovernanceType;
      id: string;
      isEditable: boolean;
      customPolicies: Array<{
        __typename?: "Policy";
        description: string;
      } | null>;
      goals: Array<{
        __typename?: "Goal";
        id: string;
        name?: string | null;
        description: string;
      } | null>;
      legalDocuments: Array<{
        __typename?: "Document";
        id: string;
        name: string;
        href: string;
      } | null>;
      monitoredPolicies: Array<{
        __typename?: "Policy";
        description: string;
      } | null>;
      referenceDocuments: Array<{
        __typename?: "Document";
        id: string;
        name: string;
        href: string;
      } | null>;
    } | null;
  } | null;
};

export type InputDataAssetQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
  inputDataAssetGroupFilter?: InputMaybe<WorkflowInputDataAssetGroupFilterInput>;
  dataAssetFilter?: InputMaybe<WorkflowInputDataAssetGroupDataAssetFilterInput>;
}>;

export type InputDataAssetQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    inputDataAssetGroups: Array<{
      __typename?: "WorkflowInputDataAssetGroupOutput";
      dataAssets: Array<{
        __typename?: "WorkflowInputDataAssetGroupDataAsset";
        description?: string | null;
        id: string;
        issueStatus: WorkflowIssueStatus;
        name: string;
        ownerName: string;
        pii: boolean;
      } | null>;
    } | null>;
  } | null;
};

export type OnboardDataAssetToInputDataAssetGroupMutationVariables = Exact<{
  input: OnboardDataAssetToInputDataAssetGroupInput;
}>;

export type OnboardDataAssetToInputDataAssetGroupMutation = {
  __typename?: "Mutation";
  onboardDataAssetToInputDataAssetGroup: {
    __typename?: "OnboardDataAssetToInputDataAssetGroupOutput";
    dataAssetId?: string | null;
    presignedUrl?: string | null;
    success: boolean;
  };
};

export type OnboardDataAssetToWorkspaceMutationVariables = Exact<{
  input: OnboardDataAssetToWorkspaceInput;
}>;

export type OnboardDataAssetToWorkspaceMutation = {
  __typename?: "Mutation";
  onboardDataAssetToWorkspace: {
    __typename?: "OnboardDataAssetToWorkspaceOutput";
    dataAssetId?: string | null;
    presignedUrl?: string | null;
    success: boolean;
  };
};

export type GetParticipatingOrganizationsQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetParticipatingOrganizationsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    participatingOrganizations?: Array<{
      __typename?: "OrganizationOutput";
      id: string;
      name: string;
    } | null> | null;
    owner?: {
      __typename?: "OrganizationOutput";
      id: string;
      name: string;
    } | null;
  } | null;
};

export type GetProjectQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
}>;

export type GetProjectQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    name: string;
    projects?: Array<{
      __typename?: "ProjectOutput";
      name: string;
      tags: Array<string>;
      status: ProjectStatus;
      workflowState: WorkflowState;
      description?: string | null;
      dataProductCount: number;
      dataProductType: DataProductType;
      issueState: WorkflowIssueStatus;
      issueCount: number;
      collaboratingOrganizations: Array<string>;
      dataConsumers: Array<string>;
      isEditable: boolean;
      inputAssetCount: number;
      startDate?: string | null;
      sensitivity: Array<Sensitivity>;
      id: string;
      endDate?: string | null;
      createdAt: string;
      modifiedAt: string;
      managers: Array<{
        __typename?: "UserOutput";
        id: string;
        firstName: string;
        lastName: string;
      }>;
      owner: { __typename?: "OrganizationOutput"; id: string; name: string };
      goals: Array<{
        __typename?: "Goal";
        id: string;
        name?: string | null;
        metrics: Array<string>;
        description: string;
      }>;
      collaboratingMembers: Array<{
        __typename?: "ProjectCollaboratingMember";
        id: string;
        firstName: string;
        lastName: string;
      }>;
      destinations: Array<{
        __typename?: "ProjectDestinationOutput";
        id: string;
        name: string;
        description?: string | null;
        destinationType: DestinationType;
        destinationUrl?: string | null;
      } | null>;
      backgroundMaterials: Array<{
        __typename?: "Document";
        name: string;
        href: string;
        id: string;
      } | null>;
    } | null> | null;
  } | null;
};

export type GetProjectsForDashboardQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetProjectsForDashboardQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      id: string;
      name: string;
      updatedAt: string;
    } | null> | null;
  } | null;
};

export type GetProjectInfoQueryVariables = Exact<{
  input: WorkspaceInput;
  projectFilter?: InputMaybe<WorkspaceProjectFilterInput>;
}>;

export type GetProjectInfoQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      name: string;
      isEditable: boolean;
    } | null> | null;
  } | null;
};

export type GetProjectsQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetProjectsQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    projects?: Array<{
      __typename?: "ProjectOutput";
      tags: Array<string>;
      createdAt: string;
      modifiedAt: string;
      name: string;
      status: ProjectStatus;
      inputAssetCount: number;
      isEditable: boolean;
      description?: string | null;
      id: string;
      issueCount: number;
      owner: { __typename?: "OrganizationOutput"; name: string };
      managers: Array<{
        __typename?: "UserOutput";
        id: string;
        firstName: string;
        lastName: string;
      }>;
    } | null> | null;
  } | null;
};

export type RefreshUserTokenQueryVariables = Exact<{ [key: string]: never }>;

export type RefreshUserTokenQuery = {
  __typename?: "Query";
  refreshUserToken: {
    __typename?: "RefreshUserTokenOutput";
    success: boolean;
    userToken?: string | null;
  };
};

export type GetSchemaQueryVariables = Exact<{
  input: WorkspaceInput;
  dataAssetFilter?: InputMaybe<DataAssetFilterInput>;
}>;

export type GetSchemaQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    dataAssets?: Array<{
      __typename?: "DataAsset";
      isEditable: boolean;
      fields?: Array<{
        __typename?: "Field";
        name: string;
        dataType: DataType;
        pii: PiiType;
        description: string;
        jsonSchema: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateDataAssetFileMutationVariables = Exact<{
  input: UpdateDataAssetFileInput;
}>;

export type UpdateDataAssetFileMutation = {
  __typename?: "Mutation";
  updateDataAssetFile: {
    __typename?: "UpdateDataAssetFileOutput";
    dataAssetId?: string | null;
    presignedUrl?: string | null;
    success: boolean;
  };
};

export type GetCurrentUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserInfoQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "CurrentUserOutput";
    firstName: string;
    id: string;
    lastName: string;
    emailAddress: any;
    organization?: {
      __typename?: "CurrentUserOrganizationOutput";
      name: string;
      description: string;
      createdAt: string;
    } | null;
    workspaces: Array<{
      __typename?: "CurrentUserWorkspaceOutput";
      id: string;
      ownerOrganizationName: string;
      acceptedPolicies: boolean;
    } | null>;
  };
};

export type GetCurrentUserRoleQueryVariables = Exact<{
  input: CurrentUserWorkspaceRoleFilter;
}>;

export type GetCurrentUserRoleQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "CurrentUserOutput";
    workspaceRole: WorkspaceRoleEnum;
  };
};

export type GetWorkflowQueryVariables = Exact<{
  workflowFilterInput: WorkflowFilterInput;
}>;

export type GetWorkflowQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    projectName: string;
    state: WorkflowState;
    organizations: Array<{
      __typename?: "WorkflowOrganizationOutput";
      dataAssetCount: number;
      issueStatus: WorkflowIssueStatus;
      id: string;
      linkedNodes: Array<string>;
      name: string;
    } | null>;
    inputDataAssetGroups: Array<{
      __typename?: "WorkflowInputDataAssetGroupOutput";
      id: string;
      description?: string | null;
      issueStatus: WorkflowIssueStatus;
      linkedNodes: Array<string>;
      name: string;
      pii: boolean;
      schemaId?: string | null;
      dataAssets: Array<{
        __typename?: "WorkflowInputDataAssetGroupDataAsset";
        name: string;
        id: string;
        ownerName: string;
      } | null>;
    } | null>;
    transformations: Array<{
      __typename?: "WorkflowTransformationOutput";
      id: string;
      issueStatus: WorkflowIssueStatus;
      jobCount: number;
      linkedNodes: Array<string>;
      linkedTransformations: Array<string>;
      name: string;
      job?: {
        __typename?: "WorkflowTransformationJob";
        startedAt?: string | null;
      } | null;
    } | null>;
    finalDataProducts: Array<{
      __typename?: "WorkflowFinalDataProductGroupOutput";
      id: string;
      issueStatus: WorkflowIssueStatus;
      linkedNodes: Array<string>;
      name: string;
      pii: boolean;
      schemaId?: string | null;
      dataAssets: Array<{
        __typename?: "WorkflowFinalDataProductGroupDataAsset";
        name: string;
        id: string;
        ownerName: string;
      } | null>;
    } | null>;
    destinations: Array<{
      __typename?: "WorkflowDestinationOutput";
      destinationType: DestinationType;
      id: string;
      issueStatus: WorkflowIssueStatus;
      modifiedAt: string;
      name: string;
    } | null>;
  } | null;
};

export type GetWorkspaceInfoQueryVariables = Exact<{
  input: WorkspaceInput;
  accessible?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetWorkspaceInfoQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    id?: string | null;
    name: string;
    createdAt?: string | null;
    description?: string | null;
    logoUrl?: string | null;
    owner?: { __typename?: "OrganizationOutput"; name: string } | null;
    governance?: { __typename?: "Governance"; isEditable: boolean } | null;
  } | null;
};

export type GetWorkspaceMembersQueryVariables = Exact<{
  input: WorkspaceInput;
}>;

export type GetWorkspaceMembersQuery = {
  __typename?: "Query";
  workspace?: {
    __typename?: "Workspace";
    members: Array<{
      __typename?: "Member";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      emailAddress: any;
      organizationName: string;
      createdAt: string;
      lastAccessed?: string | null;
      workspaceRole: WorkspaceRoleEnum;
      status: MemberStatus;
    }>;
  } | null;
};

export const ForgotPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ForgotPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ForgotPasswordInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "forgotPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const DeleteDestinationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDestination" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteDestinationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteDestination" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDestinationMutation,
  DeleteDestinationMutationVariables
>;
export const AcceptWorkspacePolicyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AcceptWorkspacePolicy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AcceptWorkspacePolicyInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptWorkspacePolicy" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptWorkspacePolicyMutation,
  AcceptWorkspacePolicyMutationVariables
>;
export const AddDataAssetsToInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddDataAssetsToInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "AddDataAssetsToInputDataAssetGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addDataAssetsToInputDataAssetGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddDataAssetsToInputDataAssetGroupMutation,
  AddDataAssetsToInputDataAssetGroupMutationVariables
>;
export const AddSchemaToProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddSchemaToProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SchemaProjectConnectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSchemaToProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSchemaToProjectMutation,
  AddSchemaToProjectMutationVariables
>;
export const AddSchemaToResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addSchemaToResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SchemaResourceConnectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSchemaToResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSchemaToResourceMutation,
  AddSchemaToResourceMutationVariables
>;
export const AddSourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddSource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AddSourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddSourceMutation, AddSourceMutationVariables>;
export const AddTermToResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddTermToResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TermResourceConnectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addTermToResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTermToResourceMutation,
  AddTermToResourceMutationVariables
>;
export const ConfirmForgotPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmForgotPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ConfirmForgotPasswordInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmForgotPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables
>;
export const ConfirmUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ConfirmUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "user" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                { kind: "Field", name: { kind: "Name", value: "idToken" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const CreateDestinationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDestination" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDestinationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createDestination" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "destinationId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDestinationMutation,
  CreateDestinationMutationVariables
>;
export const CreatefinalDataProductGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatefinalDataProductGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateFinalDataProductGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createFinalDataProductGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalDataProductGroupId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetSchemaId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatefinalDataProductGroupMutation,
  CreatefinalDataProductGroupMutationVariables
>;
export const CreateIngestionServiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateIngestionService" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateIngestionServiceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createIngestionService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ingestionServiceId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateIngestionServiceMutation,
  CreateIngestionServiceMutationVariables
>;
export const CreateInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateInputDataAssetGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createInputDataAssetGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroupId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetSchemaId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInputDataAssetGroupMutation,
  CreateInputDataAssetGroupMutationVariables
>;
export const CreateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "projectId" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateResourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "resourceId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;
export const CreateResourceFolderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateResourceFolder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateResourceFolderInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createResourceFolder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resourceFolderId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceFolderMutation,
  CreateResourceFolderMutationVariables
>;
export const CreateSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSchemaInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSchema" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "schemaId" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSchemaMutation,
  CreateSchemaMutationVariables
>;
export const CreateTargetSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTargetSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTargetSchemaInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTargetSchema" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "targetSchemaId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTargetSchemaMutation,
  CreateTargetSchemaMutationVariables
>;
export const CreateTermDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTerm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTermInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTerm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTermMutation, CreateTermMutationVariables>;
export const CreateTransformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTransformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTransformationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTransformation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformationId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransformationMutation,
  CreateTransformationMutationVariables
>;
export const CreateTransformationServiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateTransformationService" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTransformationServiceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTransformationService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformationServiceId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransformationServiceMutation,
  CreateTransformationServiceMutationVariables
>;
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const CreateWarehouseServiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateWarehouseService" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateWarehouseServiceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createWarehouseService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "warehouseServiceId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWarehouseServiceMutation,
  CreateWarehouseServiceMutationVariables
>;
export const CreateWorkflowConnectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateWorkflowConnection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateWorkflowConnectionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createWorkflowConnection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkflowConnectionMutation,
  CreateWorkflowConnectionMutationVariables
>;
export const DeactivateOrganizationUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeactivateOrganizationUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeactivateOrganizationUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deactivateOrganizationUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeactivateOrganizationUserMutation,
  DeactivateOrganizationUserMutationVariables
>;
export const DeleteFinalDataProductGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteFinalDataProductGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteFinalDataProductGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteFinalDataProductGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFinalDataProductGroupMutation,
  DeleteFinalDataProductGroupMutationVariables
>;
export const DeleteInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteInputDataAssetGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteInputDataAssetGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInputDataAssetGroupMutation,
  DeleteInputDataAssetGroupMutationVariables
>;
export const DeleteResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteResourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;
export const DeleteTermDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteTerm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deleteTermInput2" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteTermInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteTerm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deleteTermInput2" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTermMutation, DeleteTermMutationVariables>;
export const DisableUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DisableUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DisableUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "disableUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "user" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DisableUserMutation, DisableUserMutationVariables>;
export const DownloadFileDestinationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DownloadFileDestination" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DownloadFileDestinationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "downloadFileDestination" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "destinationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "presignedUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadFileDestinationMutation,
  DownloadFileDestinationMutationVariables
>;
export const DuplicateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DuplicateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DuplicateProjectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "duplicateProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>;
export const HardDeleteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "HardDeleteUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "HardDeleteUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "hardDeleteUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HardDeleteUserMutation,
  HardDeleteUserMutationVariables
>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoginInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "challengeResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "session" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "idToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "userToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const MoveResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MoveResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MoveResourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "moveResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MoveResourceMutation,
  MoveResourceMutationVariables
>;
export const PreSignedUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PreSignedUrl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PreSignedUrlInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "preSignedUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "presignedUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreSignedUrlQuery, PreSignedUrlQueryVariables>;
export const RefreshTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "refreshToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RefreshTokenInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                { kind: "Field", name: { kind: "Name", value: "idToken" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const RemoveDataAssetFromFinalDataProductGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveDataAssetFromFinalDataProductGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "RemoveDataAssetFromFinalDataProductGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "removeDataAssetFromFinalDataProductGroup",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveDataAssetFromFinalDataProductGroupMutation,
  RemoveDataAssetFromFinalDataProductGroupMutationVariables
>;
export const RemoveDataAssetFromInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveDataAssetFromInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "RemoveDataAssetFromInputDataAssetGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "removeDataAssetFromInputDataAssetGroup",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveDataAssetFromInputDataAssetGroupMutation,
  RemoveDataAssetFromInputDataAssetGroupMutationVariables
>;
export const RemoveSchemaFromResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "removeSchemaFromResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SchemaResourceConnectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeSchemaFromResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveSchemaFromResourceMutation,
  RemoveSchemaFromResourceMutationVariables
>;
export const RemoveTargetSchemaFromProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveTargetSchemaFromProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "RemoveTargetSchemaFromProjectInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeTargetSchemaFromProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveTargetSchemaFromProjectMutation,
  RemoveTargetSchemaFromProjectMutationVariables
>;
export const RemoveTermFromResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveTermFromResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TermResourceConnectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeTermFromResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveTermFromResourceMutation,
  RemoveTermFromResourceMutationVariables
>;
export const ResendInviteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResendInvite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ResendInviteInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resendInvite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export const RunTransformationsInProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RunTransformationsInProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RunTransformationsInProjectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "runTransformationsInProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RunTransformationsInProjectMutation,
  RunTransformationsInProjectMutationVariables
>;
export const UpdateDataAssetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDataAsset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "updatedDataAsset" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateDataAssetInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateDataAsset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "updatedDataAsset" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDataAssetMutation,
  UpdateDataAssetMutationVariables
>;
export const UpdateDestinationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDestination" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateDestinationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateDestination" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDestinationMutation,
  UpdateDestinationMutationVariables
>;
export const UpdateFinalDataProductGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateFinalDataProductGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateFinalDataProductGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFinalDataProductGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalDataProductGroupId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFinalDataProductGroupMutation,
  UpdateFinalDataProductGroupMutationVariables
>;
export const UpdateGovernanceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateGovernance" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateGovernanceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateGovernance" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGovernanceMutation,
  UpdateGovernanceMutationVariables
>;
export const UpdateInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateInputDataAssetGroupInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateInputDataAssetGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroupId" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInputDataAssetGroupMutation,
  UpdateInputDataAssetGroupMutationVariables
>;
export const UpdateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateProjectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "result" },
            name: { kind: "Name", value: "updateProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateProjectNewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProjectNew" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateProjectInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectNewMutation,
  UpdateProjectNewMutationVariables
>;
export const UpdateResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateResourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateResource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
>;
export const UpdateRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newRole" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Role" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRole" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "newRole" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newRole" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateWorkspaceSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateWorkspaceSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateWorkspaceSchemaInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateWorkspaceSchema" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceSchemaMutation,
  UpdateWorkspaceSchemaMutationVariables
>;
export const UpdateSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateSchemaInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSchema" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSchemaMutation,
  UpdateSchemaMutationVariables
>;
export const UpdateTargetSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateTargetSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateTargetSchemaInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateTargetSchema" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTargetSchemaMutation,
  UpdateTargetSchemaMutationVariables
>;
export const UpdateTermDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateTerm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateTermInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateTerm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTermMutation, UpdateTermMutationVariables>;
export const UpdateTransformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateTransformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateTransformationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateTransformation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransformationMutation,
  UpdateTransformationMutationVariables
>;
export const UpdateUserDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables
>;
export const UpdateUserOrganizationRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserOrganizationRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateOrganizationRoleInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserOrganizationRole" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserOrganizationRoleMutation,
  UpdateUserOrganizationRoleMutationVariables
>;
export const UpdateWorkspaceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateWorkspace" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateWorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateWorkspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logoUploadContext" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fields" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contentType" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;
export const UpdateWorkspaceRoleScopeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateWorkspaceRoleScope" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateWorkspaceRoleScopeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateWorkspaceRoleScope" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceRoleScopeMutation,
  UpdateWorkspaceRoleScopeMutationVariables
>;
export const ValidateTransformationsInProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ValidateTransformationsInProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "ValidateTransformationsInProjectInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "validateTransformationsInProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateTransformationsInProjectMutation,
  ValidateTransformationsInProjectMutationVariables
>;
export const GetADataAssetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getADataAsset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataAssetFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DataAssetFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataAssets" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dataAssetFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dataAssetFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fields" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assetPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assetSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tableName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "childAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "connectionType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "connectionDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issues" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "title" },
                              name: { kind: "Name", value: "issueType" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "description" },
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "OrganizationDataAssetOutput",
                                },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ProjectDataAssetOutput",
                                },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "piiTypes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshRate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sensitivity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "storageService" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "validationStatus" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetADataAssetQuery, GetADataAssetQueryVariables>;
export const GetAgreementPolicyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAgreementPolicy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "monitoredPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAgreementPolicyQuery,
  GetAgreementPolicyQueryVariables
>;
export const GetCreatedDataProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCreatedDataProducts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createdDataProductFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CreatedDataProductFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDataProducts" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: {
                              kind: "Name",
                              value: "createdDataProductFilter",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "createdDataProductFilter",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "connectionType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "managers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshRate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sensitivity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "piiTypes" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "finalDataProductGroup",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCreatedDataProductsQuery,
  GetCreatedDataProductsQueryVariables
>;
export const GetCustomPoliciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCustomPolicies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "isEditable" },
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "_" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "get" },
                    value: {
                      kind: "StringValue",
                      value: "governance.isEditable",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "categories" },
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "_" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "get" },
                    value: {
                      kind: "StringValue",
                      value: "governance.policies",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "policies" },
                        name: { kind: "Name", value: "customPolicies" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "_" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "groupBy" },
                                value: {
                                  kind: "StringValue",
                                  value: "policyCategory",
                                  block: false,
                                },
                              },
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "toPairs" },
                                value: { kind: "EnumValue", value: "none" },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "policyCategory" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "title" },
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "policyCategory" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "dateTime" },
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "referenceDocuments",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "href" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomPoliciesQuery,
  GetCustomPoliciesQueryVariables
>;
export const GetDataAssetManagerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDataAssetManager" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataAssetId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dataAssetManagerCandidates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dataAssetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dataAssetId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDataAssetManagerQuery,
  GetDataAssetManagerQueryVariables
>;
export const GetDataAssetSuggestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDataAssetSuggestions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssetSuggestions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accessStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "issueStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "managers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pii" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDataAssetSuggestionsQuery,
  GetDataAssetSuggestionsQueryVariables
>;
export const GetDataAssetsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDataAssets" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getDataAssets",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataAssets" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issues" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ProjectDataAssetOutput",
                                },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "OrganizationDataAssetOutput",
                                },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "childAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sensitivity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshRate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "connectionType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "validationStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "piiTypes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataAssetsQuery, GetDataAssetsQueryVariables>;
export const GetDataProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDataProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "finalDataProductGroupFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowFinalDataProductGroupFilterInput",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataAssetFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowFinalDataProductGroupDataAssetFilterInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalDataProducts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "finalDataProductGroupFilter",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "finalDataProductGroupFilter",
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssets" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "dataAssetFilter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataAssetFilter" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "issueStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pii" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataProductQuery, GetDataProductQueryVariables>;
export const GetDataProductAssetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDataProductAsset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "finalDataProductGroupFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowFinalDataProductGroupFilterInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalDataProducts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "finalDataProductGroupFilter",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "finalDataProductGroupFilter",
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "pii" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schemaId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetSchema" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jsonSchema" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDataProductAssetQuery,
  GetDataProductAssetQueryVariables
>;
export const GetDestinationSheetsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDestinationSheets" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "destinationFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkflowDestinationFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "destinations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "destinationFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "destinationFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "destinationType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "destinationUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "connectionSettings" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDestinationSheetsQuery,
  GetDestinationSheetsQueryVariables
>;
export const GetExportCsvDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExportCSV" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "JSON" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exportCSV" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExportCsvQuery, GetExportCsvQueryVariables>;
export const GetIngestionServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetIngestionServices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ingestionServiceFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "IngestionServiceFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ingestionServices" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: {
                              kind: "Name",
                              value: "ingestionServiceFilter",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "ingestionServiceFilter",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "provider" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataAssetCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sourceCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "configurations" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "manager" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailAddress",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIngestionServicesQuery,
  GetIngestionServicesQueryVariables
>;
export const GetInputSchemaSetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getInputSchemaSet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputDataAssetGroupFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowInputDataAssetGroupFilterInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroups" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "inputDataAssetGroupFilter",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "inputDataAssetGroupFilter",
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "pii" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schemaId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetSchema" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jsonSchema" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInputSchemaSetQuery,
  GetInputSchemaSetQueryVariables
>;
export const GetMonitoredPoliciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getMonitoredPolicies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "isEditable" },
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "_" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "get" },
                    value: {
                      kind: "StringValue",
                      value: "governance.isEditable",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "categories" },
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "_" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "get" },
                    value: {
                      kind: "StringValue",
                      value: "governance.policies",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "policies" },
                        name: { kind: "Name", value: "monitoredPolicies" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "_" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "groupBy" },
                                value: {
                                  kind: "StringValue",
                                  value: "policyCategory",
                                  block: false,
                                },
                              },
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "toPairs" },
                                value: { kind: "EnumValue", value: "none" },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "policyCategory" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "title" },
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "policyCategory" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "dateTime" },
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "referenceDocuments",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "href" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMonitoredPoliciesQuery,
  GetMonitoredPoliciesQueryVariables
>;
export const GetOrganizationMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOrganizationMembers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getOrganizationMembers",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "members" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "assetManagedCount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationRole" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "projectManagedCount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workspaceCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationMembersQuery,
  GetOrganizationMembersQueryVariables
>;
export const GetOrganizationSheetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOrganizationSheet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkflowOrganizationFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contributors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataProductCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputDataAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectRole" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationSheetQuery,
  GetOrganizationSheetQueryVariables
>;
export const GetOrganizationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getOrganizations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participatingOrganizations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domain" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const GetProjectDataAssetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectDataAsset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "inputDataAssetsInputDataAssetFilter2",
            },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "InputDataAssetFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProjectDataAsset",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputDataAssets" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: {
                              kind: "Name",
                              value: "inputDataAssetFilter",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "inputDataAssetsInputDataAssetFilter2",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "connectionType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "inputDataAssetGroup",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "managers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshRate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sensitivity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDataAssetQuery,
  GetProjectDataAssetQueryVariables
>;
export const GetProjectManagerCandidatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectManagerCandidates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProjectEntityInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectManagerCandidates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectManagerCandidatesQuery,
  GetProjectManagerCandidatesQueryVariables
>;
export const GetProjectManagersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectManagers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProject",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectManagersQuery,
  GetProjectManagersQueryVariables
>;
export const GetProjectManagersAndOwnersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectManagersAndOwners" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "managers" },
            name: { kind: "Name", value: "projectManagerCandidates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "projectId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "owners" },
            name: { kind: "Name", value: "projectOwnerCandidates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "workspaceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workspaceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "projectId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectManagersAndOwnersQuery,
  GetProjectManagersAndOwnersQueryVariables
>;
export const GetProjectTargetSchemasDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectTargetSchemas" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "targetSchemaFilter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TargetSchemaFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProjectTargetSchemas",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetSchemas" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "targetSchemaFilter" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "targetSchemaFilter",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jsonSchema" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectTargetSchemasQuery,
  GetProjectTargetSchemasQueryVariables
>;
export const GetResourcesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetResources" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "resourceFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceResourceFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resources" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "resourceFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resourceFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ResourceOutput" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "terms" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schemas" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "internal" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ResourceFolderOutput" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isFolder" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetSchemaManagersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSchemaManagers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workspaceInput2" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workspaceInput2" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "schemas" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSchemaManagersQuery,
  GetSchemaManagersQueryVariables
>;
export const GetSchemasDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSchemas" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "schemaId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "schemas" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "schemaId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "schemaId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jsonSchema" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "versionNumber" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "types" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "piiTypes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSchemasQuery, GetSchemasQueryVariables>;
export const GetSourceDefinitionSpecificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSourceDefinitionSpecification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceDefinitionSpecFilter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SourceDefinitionSpecFilter" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "sourceDefinitionSpecification",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: {
                              kind: "Name",
                              value: "sourceDefinitionSpecFilter",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "sourceDefinitionSpecFilter",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documentationUrl" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "connectionSpecification",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSourceDefinitionSpecificationQuery,
  GetSourceDefinitionSpecificationQueryVariables
>;
export const GetSourcesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSources" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceFilter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SourceFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sources" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "sourceFilter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sourceFilter" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sourceType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logoUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataAssets" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "manager" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailAddress",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documentationUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSourcesQuery, GetSourcesQueryVariables>;
export const GetSourcesInternalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSourcesInternal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getSourcesInternal",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceDefinitions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documentationUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "sourceDefinitionId",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSourcesInternalQuery,
  GetSourcesInternalQueryVariables
>;
export const GetTargetSchemasDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTargetSchemas" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "targetSchemaFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TargetSchemaFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getTargetSchemas",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetSchemas" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "targetSchemaFilter" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "targetSchemaFilter",
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jsonSchema" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "managers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "piiTypes" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "types" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "versionNumber" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTargetSchemasQuery,
  GetTargetSchemasQueryVariables
>;
export const TermsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Terms" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "terms" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addedOn" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "tagNames" },
                        name: { kind: "Name", value: "tags" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "_" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "map" },
                                value: {
                                  kind: "StringValue",
                                  value: "name",
                                  block: false,
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TermsQuery, TermsQueryVariables>;
export const GetTransformationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTransformation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transformationFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkflowTransformationFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "transformationFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "transformationFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "job" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "arguments" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "duration" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "facets" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nominalEndTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nominalStartTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "runId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobCount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourceUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transformationMethod" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransformationQuery,
  GetTransformationQueryVariables
>;
export const GetTransformationServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTransformationServices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transformationServices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "provider" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "languages" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "projectCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jobCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "configurations" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "manager" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailAddress",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransformationServicesQuery,
  GetTransformationServicesQueryVariables
>;
export const GetWarehouseServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWarehouseServices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "services" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "warehouseServices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "provider" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "configurations" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "owner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "manager" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailAddress",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarehouseServicesQuery,
  GetWarehouseServicesQueryVariables
>;
export const GetWorkspacesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWorkspaces" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workspaces" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerOrganizationName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "policies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifiedAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptedPolicies" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspacesQuery, GetWorkspacesQueryVariables>;
export const GetGovernanceInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getGovernanceInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goals" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "governanceType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legalDocuments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "monitoredPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "referenceDocuments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGovernanceInfoQuery,
  GetGovernanceInfoQueryVariables
>;
export const InputDataAssetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InputDataAsset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputDataAssetGroupFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowInputDataAssetGroupFilterInput",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataAssetFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "WorkflowInputDataAssetGroupDataAssetFilterInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroups" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "inputDataAssetGroupFilter",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "inputDataAssetGroupFilter",
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssets" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "dataAssetFilter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataAssetFilter" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "issueStatus" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pii" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InputDataAssetQuery, InputDataAssetQueryVariables>;
export const OnboardDataAssetToInputDataAssetGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OnboardDataAssetToInputDataAssetGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "OnboardDataAssetToInputDataAssetGroupInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "onboardDataAssetToInputDataAssetGroup",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataAssetId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "presignedUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnboardDataAssetToInputDataAssetGroupMutation,
  OnboardDataAssetToInputDataAssetGroupMutationVariables
>;
export const OnboardDataAssetToWorkspaceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OnboardDataAssetToWorkspace" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OnboardDataAssetToWorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "onboardDataAssetToWorkspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataAssetId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "presignedUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnboardDataAssetToWorkspaceMutation,
  OnboardDataAssetToWorkspaceMutationVariables
>;
export const GetParticipatingOrganizationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getParticipatingOrganizations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participatingOrganizations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetParticipatingOrganizationsQuery,
  GetParticipatingOrganizationsQueryVariables
>;
export const GetProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProject",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workflowState" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataProductCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataProductType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueState" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goals" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metrics" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "collaboratingMembers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "collaboratingOrganizations",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataConsumers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "destinations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destinationType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destinationUrl" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sensitivity" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "backgroundMaterials" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectsForDashboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectsForDashboard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProjectsForDashboard",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "updatedAt" },
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsForDashboardQuery,
  GetProjectsForDashboardQueryVariables
>;
export const GetProjectInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WorkspaceProjectFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProjectInfo",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "projectFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectInfoQuery, GetProjectInfoQueryVariables>;
export const GetProjectsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjects" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getProjects",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projects" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "tags" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inputAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "managers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const RefreshUserTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RefreshUserToken" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshUserToken" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "userToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshUserTokenQuery,
  RefreshUserTokenQueryVariables
>;
export const GetSchemaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSchema" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataAssetFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DataAssetFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataAssets" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dataAssetFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dataAssetFilter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fields" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pii" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jsonSchema" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSchemaQuery, GetSchemaQueryVariables>;
export const UpdateDataAssetFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDataAssetFile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateDataAssetFileInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateDataAssetFile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataAssetId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "presignedUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDataAssetFileMutation,
  UpdateDataAssetFileMutationVariables
>;
export const GetCurrentUserInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCurrentUserInfo" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getCurrentUserInfo",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workspaces" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerOrganizationName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptedPolicies" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentUserInfoQuery,
  GetCurrentUserInfoQueryVariables
>;
export const GetCurrentUserRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCurrentUserRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CurrentUserWorkspaceRoleFilter" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getCurrentUserRole",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workspaceRole" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "input" },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentUserRoleQuery,
  GetCurrentUserRoleQueryVariables
>;
export const GetWorkflowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWorkflow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workflowFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkflowFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "workflowFilterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workflowFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "projectName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssetCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inputDataAssetGroups" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerName" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "pii" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schemaId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedTransformations" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "job" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startedAt" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalDataProducts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataAssets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerName" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedNodes" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "pii" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schemaId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "destinations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "destinationType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifiedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "projectName" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkflowQuery, GetWorkflowQueryVariables>;
export const GetWorkspaceInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWorkspaceInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "accessible" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: true },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "logoUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governance" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "accessible" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEditable" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceInfoQuery,
  GetWorkspaceInfoQueryVariables
>;
export const GetWorkspaceMembersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getWorkspaceMembers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkspaceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "workspace" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "connection" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "cacheKey" },
                    value: {
                      kind: "StringValue",
                      value: "getWorkspaceMembers",
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "members" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastAccessed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workspaceRole" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceMembersQuery,
  GetWorkspaceMembersQueryVariables
>;
