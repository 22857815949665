import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import GroupIcon from "@mui/icons-material/Group";
import { AssetDetailPage } from "../AssetDetail/AssetDetailPage/AssetDetailPage";
import { ForgotPassword } from "../Auth/ForgotPassword";
import { Login } from "../Auth/Login";
import { ResetPassword } from "../Auth/ResetPassword";
import { MyProfilePage } from "../ContentPages/MyProfilePage";
import { DataAssetCatalogPage } from "../DataAssetCatalog/DataAssetCatalogPage/DataAssetCatalogPage";
import { Connection } from "../DataStackServices/Connection/Connection";
import { Transformation } from "../DataStackServices/Transformation/Transformation";
import { Warehouse } from "../DataStackServices/Warehouse/Warehouse";
import { Unauthorized } from "../ErrorPage/Unauthorized";
import { WorkspaceRoleEnum } from "../generated";
import { GovernancePage } from "../Governance/GovernancePage";
import { GovernancePolicyDetailPage } from "../Governance/GovernancePolicyDetailPage";
import { Resources } from "../KnowledgeCenter/Resources/Resources";
import { Schemas } from "../KnowledgeCenter/Schema";
import { SchemaCompare } from "../KnowledgeCenter/Schema/SchemaCompare/SchemaCompare";
import { SchemaDetailPage } from "../KnowledgeCenter/Schema/SchemaDetail/SchemaDetailPage";
import { Terms } from "../KnowledgeCenter/Terms";
import { MembersPage } from "../Members/MembersPage/MembersPage";
import { MyOrganizationPage } from "../MyOrganization/MyOrganizationPage";
import { CreatedDataProductsPage } from "../Projects/CreatedDataProductsPage/CreatedDataProductsPage";
import { MyDataAssetPage } from "../Projects/MyDataAssetPage/MyDataAssetPage";
import { ProjectDataAssetPage } from "../Projects/ProjectDataAssetPage/ProjectDataAssetPage";
import { ProjectOverviewPage } from "../Projects/ProjectOverviewPage/ProjectOverviewPage";
import { ProjectSchemaEditDetailPage } from "../Projects/ProjectSchemaPage/ProjectSchemaDetailsPage";
import { ProjectSchemaPage } from "../Projects/ProjectSchemaPage/ProjectSchemaPage";
import { ProjectsListPage } from "../Projects/ProjectsListPage/ProjectsListPage";
import { ProjectWorkflowPage } from "../ProjectWorkflow/ProjectWorkflowPage";
import { All } from "../Sources/All/All";
import { WorkspaceConfigurationPage } from "../WorkspaceConfiguration/WorkspaceConfigurationPage";
import { WorkspaceSettingsPage } from "../WorkspaceSettings";
import WorkspaceSwitcherPage from "../WorkspaceSwitcher/WorkspaceSwitcherPage";
import { PolicyAgreement } from "../Auth/RegisterFlow/PolicyAgreement/PolicyAgreement";
import { RegisterUser } from "../Auth/RegisterFlow/RegisterUser/RegisterUser";
import { BrightBotViiIcon } from "../common/Icons/BrightBotViiIcon";
import { DataAssetLibraryIcon } from "../common/Icons/DataAssetLibraryIcon";
import { GovernanceIcon } from "../common/Icons/GovernanceIcon";
import { HomeViiIcon } from "../common/Icons/HomeViiIcon";
import { NavBuildViiIcon } from "../common/Icons/NavBuildViiIcon";
import { NavConnectViiIcon } from "../common/Icons/NavConnectViiIcon";
import { NavGovernViiIcon } from "../common/Icons/NavGovernViiIcon";
import { ProjectsFolderViiIcon } from "../common/Icons/ProjectsFolderViiIcon";
import { SourcesViiIcon } from "../common/Icons/SourcesViiIcon";
import HomePage from "../features/home/pages";
import { Session } from "../Session/Session";
import TransformIcon from "@mui/icons-material/Transform";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {
  StyledIngestionIcon,
  StyledMenuBookIcon,
  StyledSchemaIcon,
  StyledSettingsIcon,
} from "./style";
import BbAssistant from "../BbAssistant/src";

export enum NavLevel {
  root = "root",
  first = "first-level",
  second = "second-level",
  third = "third-level",
  fourth = "fourth-level",
}

export const AssetRoutes = {
  Workspace: {
    name: "Workspace",
    path: `/workspace`,
    icon: <GroupIcon />,
    level: NavLevel.root,
    component: WorkspaceSwitcherPage,
    routes: {
      HomePage: {
        name: "Home",
        icon: <HomeViiIcon />,
        path: `/workspace/:workspaceId/home`,
        nav: true,
        key: "home",
        level: NavLevel.first,
        component: HomePage,
      },
      // BrightBotPage: {
      //   name: "BrightBot",
      //   path: `/workspace/:workspaceId/brightbot`,
      //   icon: <BrightBotViiIcon />,
      //   nav: true,
      //   key: "brightbot",
      //   component: Session,
      //   level: NavLevel.first,
      // },
      BrightBotPage: {
        name: "BrightBot",
        nav: true,
        icon: <BrightBotViiIcon />,
        key: "brightbot",
        level: NavLevel.first,
        path: `/workspace/:workspaceId/brightbot`,
        component: Session,
      },
      SessionThread: {
        name: "Session Thread",
        path: "/workspace/:workspaceId/brightbot/:threadId",
        nav: false,
        key: "brightbot",
        // level: NavLevel.second,
        component: Session,
      },

      Connect: {
        name: "Connect",
        icon: <NavConnectViiIcon />,
        nav: true,
        level: NavLevel.first,
        access: [WorkspaceRoleEnum.Admin],
        routes: {
          Sources: {
            name: "Sources",
            path: `/workspace/:workspaceId/connect/sources`,
            icon: <SourcesViiIcon />,
            nav: true,
            key: "sources",
            level: NavLevel.second,
            component: All,
            routes: {
              DataSources: {
                name: "Data Sources",
                path: `/workspace/:workspaceId/connect/sources/data-sources`,
                icon: <SourcesViiIcon />,
                nav: true,
                key: "data-sources",
                level: NavLevel.second,
                component: All,
              },
              Files: {
                name: "Files",
                path: `/workspace/:workspaceId/connect/sources/files`,
                icon: <GroupIcon />,
                nav: true,
                key: "files",
                level: NavLevel.third,
                component: Resources,
              },
              ResourcesFolder: {
                name: "Files",
                path: `/workspace/:workspaceId/connect/sources/files/:folderId`,
                key: "files",
                icon: <GroupIcon />,
                level: NavLevel.third,
                component: Resources,
              },
            },
          },
        },
      },
      Govern: {
        name: "Govern",
        icon: <NavGovernViiIcon />,
        nav: true,
        level: NavLevel.first,
        access: [
          WorkspaceRoleEnum.Admin,
          WorkspaceRoleEnum.Collaborator,
          WorkspaceRoleEnum.Viewer,
        ],
        routes: {
          DataCatalog: {
            name: "Data Catalog",
            path: `/workspace/:workspaceId/govern/data-catalog`,
            nav: true,
            key: "data-catalog",
            icon: <DataAssetLibraryIcon />,
            component: DataAssetCatalogPage,
            level: NavLevel.second,
            access: [WorkspaceRoleEnum.Admin, WorkspaceRoleEnum.Collaborator],
          },
          Glossary: {
            name: "Glossary",
            path: `/workspace/:workspaceId/govern/glossary`,
            icon: <StyledMenuBookIcon />,
            nav: true,
            key: "glossary",
            component: Terms,
            level: NavLevel.third,
          },
          Schemas: {
            name: "Schemas",
            path: `/workspace/:workspaceId/govern/schemas`,
            icon: <StyledSchemaIcon />,
            level: NavLevel.third,
            routes: {
              Schema: {
                name: "Schemas",
                path: `/workspace/:workspaceId/govern/schemas`,
                icon: <StyledSchemaIcon />,
                nav: true,
                key: "schemas",
                level: NavLevel.fourth,
                component: Schemas,
              },
              SchemaCompare: {
                name: "Schema Compare",
                path: `/workspace/:workspaceId/govern/schemas/compare`,
                icon: <StyledSchemaIcon />,
                level: NavLevel.fourth,
                component: SchemaCompare,
              },
              SchemaDetailPage: {
                name: "Schema Detail Page",
                path: `/workspace/:workspaceId/govern/schemas/:schemaId`,
                icon: <StyledSchemaIcon />,
                level: NavLevel.fourth,
                component: SchemaDetailPage,
              },
            },
          },
          Policies: {
            name: "Policies",
            path: `/workspace/:workspaceId/governance`,
            icon: <GovernanceIcon />,
            key: "governance",
            nav: true,
            level: NavLevel.second,
            component: GovernancePage,
          },
        },
      },
      Build: {
        name: "Build",
        icon: <NavBuildViiIcon />,
        nav: true,
        level: NavLevel.first,
        routes: {
          Projects: {
            name: "Projects",
            path: `/workspace/:workspaceId/project`,
            icon: <ProjectsFolderViiIcon />,
            nav: false,
            level: NavLevel.second,
            routes: {
              ProjectsListPage: {
                name: "Projects",
                path: `/workspace/:workspaceId/project`,
                icon: <ProjectsFolderViiIcon />,
                nav: true,
                key: "project",
                level: NavLevel.third,
                component: ProjectsListPage,
              },
              ProjectOverview: {
                name: "Project Overview",
                path: `/workspace/:workspaceId/project/:projectId/project-overview`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectOverviewPage,
              },
              ProjectOverviewEdit: {
                name: "Project Overview",
                path: `/workspace/:workspaceId/project/:projectId/project-overview/edit`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                access: [WorkspaceRoleEnum.Admin],
                component: ProjectOverviewPage,
              },
              ProjectWorkflow: {
                name: "Project Workflow Page",
                path: `/workspace/:workspaceId/project/:projectId/project-workflow`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectWorkflowPage,
                access: [
                  WorkspaceRoleEnum.Admin,
                  WorkspaceRoleEnum.Collaborator,
                ],
              },
              CreatedDataProducts: {
                name: "Created Data Products Page",
                path: `/workspace/:workspaceId/project/:projectId/created-data-products`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: CreatedDataProductsPage,
              },
              ProjectDataAsset: {
                name: "ProjectDataAsset",
                path: `/workspace/:workspaceId/project/:projectId/data-assets`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectDataAssetPage || MyDataAssetPage,
              },
              ProjectSchema: {
                name: "Project Schema",
                path: `/workspace/:workspaceId/project/:projectId/project-schema`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                routes: {
                  ProjectSchemaPage: {
                    name: "Project Schema Detail Page",
                    path: `/workspace/:workspaceId/project/:projectId/project-schema`,
                    icon: <DonutSmallIcon />,
                    nav: false,
                    level: NavLevel.fourth,
                    component: ProjectSchemaPage,
                  },
                  ProjectSchemaDetail: {
                    name: "Project Schema Detail Page",
                    path: `/workspace/:workspaceId/project/:projectId/project-schema/:schemaId`,
                    icon: <DonutSmallIcon />,
                    nav: false,
                    level: NavLevel.fourth,
                    component: ProjectSchemaEditDetailPage,
                  },
                },
              },
            },
          },
          BbStudio: {
            name: "Brightbot Studio",
            path: `/workspace/:workspaceId/brightbot-studio`,
            key: "BbStudio",
            icon: <BrightBotViiIcon />,
            level: NavLevel.second,
            access: [WorkspaceRoleEnum.Admin, WorkspaceRoleEnum.Collaborator],
            routes: {
              BbStudioPage: {
                name: "Brightbot Studio",
                path: `/workspace/:workspaceId/brightbot-studio`,
                nav: true,
                key: "BbStudio",
                icon: <BrightBotViiIcon />,
                level: NavLevel.second,
                component: BbAssistant,
              },
              BbStudioThread: {
                name: "Brightbot Studio",
                path: `/workspace/:workspaceId/brightbot-studio/thread/:chatId`,
                nav: false,
                key: "BbStudio",
                icon: <BrightBotViiIcon />,
                level: NavLevel.second,
                component: BbAssistant,
              },
              BbStudioAssistant: {
                name: "Brightbot Studio",
                path: `/workspace/:workspaceId/brightbot-studio/assistant/:assistantId`,
                nav: false,
                key: "BbStudio",
                icon: <BrightBotViiIcon />,
                level: NavLevel.second,
                component: BbAssistant,
              },
              BbStudioAssistantEdit: {
                name: "Brightbot Studio",
                path: `/workspace/:workspaceId/brightbot-studio/assistant/:assistantId/edit`,
                nav: false,
                key: "BbStudio",
                icon: <BrightBotViiIcon />,
                level: NavLevel.second,
                component: BbAssistant,
              },
            },
          },
        },
      },
      Configure: {
        name: "Configure",
        icon: <StyledSettingsIcon />,
        nav: true,
        level: NavLevel.first,
        routes: {
          Warehouse: {
            name: "Warehouse",
            path: `/workspace/:workspaceId/configure/warehouse`,
            icon: <WarehouseIcon />,
            nav: true,
            key: "warehouse",
            component: Warehouse,
            level: NavLevel.third,
          },
          Ingestion: {
            name: "Ingestion",
            path: `/workspace/:workspaceId/configure/ingestion`,
            icon: <StyledIngestionIcon />,
            nav: true,
            key: "ingestion",
            component: Connection,
            level: NavLevel.third,
          },
          Transform: {
            name: "Transform",
            path: `/workspace/:workspaceId/configure/transformation`,
            icon: <TransformIcon />,
            nav: true,
            key: "transformation",
            component: Transformation,
            level: NavLevel.third,
          },
        },
      },
      DataAssetDetailPage: {
        name: "Data Asset Detail Page",
        path: `/workspace/:workspaceId/asset-details/:dataAssetId`,
        icon: <GroupIcon />,
        component: AssetDetailPage,
        level: NavLevel.first,
      },
      Members: {
        name: "Members Page",
        path: `/workspace/:workspaceId/members`,
        icon: <GroupIcon />,
        nav: false,
        component: MembersPage,
        level: NavLevel.first,
      },
      GovernancePolicyDetail: {
        name: "Governance Policy Detail Page",
        path: `/workspace/:workspaceId/governance/detail-id`,
        icon: <GroupIcon />,
        nav: false,
        component: GovernancePolicyDetailPage,
        level: NavLevel.first,
      },
      MyOrganization: {
        name: "My Organization Page",
        path: `/workspace/:workspaceId/my-organization`,
        icon: <GroupIcon />,
        nav: false,
        component: MyOrganizationPage,
        level: NavLevel.first,
      },
      MyProfile: {
        name: "My Profile Page",
        path: `/workspace/:workspaceId/my-profile`,
        icon: <GroupIcon />,
        nav: false,
        component: MyProfilePage,
        level: NavLevel.first,
      },
      WorkspaceConfiguration: {
        name: "Workspace Configuration Page",
        path: `/workspace/:workspaceId/workspace-configuration`,
        icon: <GroupIcon />,
        nav: false,
        component: WorkspaceConfigurationPage,
        level: NavLevel.first,
      },
      WorkspaceSettings: {
        name: "Workspace Settings Page",
        path: `/workspace/:workspaceId/workspace-settings`,
        icon: <GroupIcon />,
        nav: false,
        component: WorkspaceSettingsPage,
        access: [WorkspaceRoleEnum.Admin],
        level: NavLevel.first,
      },
    },
  },
};

export const LoginRoutes = {
  LOGIN: {
    name: "Login",
    path: `/login`,
    icon: <GroupIcon />,
    level: NavLevel.root,
    routes: {
      Login: {
        name: "Login",
        path: `/login`,
        icon: <GroupIcon />,
        component: Login,
        level: NavLevel.first,
      },
      ForgotPassword: {
        name: "ForgotPassword",
        path: `/login/forgotPassword`,
        icon: <GroupIcon />,
        level: NavLevel.first,
        component: ForgotPassword,
      },
      ResetPassword: {
        name: "ForgotPassword",
        path: `/login/resetPassword`,
        icon: <GroupIcon />,
        level: NavLevel.first,
        component: ResetPassword,
      },
    },
  },
  RegisterUserPage: {
    name: "Register User Page",
    path: `/register-user/:username/:session`,
    icon: <GroupIcon />,
    component: RegisterUser,
    level: NavLevel.root,
  },
  PolicyAgreement: {
    name: "Policy Agreement Page",
    path: `/agree-policy/?:workspaceId`,
    icon: <GroupIcon />,
    component: PolicyAgreement,
    level: NavLevel.root,
  },
  404: {
    name: "404",
    path: "/404",
    icon: <GroupIcon />,
    level: NavLevel.root,
    component: Unauthorized,
  },
};
