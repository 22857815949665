import { styled, Box, Typography, Button } from "@mui/material";
import { Link as CustomLink } from "../Link/Link";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: static;
`;

export const SideNavLogo = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  padding: 13px 16px;
`;

export const SideNavLogoLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  height: 24px;

  .large-logo {
    width: 128px;
    height: 24px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .logo-icon {
    width: 24px;
    height: 24px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
`;

export const SideNavLogoText = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const SideNavLogoTypo = styled(Typography)`
  color: ${brighthiveTheme.palette.common.black};
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  margin-left: 10px;
  font-weight: normal;
`;

export const SideNavTopWrap = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 4px 12px;

  .sidebutton-text,
  .cs-hidden-text {
    font-size: 14px;
  }
`;

export const PostTitle = styled(Typography)`
  color: ${brighthiveTheme.palette.grayAccent.dark};
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  gap: 8px;
  font-weight: 500;
  border-radius: 6px;
  padding-bottom: 14px;
  padding-top: 14px;
  margin-bottom: 24px;
  transition: margin-bottom 0.1s ease;

  .MuiSvgIcon-root {
    position: relative;
    width: 15px;
    height: 15px;
    transition: all 0.1s ease;
  }

  .cs-hidden-text {
    transition: opacity 0.1s ease;
  }

  .sidenav-collapsed & {
    margin-bottom: 0;

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      left: 8px;
    }

    .cs-hidden-text {
      white-space: nowrap;
      opacity: 0;
    }
  }
`;

export const SidebarOwnCollapseWrapper = styled(Box)`
  display: none;
  margin-top: auto;
  padding: 24px;
`;

export const CollapsibleWrapper = styled("div")`
  border: solid ${brighthiveTheme.palette.gray.light};
  border-width: 1px 0;
  margin: 0 -12px -1px;

  &:last-child {
    border-bottom: 0;
  }

  > div {
    > div:first-child {
      border-top-color: transparent;
    }
  }

  .sidenav-collapsed & .cs-hidden-text {
    white-space: nowrap;
    opacity: 0;
  }
`;

export const CollapseToggler = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  justify-content: flex-start;
  width: 100%;
  border-radius: 6px;
  padding: 14px 14px 14px 22px;
  background-color: transparent;
  min-width: 1px;
  color: ${brighthiveTheme.palette.accent.contrastText};
  display: inline-flex;

  &:hover,
  .active > & {
    background-color: transparent;
    color: ${brighthiveTheme.palette.primary.dark};
  }

  .active > & {
    border-bottom-color: transparent;
  }

  > .MuiSvgIcon-root {
    margin-right: 8px;
    margin-left: -4px;
    width: 28px;
    height: 28px;
  }

  .sidenav-collapsed & {
    pointer-events: none;
  }
`;

export const TogllerIcon = styled("i")`
  margin-left: auto;
  position: relative;
  top: 3px;
`;

export const CollapseDrop = styled("div")`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease,
    padding 0.3s ease, margin 0.3s ease;

  &.open {
    max-height: 500px; /* Adjust depending on content */
    opacity: 1;
    transform: translateY(0);
  }
`;

export const InnerCollapsibleWrapper = styled("div")`
  padding: 0 12px 0 46px;
  margin-bottom: 10px;
`;

export const SidebarOwnCollapseButton = styled(Button)`
  background: ${brighthiveTheme.palette.primary.active};
  color: ${brighthiveTheme.palette.primary.dark};
  border-radius: 6px;
  width: 28px;
  height: 28px;
  min-width: 1px;
  padding: 0;
  position: absolute;
  bottom: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: ${brighthiveTheme.palette.primary.main};
    color: ${brighthiveTheme.palette.common.white};
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const InnerCollapseToggler = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.428;
  justify-content: flex-start;
  width: 100%;
  border-radius: 6px;
  padding: 4px 8px;
  background-color: transparent;
  color: ${brighthiveTheme.palette.accent.contrastText};
  display: inline-flex;
  min-width: 1px;

  &:hover,
  &.active {
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  > .MuiSvgIcon-root {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }

  > i {
    right: -4px;
  }
`;

export const InnerTogllerIcon = styled("i")`
  margin-left: auto;
  position: relative;
  top: 3px;
  right: 2px;
`;

export const InnerCollapseDrop = styled("div")`
  position: relative;
  padding-top: 10px;
  padding-bottom: 12px;
`;
