import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MovePrevIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.875 15.5C15.765 15.5 15.657 15.4709 15.5619 15.4156L3.68688 8.54062C3.59216 8.48568 3.51355 8.40683 3.4589 8.31195C3.40425 8.21707 3.37548 8.10949 3.37548 8C3.37548 7.89051 3.40425 7.78293 3.4589 7.68805C3.51355 7.59317 3.59216 7.51432 3.68688 7.45938L15.5619 0.584375C15.6569 0.529391 15.7646 0.500387 15.8744 0.50028C15.9841 0.500172 16.092 0.528966 16.1871 0.583763C16.2822 0.638561 16.3611 0.717431 16.4161 0.812442C16.471 0.907452 16.5 1.01525 16.5 1.125V14.875C16.5 15.0408 16.4342 15.1997 16.3169 15.3169C16.1997 15.4342 16.0408 15.5 15.875 15.5ZM0.25 0.5H1.5V15.5H0.25V0.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
