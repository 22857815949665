import { Box, BoxProps, useTheme } from "@mui/material";

interface SpecificSidenavProps {
  show: boolean;
}

type SidenavProps = SpecificSidenavProps & BoxProps;

export const Sidenav = ({ show, ...rest }: SidenavProps) => {
  const { palette } = useTheme();

  return (
    <Box
      flexShrink={0}
      role="navigation"
      display="flex"
      flexDirection="column"
      position="relative"
      minHeight="100%"
      boxSizing="border-box"
      sx={{
        backgroundColor: palette.common.white,
        borderRight: `1px solid ${palette.action.disabledBackground}`,
        width: show ? "16.25rem" : "3rem",
        paddingBottom: "2.2rem",
      }}
      {...rest}
    />
  );
};
