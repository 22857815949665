import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../generated";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useGetUserInfo = (workspaceId?: string) => {
  const { data } = useQuery(GetCurrentUserInfoDocument, {
    skip: !workspaceId,
  });

  const id = data?.currentUser?.id;

  useEffect(() => {
    if (id) {
      localStorage.setItem("userId", id);
    }
  }, [id]);

  return { data };
};
