import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PauseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66667 11.8327V0.166016H10V11.8327H6.66667ZM0 11.8327V0.166016H3.33333V11.8327H0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
