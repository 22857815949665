import { useState } from "react";
import { CaretDownViiIcon } from "../Icons/CaretDownViiIcon";
import { CaretUpViiIcon } from "../Icons/CaretUpViiIcon";
import {
  CollapseDrop,
  CollapseToggler,
  CollapsibleWrapper,
  TogllerIcon,
} from "./style";

export const CollapsibleButton = ({
  children,
  show,
  label,
  icon,
  level,
  collapse,
  setCollapse,
}: any) => {
  const [open, setOpen] = useState<boolean>(show);

  const collapseHandler = () => {
    if (collapse) {
      setCollapse(false);
      setOpen(true);
    }
  };

  return (
    <CollapsibleWrapper
      sx={{ cursor: "pointer" }}
      onClick={collapseHandler}
      className={open ? "active" : ""}
    >
      <CollapseToggler onClick={() => setOpen((prev) => !prev)}>
        {icon && icon}
        <span className="cs-hidden-text">{label} </span>
        {!collapse && (
          <TogllerIcon>
            {open ? <CaretUpViiIcon /> : <CaretDownViiIcon />}
          </TogllerIcon>
        )}
      </CollapseToggler>
      <CollapseDrop
        className={`side-slide ${level} ${open && !collapse ? "open" : ""}`}
      >
        {children}
      </CollapseDrop>
    </CollapsibleWrapper>
  );
};
