import { Box, styled } from "@mui/system";

export const MainContainer = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  width: "fit-content",
  padding: "0.25rem",
  borderRadius: "50px",
  backgroundColor: "#F2F2F2",
}));

export const StyledActiveTab = styled(Box)(({ theme }) => ({
  top: ".25rem",
  left: "0",
  height: "fit-content",
  position: "absolute",
  border: "1px solid #D4D4D4",
  backgroundColor: theme.palette.common.white,
  borderRadius: "50px",
  boxSizing: "border-box",
  transition: "all 0.3s ease",
}));

export const StyledTab = styled(Box)(({ theme }) => ({
  zIndex: "1",
  cursor: "pointer",
  padding: ".25rem 1.719rem",
}));
