import { useCallback, useState } from "react";
import { Message } from "../types";
import { omit } from "lodash";
import bBaseApi from "../api/bBaseApi";
const baseUrl = process.env.REACT_APP_BB_ASSISTANT_URL;

export function useMessageEditing(
  threadId: string | undefined,
  onSuccess: () => void
) {
  const [editing, setEditing] = useState<Record<string, Message>>({});

  const recordEdits = useCallback((msg: Message) => {
    setEditing((current) => ({ ...current, [msg.id]: msg }));
  }, []);
  const commitEdits = useCallback(async () => {
    if (!threadId) return;
    bBaseApi
      .post(
        `/threads/${threadId}/state`,
        JSON.stringify({ values: Object.values(editing) })
      )
      .then((res) => {
        if (res.data) {
          setEditing({});
        } else {
          return Promise.reject(res.statusText);
        }
        onSuccess();
      })
      .catch((e) => {
        setEditing({});
        console.error(e);
      });
  }, [threadId, editing, onSuccess]);
  const abandonEdits = useCallback((msg?: Message) => {
    if (msg) {
      setEditing((current) => {
        return omit(current, msg.id);
      });
    } else {
      setEditing({});
    }
  }, []);

  return {
    editing,
    recordEdits,
    commitEdits: threadId ? commitEdits : undefined,
    abandonEdits,
  };
}
