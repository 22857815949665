import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CardsViewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon height="18" width="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.89543 0 2 0H6C7.10457 0 8 0.89543 8 2V6C8 7.10457 7.10457 8 6 8H2C0.89543 8 0 7.10457 0 6V2ZM6 2H2V6H6V2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10 0.89543 10.8954 0 12 0H16C17.1046 0 18 0.89543 18 2V6C18 7.10457 17.1046 8 16 8H12C10.8954 8 10 7.10457 10 6V2ZM16 2H12V6H16V2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 10.8954 0.89543 10 2 10H6C7.10457 10 8 10.8954 8 12V16C8 17.1046 7.10457 18 6 18H2C0.89543 18 0 17.1046 0 16V12ZM6 12H2V16H6V12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C10 10.8954 10.8954 10 12 10H16C17.1046 10 18 10.8954 18 12V16C18 17.1046 17.1046 18 16 18H12C10.8954 18 10 17.1046 10 16V12ZM16 12H12V16H16V12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
