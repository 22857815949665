import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  useTheme,
  Modal,
  Box,
  useForkRef,
  TextField,
  Button,
  FormLabel,
} from "@mui/material";
import UppyModal from "../../common/UppyModal/UppyModal";
import {
  CreateResourceDocument,
  GetResourcesDocument,
  ResourceType,
} from "../../generated";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { CustomTextField } from "../../common/CustomTextField/CustomTextField";
import { useState } from "react";
import { Tag } from "../../common/Tag/Tag";
import { ButtonDefault, ButtonPrimary } from "./style";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const resourceNames = {
  DOCUMENT: "Document",
  LINK: "Webpage",
  IMAGE: "Image",
  VIDEO: "Video",
};

export const AddResource = ({
  setResource,
  resource,
  submitButtonText,
}: {
  setResource: Function;
  resource: any;
  submitButtonText: string;
}) => {
  const { palette } = useTheme();
  const { workspaceId, folderId } = useParams();
  const [tag, setTag] = useState<any>();

  const [createResource, { loading }] = useMutation(CreateResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });

  const validate = (path: string, type: string) => {
    const lastCh = path.slice(-5).toLocaleLowerCase();

    if (!path.includes("http")) return false;

    if (type === ResourceType.Document) {
      return ["docs", "doc", "ooxml", "pdf", "xls", "csv"].some((t) =>
        lastCh.includes(t)
      );
    } else if (type === ResourceType.Image) {
      return ["png", "jpg", "gif", "webp"].some((t) => lastCh.includes(t));
    } else if (type === ResourceType.Video) {
      return ["mp4", "avi", "mov", "mkv", "webm"].some((t) =>
        lastCh.includes(t)
      );
    } else if (type === ResourceType.Link) {
      return ![
        "docs",
        "doc",
        "ooxml",
        "pdf",
        "xls",
        "csv",
        "png",
        "jpg",
        "gif",
        "webp",
        "mp4",
        "avi",
        "mov",
        "mkv",
        "webm",
      ].some((t) => lastCh.includes(t));
    } else {
      return false;
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{
          path: "",
          type: resource === "File" ? ResourceType.Document : resource,
          name: "",
          description: "",
          tags: {
            connectOrCreate: [],
            disconnect: [],
          },
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().min(1).required(),
          path: Yup.string().min(1).required(),
        })}
        onSubmit={(data) => {
          if (loading) return;
          createResource({
            variables: {
              input: {
                name: data.name,
                path: data.path,
                type: data.type,
                workspaceId: workspaceId || "",
                parentId: folderId || "",
                description: data.description,
                tags: data.tags.connectOrCreate.map((tag) => tag),
              },
            },
          });
          setResource("");
        }}
      >
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            <Box
              sx={{
                padding: "0 24px",
              }}
            >
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Name</FormLabel>
                <CustomTextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name}
                  touched={touched.name}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Description</FormLabel>
                <CustomTextField
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.description}
                  touched={touched.description}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>URL</FormLabel>
                <CustomTextField
                  name="path"
                  onBlur={handleBlur}
                  value={values.path}
                  onChange={handleChange}
                  error={
                    (touched.path &&
                      !validate(values.path, values.type) &&
                      "Link type is invalid") ||
                    ""
                  }
                  touched={touched.path}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                <FormLabel>Tags</FormLabel>
                <Box>
                  <TextField
                    hiddenLabel
                    fullWidth
                    placeholder="Add tags here"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setFieldValue("tags.connectOrCreate", [
                          ...(values.tags?.connectOrCreate || []),
                          tag,
                        ]);
                        setTag("");
                      }
                    }}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                  <Box display={"flex"} pt={"1.125rem"}>
                    {values.tags?.connectOrCreate.map((tag: string) => (
                      <Tag
                        label={tag}
                        dismissable={true}
                        mr={2}
                        onClickDismiss={() => {
                          setFieldValue("tags.connectOrCreate", [
                            ...(values.tags?.connectOrCreate.filter(
                              (aTag: any) => tag !== aTag
                            ) || []),
                          ]);
                          setFieldValue("tags.disconnect", [
                            ...(values.tags?.disconnect || []),
                            tag,
                          ]);
                        }}
                      ></Tag>
                    ))}
                  </Box>
                </Box>
              </Box>
              {/* <Box>
                <FormLabel>Type</FormLabel>
                <Select
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  sx={{
                    width: "100%",

                    ".MuiSelect-select": {
                      height: "36px",
                      fontSize: "14px",
                      lineHeight: "18px",
                      padding: "8px 12px",
                      boxSizing: "border-box",
                    },
                  }}
                >
                  {Object.values(ResourceType).map((resource) => (
                    <MenuItem value={resource}>
                      {resourceNames[resource]}
                    </MenuItem>
                  ))}
                </Select>
              </Box> */}
            </Box>
            <Box
              sx={{
                boxShadow: `0 -3px 5px ${palette.gray.main}`,
                padding: "24px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <ButtonDefault type="button" onClick={() => setResource("")}>
                Cancel
              </ButtonDefault>
              <ButtonPrimary
                type="submit"
                onClick={() => handleSubmit()}
                disabled={
                  !dirty || !validate(values.path, values.type) || loading
                }
              >
                {submitButtonText}
              </ButtonPrimary>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};
