import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const IngestionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17C4.53976 17 4.16667 17.3731 4.16667 17.8333C4.16667 18.2935 4.53976 18.6666 5 18.6666C5.46024 18.6666 5.83333 18.2935 5.83333 17.8333C5.83333 17.3731 5.46024 17 5 17ZM2.5 17.8333C2.5 16.4526 3.61929 15.3333 5 15.3333C6.38071 15.3333 7.5 16.4526 7.5 17.8333C7.5 19.214 6.38071 20.3333 5 20.3333C3.61929 20.3333 2.5 19.214 2.5 17.8333Z"
        fill="currentColor"
        fillOpacity="0.54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5.33329C14.5398 5.33329 14.1667 5.70639 14.1667 6.16663C14.1667 6.62686 14.5398 6.99996 15 6.99996C15.4602 6.99996 15.8333 6.62686 15.8333 6.16663C15.8333 5.70639 15.4602 5.33329 15 5.33329ZM12.5 6.16663C12.5 4.78591 13.6193 3.66663 15 3.66663C16.3807 3.66663 17.5 4.78591 17.5 6.16663C17.5 7.54734 16.3807 8.66663 15 8.66663C13.6193 8.66663 12.5 7.54734 12.5 6.16663Z"
        fill="currentColor"
        fillOpacity="0.54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08333 6.99996C6.5308 6.99996 6.0009 7.21945 5.61019 7.61015C5.21949 8.00085 5 8.53076 5 9.08329C5 9.63583 5.21949 10.1657 5.61019 10.5564C6.0009 10.9471 6.5308 11.1666 7.08333 11.1666H13.75C14.7446 11.1666 15.6984 11.5617 16.4016 12.265C17.1049 12.9682 17.5 13.9221 17.5 14.9166C17.5 15.9112 17.1049 16.865 16.4016 17.5683C15.6984 18.2715 14.7446 18.6666 13.75 18.6666H10C9.53976 18.6666 9.16667 18.2935 9.16667 17.8333C9.16667 17.3731 9.53976 17 10 17H13.75C14.3025 17 14.8324 16.7805 15.2231 16.3898C15.6138 15.9991 15.8333 15.4692 15.8333 14.9166C15.8333 14.3641 15.6138 13.8342 15.2231 13.4435C14.8324 13.0528 14.3025 12.8333 13.75 12.8333H7.08333C6.08877 12.8333 5.13494 12.4382 4.43168 11.7349C3.72842 11.0317 3.33333 10.0779 3.33333 9.08329C3.33333 8.08873 3.72842 7.1349 4.43168 6.43164C5.13494 5.72838 6.08877 5.33329 7.08333 5.33329H10C10.4602 5.33329 10.8333 5.70639 10.8333 6.16663C10.8333 6.62686 10.4602 6.99996 10 6.99996H7.08333Z"
        fill="currentColor"
        fillOpacity="0.54"
      />
    </SvgIcon>
  );
};
