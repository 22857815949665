import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TableViewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon height="18" width="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V5C2 5.55228 2.44772 6 3 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2H3ZM0 3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3V5C18 6.65685 16.6569 8 15 8H3C1.34315 8 0 6.65685 0 5V3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C2.44772 12 2 12.4477 2 13V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V13C16 12.4477 15.5523 12 15 12H3ZM0 13C0 11.3431 1.34315 10 3 10H15C16.6569 10 18 11.3431 18 13V15C18 16.6569 16.6569 18 15 18H3C1.34315 18 0 16.6569 0 15V13Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
