import axios from "axios";

// Set up a base URL for your API
const baseURL = process.env.REACT_APP_BB_ASSISTANT_URL; // Replace with your actual API base URL

// Create an Axios instance with a base URL
const bBaseApi = axios.create({
  baseURL: baseURL,
});

const workspaceId = window.location.pathname
  ?.split("workspace/")[1]
  ?.split("/")[0];

bBaseApi.interceptors.request.use(
  function (config: any) {
    config.headers = {
      ...config.headers, // Keep existing headers
      "Content-Type": config.headers["Content-Type"] || "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const data =
      typeof config.data === "string" ? JSON.parse(config.data) : config.data;

    if (config.method === "get") {
      // Add your custom parameters here
      config.params = {
        ...config.params,
        workspaceId: workspaceId,
      };
    } else if (config.headers["Content-Type"] === "multipart/form-data") {
      data.append("workspaceId", workspaceId);
    } else {
      // Add params to the body for non-GET requests
      config.data = {
        ...data,
        workspaceId: workspaceId,
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

bBaseApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default bBaseApi;
