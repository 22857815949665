import { Box, Tooltip, useTheme } from "@mui/material";
import LastPageIcon from "@mui/icons-material/LastPage";

type Props = { collapse: boolean; toggleCollapse: () => void };

const CollapseButton = ({ collapse, toggleCollapse }: Props) => {
  const { palette } = useTheme();
  return (
    <Box sx={{ paddingLeft: "10px" }}>
      <Tooltip
        title={collapse ? "Open sidebar" : "Close sidebar"}
        placement="right"
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: "7px",
            alignItems: "center",
            backgroundColor: palette.gray.light,
            width: "fit-content",
            height: "fit-content",
            "&:hover": {
              backgroundColor: palette.primary.dark,
            },
            svg: {
              "&:hover": {
                color: palette.common.white,
              },
            },
          }}
        >
          <LastPageIcon
            sx={{
              cursor: "pointer",
              fontSize: "1.7rem",
              transform: collapse ? "rotate(360deg)" : "rotate(180deg)",
              transition: "transform 500ms",
            }}
            onClick={toggleCollapse}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CollapseButton;
