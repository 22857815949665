import { Box, styled } from "@mui/material";

export const SidebarContainer = styled(Box)(() => ({
  width: "43.25rem",
  padding: "1rem",
  paddingTop: "1rem",
  boxSizing: "border-box",
  transition: "width .5s ease-in-out",
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.outline}`,
  backgroundColor: theme.palette.custom.light,
  display: "flex",
  flexDirection: "column",
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: "0.75rem 1rem",
  button: {
    padding: "5px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grayAccent.light,
    minWidth: "inherit",
    margin: "0 5px",
    position: "relative",
    zIndex: "10",

    svg: {
      width: "16px",
      height: "16px",

      "&.pause": {
        width: "10px",
        height: "12px",
      },
    },

    "&:hover, &.active, &:active": {
      color: theme.palette.primary.main,
    },

    "&:disabled": {
      opacity: "0.5",
    },
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  backgroundColor: theme.palette.common.white,
}));

export const StateParentContainer = styled(Box)(({}) => ({
  overflow: "auto",
  flex: "1 1 0",
  display: "flex",
}));

export const StateContainer = styled(Box)(({}) => ({
  width: "100%",
  marginTop: "1rem",
  boxSizing: "border-box",
  paddingInline: "1.5rem",
  paddingBottom: "1.5rem",
}));

export const OutputContainer = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  paddingBottom: "1rem",
}));

export const OutputWrapper = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  padding: ".5rem",
  boxSizing: "border-box",
  borderRadius: ".7rem",
  width: "100%",
  height: "50vh",
  backgroundColor: "white",
}));

export const TitleContainer = styled(Box)(({}) => ({
  borderBottom: "1px solid #e0e0e0",
  paddingBottom: ".5rem",
}));

export const OutputItemContainer = styled(Box)(({}) => ({
  minHeight: "20rem",
  height: "100%",
  overflowY: "auto",
}));

export const FooterContainer = styled(Box)(({}) => ({
  padding: "0.5rem 1.75rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const FooterBtnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  height: "2.25rem",
  width: "2.25rem",
  border: `1px solid ${theme.palette.primary.darkest}`,
  borderRadius: "3px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));
