import Refresh from "@mui/icons-material/Refresh";
import { Drawer, Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import { Button } from "../Button/Button";
import { CloseIcon } from "../Icons/CloseIcon";
import { Typography } from "../Typography/Typography";
import NotificationItem from "./NotificationItem";
import { palette } from "@mui/system";
import _ from "lodash";

interface NotificationProps {
  open?: boolean;
  onClose?: () => void;
}

function Notification({ open, onClose }: NotificationProps) {
  const unreadNotifications = [
    {
      title: "Sharon Agathon",
      action: "replied",
      project: "Regional Dual Enrollment Outcome",
      workspace: "Post Secondary Outcome",
    },
    {
      title: "Sharon Agathon",
      action: "replied",
      project: "Regional Dual Enrollment Outcome",
      workspace: "Post Secondary Outcome",
    },
  ];

  const groupedNotifications = [
    {
      title: "Today",
      items: unreadNotifications,
    },
    {
      title: "Yesterday",
      items: unreadNotifications,
    },
  ];
  const { palette } = useTheme();
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width="450px" padding={6}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Typography fontWeight="bold" variant="h4">
            Notifications
          </Typography>
          <IconButton size="small" onClick={() => onClose?.()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          mt={1}
          padding={4}
          bgcolor={palette.settings.lighter}
          borderRadius={"6px"}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle3">
              {unreadNotifications.length} unread
            </Typography>
            <Typography variant="subtitle3">Mark all as read</Typography>
          </Box>

          {_.map(unreadNotifications, (item, index) => (
            <Box key={index} mt={3}>
              <NotificationItem />
            </Box>
          ))}
        </Box>

        <Box display="flex" justifyContent="space-between" mt={7}>
          <Typography variant="subtitle2">All notifications (7)</Typography>
          <Button color="grayAccent" startIcon={<Refresh />}>
            Clear all
          </Button>
        </Box>

        {_.map(groupedNotifications, (a, index) => (
          <Box key={index} mt={2} mb={5}>
            <Typography variant="body3" fontWeight="bold">
              {a.title}
            </Typography>
            <Box>
              {_.map(a?.items, (item, subIndex) => (
                <Box
                  key={`${item.title}-${subIndex}`}
                  mt={3}
                  border={`1px solid ${palette.action.disabledBackground}`}
                  borderRadius={1}
                >
                  <NotificationItem />
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Drawer>
  );
}

export default Notification;
