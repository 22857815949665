import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetCurrentUserInfoDocument } from "../../../generated";
import { useWorkspaceId } from "../../../hooks/useWorkspace";
import { ChatBotWidget } from "../components/ChatBotWidget";
import ChatInput from "../components/ChatInput";
import DataNav from "../components/DataNav";
import InformationSection from "../components/InformationSection";
import {
  StyledBackgroundContainer,
  StyledContainer,
  StyledHeroContainer,
  StyledHeroContentContainer,
} from "./styles";

type Props = {};

const HomePage = (props: Props) => {
  const { workspaceId } = useWorkspaceId();
  const navigate = useNavigate();
  const { data } = useQuery(GetCurrentUserInfoDocument);
  const firstName = data?.currentUser.firstName;

  const submitToBrightbotChat = (text: string) => {
    if (!text) return;
    navigate(`/workspace/${workspaceId}/brightbot`, {
      state: { brightbotText: text },
    });
  };
  return (
    <StyledContainer>
      <StyledHeroContainer>
        <StyledBackgroundContainer>
          {/* <DataNav submitToBrightbotChat={submitToBrightbotChat} /> */}
          <StyledHeroContentContainer>
            <Typography variant="h4" textAlign="center" mb={5}>
              Hi{" "}
              <span style={{ textTransform: "capitalize" }}>{firstName}</span>,
              What would you like to do with your data?
            </Typography>
            <ChatInput submitToBrightbotChat={submitToBrightbotChat} />
            <Grid container spacing={4} mt={3} mb={8}>
              <Grid item xs={6}>
                <ChatBotWidget
                  title="Quick insights from my data"
                  description="Use the most recently updated data asset and tell me something new"
                  onClick={() =>
                    submitToBrightbotChat(
                      "Use the most recently updated data asset and tell me something new"
                    )
                  }
                ></ChatBotWidget>
              </Grid>

              <Grid item xs={6}>
                <ChatBotWidget
                  title="Extract knowledge from my documents"
                  description="Take the unstructured text from this document and extract business terms"
                  onClick={() =>
                    submitToBrightbotChat(
                      "Take the unstructured text from this document and extract business terms"
                    )
                  }
                ></ChatBotWidget>
              </Grid>
              <Grid item xs={6}>
                <ChatBotWidget
                  title="Help me build a data strategy"
                  description="Given the goals of the workspace, suggest steps we can take to improve"
                  onClick={() =>
                    submitToBrightbotChat(
                      "Given the goals of the workspace, suggest steps we can take to improve"
                    )
                  }
                ></ChatBotWidget>
              </Grid>
              <Grid item xs={6}>
                <ChatBotWidget
                  title="Create new project"
                  description="Help me create a new data project by asking me what I’m looking to do"
                  onClick={() =>
                    submitToBrightbotChat(
                      "Help me create a new data project by asking me what I’m looking to do"
                    )
                  }
                ></ChatBotWidget>
              </Grid>
            </Grid>
          </StyledHeroContentContainer>
        </StyledBackgroundContainer>
      </StyledHeroContainer>

      {/* <InformationSection /> */}
    </StyledContainer>
  );
};

export default HomePage;
