import { useTheme } from "@mui/material";
import {
  ChatBotWidgetDescription,
  ChatBotWidgetHeading,
  ChatBotWidgetWrapper,
} from "./style";
import { LinesEllipsisTypography } from "../../../../common/LinesEllipsisTypography/LinesEllipsisTypography";

export const ChatBotWidget = ({ title, description, onClick }: any) => {
  const { palette } = useTheme();

  return (
    <ChatBotWidgetWrapper onClick={onClick}>
      {title && (
        <ChatBotWidgetHeading variant="h2">{title}</ChatBotWidgetHeading>
      )}
      {description && (
        <ChatBotWidgetDescription variant="body1">
          <LinesEllipsisTypography
            color={palette.gray.dark}
            variant="body3"
            text={description}
            maxLines={2}
          />
        </ChatBotWidgetDescription>
      )}
    </ChatBotWidgetWrapper>
  );
};
