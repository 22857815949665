import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrightBotHomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.51227 7.85133C8.47997 7.7408 8.44967 7.65395 8.41299 7.55842C8.16153 6.92321 7.82087 6.32627 7.40107 5.78523C6.68913 4.85064 5.79684 4.06531 4.77596 3.47479C4.53787 3.34528 4.29023 3.23378 4.03516 3.14123C4.0212 2.96377 3.94814 2.79591 3.82742 2.664C3.70671 2.53209 3.54518 2.44358 3.36818 2.41237C3.19118 2.38116 3.00872 2.40901 2.84942 2.49155C2.69013 2.57408 2.56302 2.70664 2.48804 2.86842C2.41305 3.0302 2.39444 3.21205 2.43511 3.38543C2.47578 3.55882 2.57344 3.71393 2.71276 3.82644C2.85209 3.93894 3.02519 4.00247 3.20492 4.00705C3.38465 4.01164 3.56083 3.95702 3.70583 3.85177C4.28156 4.00217 4.84255 4.34363 5.32459 4.6989C6.47407 5.53852 7.47283 6.66196 7.97042 8.00014C7.97502 8.01559 7.98089 8.03064 7.98797 8.04514C8.1634 8.01093 8.34122 7.98462 8.52144 7.9662C8.52657 7.92769 8.52345 7.88855 8.51227 7.85133Z"
        fill="#05524E"
      />
      <path
        d="M16.693 7.85154C16.7253 7.74101 16.7556 7.65417 16.7922 7.55864C17.0437 6.92343 17.3844 6.32649 17.8042 5.78545C18.5162 4.85082 19.4087 4.06548 20.4297 3.47501C20.6678 3.34549 20.9154 3.23399 21.1705 3.14145C21.1844 2.96398 21.2575 2.79613 21.3782 2.66422C21.4989 2.5323 21.6605 2.4438 21.8375 2.41259C22.0145 2.38137 22.1969 2.40922 22.3562 2.49176C22.5155 2.5743 22.6426 2.70685 22.7176 2.86863C22.7926 3.03041 22.8112 3.21226 22.7705 3.38565C22.7299 3.55903 22.6322 3.71415 22.4929 3.82665C22.3536 3.93915 22.1804 4.00268 22.0007 4.00727C21.821 4.01185 21.6448 3.95724 21.4998 3.85199C20.9245 4.0016 20.3631 4.34423 19.8811 4.6995C18.7316 5.5411 17.7348 6.66257 17.2352 8.00075C17.2306 8.0162 17.2248 8.03125 17.2177 8.04575C17.0422 8.01154 16.8644 7.98523 16.6842 7.9668C16.6789 7.9282 16.6819 7.88892 16.693 7.85154Z"
        fill="#05524E"
      />
      <path
        d="M9.24429 7.16969L6.86639 7.58481C6.65972 7.62089 6.52173 7.816 6.55817 8.02061L6.6209 8.37281C6.65734 8.57742 6.85441 8.71404 7.06107 8.67797L9.43898 8.26285C9.64564 8.22677 9.78363 8.03165 9.74719 7.82705L9.68447 7.47484C9.64803 7.27023 9.45095 7.13361 9.24429 7.16969Z"
        fill="#05524E"
      />
      <path
        d="M18.381 7.58214L16.0031 7.16702C15.7964 7.13094 15.5994 7.26756 15.5629 7.47217L15.5002 7.82438C15.4638 8.02899 15.6017 8.2241 15.8084 8.26018L18.1863 8.6753C18.393 8.71138 18.5901 8.57475 18.6265 8.37015L18.6892 8.01794C18.7257 7.81333 18.5877 7.61822 18.381 7.58214Z"
        fill="#05524E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0817 7.90318H15.1481C19.0345 7.90318 22.185 11.0223 22.185 14.87C22.185 18.7177 19.0345 21.8369 15.1481 21.8369H10.0817C6.19542 21.8369 3.04492 18.7177 3.04492 14.87C3.04492 11.0223 6.19542 7.90318 10.0817 7.90318ZM10.0885 9.68677H15.1545C18.046 9.68677 20.39 12.0074 20.39 14.87C20.39 17.7326 18.046 20.0532 15.1545 20.0532H10.0885C7.19704 20.0532 4.85304 17.7326 4.85304 14.87C4.85304 12.0074 7.19704 9.68677 10.0885 9.68677Z"
        fill="#05524E"
      />
      <path
        d="M16.8272 15.2626C16.8272 15.2626 15.9265 14.3705 15.1383 14.9279C14.35 15.4853 14.2376 16.0426 14.0127 15.9313C13.7878 15.82 13.8999 13.6532 15.4198 13.3434C16.9396 13.0335 17.6147 15.597 16.8272 15.2626Z"
        fill="#05524E"
      />
      <path
        d="M8.40543 15.2626C8.40543 15.2626 9.30611 14.3705 10.0944 14.9279C10.8826 15.4853 10.995 16.0426 11.2203 15.9313C11.4456 15.82 11.3328 13.6532 9.81287 13.3434C8.29299 13.0335 7.61798 15.597 8.40543 15.2626Z"
        fill="#05524E"
      />
      <path
        d="M12.6507 18.4483C11.2636 18.4483 10.2732 17.5861 10.2584 17.5731C10.1918 17.5138 10.1517 17.4307 10.147 17.3421C10.1422 17.2536 10.1732 17.1668 10.2331 17.1008C10.293 17.0348 10.3769 16.9951 10.4664 16.9904C10.5559 16.9857 10.6435 17.0164 10.7102 17.0757C10.7863 17.1428 12.5343 18.6413 14.5378 17.0631C14.6079 17.0079 14.6973 16.9825 14.7863 16.9925C14.8753 17.0025 14.9567 17.0472 15.0124 17.1166C15.0682 17.186 15.0939 17.2745 15.0837 17.3626C15.0736 17.4508 15.0285 17.5313 14.9584 17.5865C14.1387 18.2319 13.3452 18.4483 12.6507 18.4483Z"
        fill="#05524E"
      />
    </SvgIcon>
  );
};
