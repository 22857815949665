import { ConfigList } from "./ConfigList";
import { Schemas } from "../hooks/useSchemas";
import TypingBox from "./TypingBox";
import { Config } from "./Config";
import {
  ConfigListProps,
  Config as ConfigInterface,
} from "../hooks/useConfigList";
import { cn } from "../utils/cn";
import { MessageWithFiles } from "../utils/formTypes";
import { useNavigate, useParams } from "react-router-dom";
import { useThreadAndAssistant } from "../hooks/useThreadAndAssistant";
import {
  TypingArea,
  PageContentHolder,
  NewChatRightArea,
  NewChatRightAreaSidebar,
  NewChatRightAreaContent,
  NewChatRightAreaContentHolder,
} from "./style";

interface NewChatProps extends ConfigListProps {
  configSchema: Schemas["configSchema"];
  configDefaults: Schemas["configDefaults"];
  enterConfig: (id: string | null) => void;
  deleteConfig: (id: string) => Promise<void>;
  startChat: (
    config: ConfigInterface,
    message: MessageWithFiles
  ) => Promise<void>;
}

export function NewChat(props: NewChatProps) {
  const navigator = useNavigate();
  const { assistantId, workspaceId } = useParams();

  const { assistantConfig, isLoading } = useThreadAndAssistant();

  if (isLoading) return <div>Loading...</div>;
  if (!assistantConfig)
    return <div>Could not find assistant with given id.</div>;

  return (
    <PageContentHolder className={assistantConfig ? "" : "pb-6"}>
      <NewChatRightArea>
        {/* <NewChatRightAreaSidebar>
          <ConfigList
            configs={props.configs}
            currentConfig={assistantConfig}
            enterConfig={(id) =>
              navigator(`/workspace/${workspaceId}/brightbot-studio/assistant/${id}`)
            }
            deleteConfig={props.deleteConfig}
          />
        </NewChatRightAreaSidebar> */}

        <NewChatRightAreaContent>
          <NewChatRightAreaContentHolder>
            <Config
              key={assistantId}
              config={assistantConfig}
              configSchema={props.configSchema}
              configDefaults={props.configDefaults}
              saveConfig={props.saveConfig}
              enterConfig={props.enterConfig}
            />
          </NewChatRightAreaContentHolder>
        </NewChatRightAreaContent>
      </NewChatRightArea>
      <TypingArea>
        <TypingBox
          onSubmit={async (msg: MessageWithFiles) => {
            if (assistantConfig) {
              await props.startChat(assistantConfig, msg);
            }
          }}
          currentConfig={assistantConfig}
          currentChat={null}
        />
      </TypingArea>
    </PageContentHolder>
  );
}
