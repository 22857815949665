import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ToolCall } from "../types";
import { str } from "../utils/str";
import { cn } from "../utils/cn";
import {
  ChatUseSpan,
  ChatRetrieverSpan,
  ChatFileNameHolder,
  ChatFileTableHolder,
  ChatFileTable,
  ChatFileTableTd,
  ResultsFromName,
  ResultsDropLink,
} from "./style";

export function ToolRequest(
  props: ToolCall & {
    open?: boolean;
    setOpen?: (open: boolean) => void;
  }
) {
  return (
    <>
      <ChatUseSpan>Use</ChatUseSpan>
      {props.name && <ChatRetrieverSpan>{props.name}</ChatRetrieverSpan>}
      {props.args && (
        <ChatFileNameHolder>
          <ChatFileTableHolder>
            <ChatFileTable>
              <tbody>
                {Object.entries(props.args).map(([key, value], i) => (
                  <tr key={i}>
                    <ChatFileTableTd
                      className={cn(i === 0 ? "" : "border-transparent")}
                    >
                      <div>{key}</div>
                    </ChatFileTableTd>
                    <ChatFileTableTd
                      className={cn(i === 0 ? "" : "border-top")}
                    >
                      {str(value)}
                    </ChatFileTableTd>
                  </tr>
                ))}
              </tbody>
            </ChatFileTable>
          </ChatFileTableHolder>
        </ChatFileNameHolder>
      )}
    </>
  );
}

export function ToolResponse(props: {
  name?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}) {
  return (
    <div>
      <ChatUseSpan>Results from</ChatUseSpan>
      {props.name && <ResultsFromName>{props.name}</ResultsFromName>}
      {props.setOpen && (
        <ResultsDropLink
          className={cn(props.open && "mb-2")}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.setOpen?.(!props.open);
          }}
        >
          <ChevronDownIcon className={cn(props.open ? "active" : "")} />
        </ResultsDropLink>
      )}
    </div>
  );
}
