import { styled, Box, IconButton, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../theme/theme";

export const StyledContainer = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  transition: "all ease-in-out 0.25s",
  flexShrink: 0,
}));

export const SidenavHolder = styled(Box)`
  position: fixed;
  top: 48px;
  height: calc(100% - 48px);
  z-index: 99;
  border-right: 1px solid ${brighthiveTheme.palette.custom.contrastText};

  width: inherit;
  overflow: auto;
  @media (max-width: 992px) {
    width: 180px;
  }
  .linkholder {
    display: flex;
    align-items: center;
    position: relative;

    .action-button {
      padding: 0;
      min-width: 10px;
      margin-left: 5px;
      &.delete {
        svg {
          color: ${brighthiveTheme.palette.error.main};
        }
      }
      svg {
        font-size: 18px;
      }
      span {
        display: none;
      }
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        text-decoration: none;
        background: ${brighthiveTheme.palette.primary.light};
      }
      button {
        padding: 10px;
        overflow: hidden;
        width: calc(100% - 30px);
        display: block;

        &:hover {
          background: none;
          box-shadow: none;
        }
      }
      svg {
        font-size: 20px !important;
        @media (max-width: 992px) {
          display: none;
        }
      }

      .sidebutton-text {
        display: block;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }

  @keyframes rollout {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: none;
    }
  }

  .roll-out {
    animation: rollout 0.4s;
  }
`;

export const DropdownHolder = styled(Box)`
  position: absolute;
  right: 3px;
  top: 4px;

  button {
    background: transparent;
    color: ${brighthiveTheme.palette.gray.darkest};
    box-shadow: none;
    min-width: 20px;
    padding: 5px;
    height: 30px;

    &:hover {
      background: ${brighthiveTheme.palette.primary.darkest};
      color: ${brighthiveTheme.palette.common.white};
    }
  }
`;

export const LeftHeaderContainer = styled(Box)(() => ({
  padding: "0 1.5rem 0 0",
  display: "flex",
  alignItems: "center",
}));

export const SidebarIconContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  padding: "0.3rem .4rem",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.outline,
  marginLeft: ".5rem",
  marginRight: "1rem",
}));

export const BrightbotIconContainer = styled(Box)(() => ({
  svg: {
    width: "40px",
    height: "40px",
    marginRight: ".5rem",
  },
}));

export const RightHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: " 100%",
  paddingRight: "5rem",
  justifyContent: "flex-end",
}));

export const ContentContainer = styled(Box)(({}) => ({
  display: "flex",
  flex: "1",
  height: "100%",
  marginTop: "-1rem",
}));

export const ChatContainer = styled(Box)(({}) => ({
  display: "flex",
  flex: "1",
  minHeight: "300px",
}));
