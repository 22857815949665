import { Box, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { ButtonPrimary, StyledOutlinedInput } from "./style";
import { BrightBotHomeIcon } from "../../../../common/Icons/BrightBotHomeIcon";

type Props = { submitToBrightbotChat: (text: string) => void };

const ChatInput = ({ submitToBrightbotChat }: Props) => {
  const [value, setValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const submitValue = value.trim();
    submitToBrightbotChat(submitValue);
  };
  return (
    <Box>
      <StyledOutlinedInput
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder="What would you like me to help you with?"
        fullWidth
        endAdornment={
          <Box flex="none">
            <ButtonPrimary
              onClick={handleSubmit}
              startIcon={<BrightBotHomeIcon />}
            >
              Let's Go!
            </ButtonPrimary>
          </Box>
        }
      />
    </Box>
  );
};

export default ChatInput;
