import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownloadFileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V9.32149L11.5774 6.57741C11.9028 6.25197 12.4305 6.25197 12.7559 6.57741C13.0814 6.90285 13.0814 7.43049 12.7559 7.75592L8.58926 11.9226C8.26382 12.248 7.73618 12.248 7.41074 11.9226L3.24408 7.75592C2.91864 7.43049 2.91864 6.90285 3.24408 6.57741C3.56951 6.25197 4.09715 6.25197 4.42259 6.57741L7.16667 9.32149V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5ZM1.33333 11.3333C1.79357 11.3333 2.16667 11.7064 2.16667 12.1667V13.8333C2.16667 14.0543 2.25446 14.2663 2.41074 14.4226C2.56702 14.5789 2.77899 14.6667 3 14.6667H13C13.221 14.6667 13.433 14.5789 13.5893 14.4226C13.7455 14.2663 13.8333 14.0543 13.8333 13.8333V12.1667C13.8333 11.7064 14.2064 11.3333 14.6667 11.3333C15.1269 11.3333 15.5 11.7064 15.5 12.1667V13.8333C15.5 14.4964 15.2366 15.1323 14.7678 15.6011C14.2989 16.0699 13.663 16.3333 13 16.3333H3C2.33696 16.3333 1.70107 16.0699 1.23223 15.6011C0.763392 15.1323 0.5 14.4964 0.5 13.8333V12.1667C0.5 11.7064 0.873096 11.3333 1.33333 11.3333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
