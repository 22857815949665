import { styled, Box, Typography, Button } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";
import { Link } from "../Link/Link";

export const SideNavLink = styled(Link)`
  text-decoration: none;
  display: block;

  &.active {
    > button {
      background-color: ${brighthiveTheme.palette.primary.active};
      color: ${brighthiveTheme.palette.primary.dark};
    }
  }

  &.disabled & {
    pointer-events: none;
  }
`;

export const ButtonAdd = styled(Button)`
  width: calc(100% - 4px);
  margin: 4px 0 4px 4px;
  border-radius: 12px;
  min-width: 1px;
  gap: 12px;
  text-align: left;
  padding: 6px 8px;
  justify-content: flex-start;
  align-items: center;
  background-color: ${brighthiveTheme.palette.primary.active};
  color: ${brighthiveTheme.palette.primary.dark};

  > i {
    border-radius: 100%;
  }
`;

export const IconAddWrap = styled(`i`)`
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${brighthiveTheme.palette.primary.dark};
  color: ${brighthiveTheme.palette.common.white};
  line-height: 0;
  width: 20px;
  height: 20px;

  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
  }
`;

export const LinkButton = styled(Button)`
  justify-content: flex-start;
  width: 100%;
  border-radius: 6px;
  padding: 14px 10px;
  background-color: transparent;
  color: ${brighthiveTheme.palette.accent.contrastText};

  &:hover,
  .active > & {
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  .MuiSvgIcon-root {
    font-size: 29px !important;
  }

  > .MuiButton-startIcon {
    position: relative;
    transition: left 0.1s ease;
  }

  .inner-dropdown & {
    padding: 8px 8px 8px 24px;
    border-left: 2px solid ${brighthiveTheme.palette.action.disabledBackground};
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    line-height: 1.42;

    &:hover,
    .active > & {
      border-left-color: currentColor;
    }

    > .MuiButton-startIcon {
      display: none;
    }
  }

  .inner-dropdown .active > & {
    border-left-color: currentColor;
  }

  .InnerCollapsibleWrapper > a > & {
    justify-content: flex-start;
    width: 100%;
    border-radius: 6px;
    padding: 7px 8px;
    background-color: transparent;
    color: ${brighthiveTheme.palette.accent.contrastText};
    display: inline-flex;
    min-width: 1px;

    .sidebutton-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.428;
    }

    &:hover,
    &.active {
      background-color: ${brighthiveTheme.palette.primary.active};
      color: ${brighthiveTheme.palette.primary.dark};
    }

    > .MuiButton-startIcon svg {
      font-size: 22px !important;
    }

    > i {
      right: -4px;
    }
  }

  .InnerCollapsibleWrapper > a.active > & {
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  .cs-hidden-text {
    transition: opacity 0.1s ease;
  }

  .sidenav-collapsed & {
    pointer-events: none;
    padding: 5px;
    width: 36px;
    overflow: hidden;
    min-width: 1px;

    > .MuiButton-startIcon {
      margin-right: 0;
      left: 2px;
      position: relative;
    }

    .cs-hidden-text {
      white-space: nowrap;
      opacity: 0;
    }
  }
`;

export const LabelText = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
`;
