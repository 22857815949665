import { SvgIcon, SvgIconProps } from "@mui/material";

export const SideViewportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6213 2.73903C22.1839 3.30164 22.5 4.0647 22.5 4.86035V19.8604C22.5 20.656 22.1839 21.4191 21.6213 21.9817C21.0587 22.5443 20.2956 22.8604 19.5 22.8604H4.5C3.70435 22.8604 2.94129 22.5443 2.37868 21.9817C1.81607 21.4191 1.5 20.656 1.5 19.8604V4.86035C1.5 4.0647 1.81607 3.30164 2.37868 2.73903C2.94129 2.17642 3.70435 1.86035 4.5 1.86035H19.5C20.2956 1.86035 21.0587 2.17642 21.6213 2.73903ZM4.5 4.11035H15V4.11475H17.25V4.11035H19.5C19.6989 4.11035 19.8897 4.18937 20.0303 4.33002C20.171 4.47067 20.25 4.66144 20.25 4.86035V19.8604C20.25 20.0593 20.171 20.25 20.0303 20.3907C19.8897 20.5313 19.6989 20.6104 19.5 20.6104H18V20.6177H13.5V20.6104H4.5C4.30109 20.6104 4.11032 20.5313 3.96967 20.3907C3.82902 20.25 3.75 20.0593 3.75 19.8604V4.86035C3.75 4.66144 3.82902 4.47067 3.96967 4.33002C4.11032 4.18937 4.30109 4.11035 4.5 4.11035Z"
        fill="#3A4369"
      />
      <path
        d="M19.4238 13.0738C19.4238 11.7447 19.4238 11.0802 19.1931 10.5722C18.9901 10.1257 18.6662 9.76261 18.2679 9.53507C17.8148 9.27637 17.2221 9.27637 16.0365 9.27637H13.2843C12.0987 9.27637 11.506 9.27637 11.0529 9.53507C10.6546 9.76261 10.3307 10.1257 10.1277 10.5722C9.89697 11.0802 9.89697 11.7447 9.89697 13.0738V16.1592C9.89697 17.4883 9.89697 18.1529 10.1277 18.6608C10.3307 19.1074 10.6546 19.4704 11.0529 19.698C11.506 19.9567 12.0987 19.9567 13.2843 19.9567H16.0365C17.2221 19.9567 17.8148 19.9567 18.2679 19.698C18.6662 19.4704 18.9901 19.1074 19.1931 18.6608C19.4238 18.1529 19.4238 17.4883 19.4238 16.1592V13.0738Z"
        fill="#3A4369"
      />
    </SvgIcon>
  );
};
