import { useEffect, useRef, useState } from "react";
import { StreamStateProps } from "../hooks/useStreamState";
import { useChatMessages } from "../hooks/useChatMessages";
import TypingBox from "./TypingBox";
import { MessageViewer } from "./Message";
import {
  ArrowDownCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { MessageWithFiles } from "../utils/formTypes";
import { useParams } from "react-router-dom";
import { useThreadAndAssistant } from "../hooks/useThreadAndAssistant";
import { useMessageEditing } from "../hooks/useMessageEditing";
import { MessageEditor } from "./MessageEditor";
import { Message } from "../types";
import {
  TypingArea,
  PageContentHolder,
  ClickToContinue,
  LodingChat,
  EditTextArea,
  EditTextAreaText,
  ButtonSaving,
} from "./style";

interface ChatProps extends Pick<StreamStateProps, "stream" | "stopStream"> {
  startStream: (
    message: MessageWithFiles | null,
    thread_id: string,
    assistantType: string
  ) => Promise<void>;
}

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function CommitEdits(props: {
  editing: Record<string, Message>;
  commitEdits: () => Promise<void>;
}) {
  const [inflight, setInflight] = useState(false);
  return (
    <EditTextArea>
      <EditTextAreaText>
        {Object.keys(props.editing).length} message(s) edited.
      </EditTextAreaText>
      <ButtonSaving
        onClick={async () => {
          setInflight(true);
          await props.commitEdits();
          setInflight(false);
        }}
      >
        <CheckCircleIcon onMouseUp={props.commitEdits} />

        {inflight ? "Saving..." : "Save"}
      </ButtonSaving>
    </EditTextArea>
  );
}

export function Chat(props: ChatProps) {
  const { chatId } = useParams();
  const {
    messages,
    next,
    refreshMessages,
    isLoading: messageLoading,
  } = useChatMessages(chatId ?? null, props.stream, props.stopStream);
  const { currentChat, assistantConfig, isLoading } = useThreadAndAssistant();
  const { editing, recordEdits, commitEdits, abandonEdits } = useMessageEditing(
    chatId,
    refreshMessages
  );

  const prevMessages = usePrevious(messages);
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo({
      top: document.body.scrollHeight,
      behavior:
        prevMessages && prevMessages?.length === messages?.length
          ? "smooth"
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (messageLoading && messages.length === 0) return <div>Loading...</div>;
  if (!currentChat || !assistantConfig) return <div>No data.</div>;

  return (
    <PageContentHolder>
      {messages?.map((msg, i) =>
        editing[msg.id] ? (
          <MessageEditor
            key={msg.id}
            message={editing[msg.id]}
            onUpdate={recordEdits}
            abandonEdits={() => abandonEdits(msg)}
          />
        ) : (
          <MessageViewer
            {...msg}
            key={msg.id}
            runId={
              i === messages.length - 1 && props.stream?.status === "done"
                ? props.stream?.run_id
                : undefined
            }
            startEditing={() => recordEdits(msg)}
            alwaysShowControls={i === messages.length - 1}
          />
        )
      )}
      {(props.stream?.status === "inflight" || messages === null) && (
        <LodingChat>...</LodingChat>
      )}
      {props.stream?.status === "error" && (
        <ClickToContinue>
          An error has occurred. Please try again.
        </ClickToContinue>
      )}
      {next.length > 0 &&
        props.stream?.status !== "inflight" &&
        Object.keys(editing).length === 0 && (
          <ClickToContinue
            onClick={() =>
              props.startStream(
                null,
                currentChat.thread_id,
                assistantConfig.config.configurable?.type as string
              )
            }
          >
            <ArrowDownCircleIcon />
            Click to continue.
          </ClickToContinue>
        )}
      <TypingArea>
        {commitEdits && Object.keys(editing).length > 0 ? (
          <CommitEdits editing={editing} commitEdits={commitEdits} />
        ) : (
          <TypingBox
            onSubmit={(msg) =>
              props.startStream(
                msg,
                currentChat.thread_id,
                assistantConfig.config.configurable?.type as string
              )
            }
            onInterrupt={
              props.stream?.status === "inflight" ? props.stopStream : undefined
            }
            inflight={props.stream?.status === "inflight"}
            currentConfig={assistantConfig}
            currentChat={currentChat}
          />
        )}
      </TypingArea>
    </PageContentHolder>
  );
}
