import { memo, useState } from "react";
import { MessageDocument, Message as MessageType } from "../types";
import { str } from "../utils/str";
import { cn } from "../utils/cn";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { LangSmithActions } from "./LangSmithActions";
import { DocumentList } from "./Document";
import { omit } from "lodash";
import { StringViewer } from "./String";
import { ToolRequest, ToolResponse } from "./Tool";
import {
  MessageHolder,
  MessageHolderRow,
  MessageWriter,
  MessageWriterName,
  MessageContentArea,
  MessageContentDes,
  ThumbHolder,
} from "./style";
import { ThumbDown } from "@mui/icons-material";
function isDocumentContent(
  content: MessageType["content"]
): content is MessageDocument[] {
  return (
    Array.isArray(content) &&
    content.every((d) => typeof d === "object" && !!d && !!d.page_content)
  );
}

export function MessageContent(props: { content: MessageType["content"] }) {
  if (typeof props.content === "string") {
    if (!props.content.trim()) {
      return null;
    }
    return <StringViewer value={props.content} markdown />;
  } else if (isDocumentContent(props.content)) {
    return <DocumentList documents={props.content} />;
  } else if (
    Array.isArray(props.content) &&
    props.content.every(
      (it) => typeof it === "object" && !!it && typeof it.content === "string"
    )
  ) {
    return (
      <DocumentList
        markdown
        documents={props.content.map((it) => ({
          page_content: it.content,
          metadata: omit(it, "content"),
        }))}
      />
    );
  } else {
    let content = props.content;
    if (Array.isArray(content)) {
      content = content.filter((it) =>
        typeof it === "object" && !!it && "type" in it
          ? it.type !== "tool_use"
          : true
      );
    }
    if (
      Array.isArray(content)
        ? content.length === 0
        : Object.keys(content).length === 0
    ) {
      return null;
    }
    return <MessageContentDes>{str(content)} 1212</MessageContentDes>;
  }
}

export const MessageViewer = memo(function (
  props: MessageType & {
    runId?: string;
    startEditing?: () => void;
    alwaysShowControls?: boolean;
  }
) {
  const [open, setOpen] = useState(false);
  const contentIsDocuments =
    ["function", "tool"].includes(props.type) &&
    isDocumentContent(props.content);
  const showContent =
    ["function", "tool"].includes(props.type) && !contentIsDocuments
      ? open
      : true;
  return (
    <MessageHolder>
      <MessageHolderRow>
        <MessageWriter
          className={cn(["function", "tool"].includes(props.type) && "mt-1")}
        >
          <MessageWriterName>{props.type}</MessageWriterName>
          {props.startEditing && (
            <div
              className={cn(
                "edit-icon",
                props.alwaysShowControls ? "" : "opacity-0"
              )}
              onMouseUp={props.startEditing}
            >
              <EditPencilIcon />
            </div>
          )}
        </MessageWriter>
        <MessageContentArea>
          {["function", "tool"].includes(props.type) && (
            <ToolResponse
              name={props.name}
              open={open}
              setOpen={contentIsDocuments ? undefined : setOpen}
            />
          )}
          {props.tool_calls?.map((call) => (
            <ToolRequest key={call.id} {...call} />
          ))}
          {showContent && <MessageContent content={props.content} />}
        </MessageContentArea>
      </MessageHolderRow>
      {props.runId && (
        <ThumbHolder>
          <LangSmithActions runId={props.runId} />
        </ThumbHolder>
      )}
    </MessageHolder>
  );
});
