import { cn } from "../utils/cn";
import { MesssageEditArea, MesssageEdittextarea } from "./style";

export function StringEditor(props: {
  value?: string | null | undefined;
  placeholder?: string;
  className?: string;
  onChange?: (e: string) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
  cursorPointer?: boolean;
  disabled?: boolean;
  fullHeight?: boolean;
}) {
  return (
    <MesssageEditArea
      className={
        cn("", props.className) + (props.fullHeight ? "" : " overflow")
      }
    >
      <MesssageEdittextarea
        className="common-class"
        disabled={props.disabled}
        value={props.value ?? ""}
        rows={1}
        onChange={(e) => {
          const target = e.target as HTMLTextAreaElement;
          props.onChange?.(target.value);
        }}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus && !props.readOnly}
      />
      <div aria-hidden className={cn("common-class", "event")}>
        {props.value}{" "}
      </div>
    </MesssageEditArea>
  );
}
