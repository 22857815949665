import {
  SidebarSkeleton,
  SkeletonBar,
  SidebarSkeletonlist,
  SidebarSkeletonlistItem,
} from "./style";

export const SidebarLoader = () => (
  <SidebarSkeleton>
    <SkeletonBar className="mainlink"></SkeletonBar>
    <SkeletonBar className="mainlink"></SkeletonBar>
    <SkeletonBar className="mainlink last"></SkeletonBar>
    <SidebarSkeletonlist>
      <SidebarSkeletonlistItem>
        <SkeletonBar></SkeletonBar>
      </SidebarSkeletonlistItem>
      <SidebarSkeletonlistItem>
        <SkeletonBar></SkeletonBar>
      </SidebarSkeletonlistItem>
      <SidebarSkeletonlistItem>
        <SkeletonBar></SkeletonBar>
      </SidebarSkeletonlistItem>
      <SidebarSkeletonlistItem>
        <SkeletonBar></SkeletonBar>
      </SidebarSkeletonlistItem>
    </SidebarSkeletonlist>
  </SidebarSkeleton>
);

export default SidebarLoader;
