import { Box, useTheme } from "@mui/material";
import { WarehouseServiceProvider } from "../../../generated";
import { CustomTextArea } from "../../CustomTextArea/CustomTextArea";
import { CustomTextField } from "../../CustomTextField/CustomTextField";
import { CustomField } from "../Ingestions/CustomField";
import {
  FieldsHolder,
  FieldsSet,
  HeadTitle,
  Label,
  SubHeading,
} from "../style";

export const Fields = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
}: any) => {
  const { palette } = useTheme();

  return (
    <>
      <FieldsHolder>
        <HeadTitle>About</HeadTitle>
        <Box display="flex" flexDirection="column">
          <FieldsSet>
            <SubHeading className="add">Warehouse Service name</SubHeading>

            <CustomTextField
              name="name"
              onBlur={handleBlur}
              value={values?.name}
              onChange={handleChange}
              error={errors?.name}
              touched={touched?.name}
            />
          </FieldsSet>
          <FieldsSet>
            <SubHeading className="add">Warehouse description</SubHeading>

            <CustomTextArea
              name="description"
              onBlur={handleBlur}
              value={values?.description}
              onChange={handleChange}
              error={errors?.description}
              touched={touched?.description}
            />
          </FieldsSet>

          {values.provider === WarehouseServiceProvider.Snowflake && (
            <>
              <HeadTitle className="mt">Configurations</HeadTitle>
              <FieldsSet>
                <CustomField
                  id="accountId"
                  name={"accountId"}
                  onBlur={handleBlur}
                  value={values?.accountId}
                  handleChange={handleChange}
                  error={errors?.accountId}
                  touched={touched?.accountId}
                >
                  <Label htmlFor="accountId">
                    Account ID <span className="asterisk">*</span>
                  </Label>
                </CustomField>
              </FieldsSet>
              <FieldsSet>
                <CustomField
                  id="username"
                  name={"username"}
                  onBlur={handleBlur}
                  value={values?.username}
                  handleChange={handleChange}
                  error={errors?.username}
                  touched={touched?.username}
                >
                  <Label htmlFor="username">
                    Username <span className="asterisk">*</span>
                  </Label>
                </CustomField>
              </FieldsSet>
              <FieldsSet>
                <CustomField
                  id="password"
                  name={"password"}
                  onBlur={handleBlur}
                  value={values?.password}
                  handleChange={handleChange}
                  error={errors?.password}
                  touched={touched?.password}
                  type="password"
                >
                  <Label htmlFor="password">
                    Password <span className="asterisk">*</span>
                  </Label>
                </CustomField>
              </FieldsSet>
            </>
          )}
        </Box>
      </FieldsHolder>
    </>
  );
};
