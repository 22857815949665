import { Chat } from "../types";
import bBaseApi from "./bBaseApi";


export async function getThread(threadId: string): Promise<Chat | null> {
  try {
    const response = await bBaseApi(`/threads/${threadId}`);
    if (!response.data) {
      return null;
    }
    return (await response.data) as Chat;
  } catch (error) {
    console.error("Failed to fetch assistant:", error);
    return null;
  }
}
