import { SvgIcon, SvgIconProps } from "@mui/material";

export const ShareFooterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9987 1.3335C13.4714 1.3335 14.6654 2.5274 14.6654 4.00016C14.6654 5.47292 13.4714 6.66683 11.9987 6.66683C11.1876 6.66683 10.4611 6.30473 9.97205 5.73338L6.61153 7.46442C6.64683 7.63749 6.66536 7.81666 6.66536 8.00016C6.66536 8.18342 6.64688 8.36237 6.61167 8.53523L9.97205 10.267C10.4611 9.6956 11.1876 9.3335 11.9987 9.3335C13.4714 9.3335 14.6654 10.5274 14.6654 12.0002C14.6654 13.4729 13.4714 14.6668 11.9987 14.6668C10.526 14.6668 9.33203 13.4729 9.33203 12.0002C9.33203 11.8167 9.35057 11.6375 9.38587 11.4644L6.02536 9.73336C5.53627 10.3047 4.80977 10.6668 3.9987 10.6668C2.52594 10.6668 1.33203 9.4729 1.33203 8.00016C1.33203 6.5274 2.52594 5.3335 3.9987 5.3335C4.80977 5.3335 5.53627 5.69559 6.02536 6.26695L9.38587 4.53591C9.35057 4.36284 9.33203 4.18366 9.33203 4.00016C9.33203 2.5274 10.526 1.3335 11.9987 1.3335ZM11.9987 10.6668C11.4787 10.6668 11.0282 10.9645 10.8084 11.3988C10.8043 11.4127 10.7981 11.4258 10.7914 11.4388L10.7827 11.4524C10.7073 11.6195 10.6654 11.8049 10.6654 12.0002C10.6654 12.7366 11.2623 13.3335 11.9987 13.3335C12.7351 13.3335 13.332 12.7366 13.332 12.0002C13.332 11.2638 12.7351 10.6668 11.9987 10.6668ZM3.9987 6.66683C3.26232 6.66683 2.66536 7.26376 2.66536 8.00016C2.66536 8.73656 3.26232 9.3335 3.9987 9.3335C4.51872 9.3335 4.9692 9.03581 5.18903 8.60154C5.19311 8.5877 5.19935 8.57457 5.20605 8.56156L5.2147 8.54788C5.29008 8.38079 5.33203 8.19538 5.33203 8.00016C5.33203 7.80495 5.29008 7.61953 5.2147 7.45244C5.21111 7.44851 5.20856 7.44369 5.20605 7.43883L5.18903 7.39878C4.9692 6.96452 4.51872 6.66683 3.9987 6.66683ZM11.9987 2.66683C11.2623 2.66683 10.6654 3.26378 10.6654 4.00016C10.6654 4.19537 10.7073 4.38078 10.7827 4.54787C10.7863 4.55186 10.7889 4.55668 10.7914 4.56154L10.8084 4.60153C11.0282 5.0358 11.4787 5.3335 11.9987 5.3335C12.7351 5.3335 13.332 4.73654 13.332 4.00016C13.332 3.26378 12.7351 2.66683 11.9987 2.66683Z"
        fill="#05524E"
      />
    </SvgIcon>
  );
};
