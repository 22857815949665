import { useState, useEffect, useRef } from "react";
import { ChatBotWidget } from "../ChatBotWidget/ChatBotWidget";
import { ChatRecentBotWidget } from "../ChatRecentBotWidget/ChatRecentBotWidget";
import { ChatSentBotWidget } from "../ChatSentBotWidget/ChatSentBotWidget";
import { ChatRcvBotWidget } from "../ChatRcvBotWidget/ChatRcvBotWidget";
import { PaperPlaneIcon } from "../../common/Icons/PaperPlaneIcon";
import { AttachmentIcon } from "../../common/Icons/AttachmentIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetCurrentUserInfoDocument } from "../../generated";
import { useQuery } from "@apollo/client";
import { getAuthToken } from "../../AppApolloProvider";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { toast } from "react-toastify";
import { size } from "lodash";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-react";
import { SuggestionBox } from "../SuggestionBox";
import axios from "axios";
import {
  ChatBotWrapper,
  WidgetsWrapper,
  WidgetCol,
  MessagesWrapper,
  EditorWrapper,
  FormWrapper,
  FormField,
  ActionsWrapper,
  ButtonAttachment,
  ButtonSubmit,
} from "../style";
import chatApi from "../../api/chatApi";
import { Box, CircularProgress, useTheme } from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CodeProps } from "react-markdown/lib/ast-to-react";
import {
  EditorContainer,
  LoadingContainer,
  MainContainer,
  SubMainContainer,
} from "./style";

let controller: any;

enum userType {
  human = "human",
  ai = "ai",
}

export const ChatBot = ({ showSuggestion, threadData, setRefetch }: any) => {
  const navigate = useNavigate();
  const { workspaceId, threadId } = useParams();
  const [messages, setMessages] = useState<any>([]);
  const [streaming, setStream] = useState<any>();
  const [messageValue, setMessageValue] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const { palette } = useTheme();
  const { data } = useQuery(GetCurrentUserInfoDocument);

  const ref = useRef<HTMLButtonElement | null>(null);

  const [thread, setThread]: any = useState();
  const [assistant, setAssistant]: any = useState();

  const { pathname, state: locationState } = useLocation();
  const externalState = locationState as { brightbotText?: string };

  const [streamMessage, setStreamMessage] = useState("");

  const userName = `${data?.currentUser?.firstName.split(" ").join("-") || ""}`;
  const userId = data?.currentUser.id;

  const reactMarkdownComp = {
    code(props: React.PropsWithChildren<CodeProps>) {
      const { children, className, node, ...rest } = props;
      const match = /language-(\w+)/.exec(className || "");
      return match ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
          wrapLines={true}
          children={String(children).replace(/\n$/, "")}
          language={match[1]}
          style={monokai}
        />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      );
    },
  };

  const chatCreate = async (data: any, message: string) => {
    controller = new AbortController();
    const signal = controller?.signal;

    let id = threadId;
    if (!thread) id = await createSession(message);

    let newMessage = "";

    await fetchEventSource(
      `${process.env.REACT_APP_BASE_URL}/threads/${id}/runs/stream`,
      {
        signal: signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": process.env.REACT_APP_BRIGHTIBOT_API_KEY || "",
        },
        body: JSON.stringify({
          assistant_id: assistant.assistant_id,
          ...(messages.length === 0 && {
            metadata: { Title: message, Author: userName },
          }),
          input: {
            messages: [data[size(data) - 1]], // send only the latest message
          },
          user_info: userId,
          stream_mode: "events",
        }),
        openWhenHidden: true,
        async onopen(res) {
          if (res.status === 200) {
            setStream(true);
            setStreamMessage("");
          }
        },
        onmessage(msg) {
          if (msg.event === "events") {
            const dt = JSON.parse(msg.data);

            if (dt.event === "on_chat_model_stream") {
              const output = newMessage + dt?.data?.chunk?.content;

              setStreamMessage(output);
              newMessage = output;
            }
          } else if (msg.event === "end") {
            setMessages([
              ...data,
              {
                content: newMessage,
                additional_kwargs: {},
                response_metadata: {},
                type: "ai",
                example: false,
              },
            ]);
            setStream(false);
          }
        },
        onclose() {
          return;
        },
        onerror(err) {
          throw new Error(err);
        },
      }
    );
  };

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();

      if (!e.currentTarget.message.value) return;

      const data = e.currentTarget.message.value;

      if (!data) return;

      const dataMessage = [
        ...messages,
        {
          content: data,
          additional_kwargs: {},
          response_metadata: {},
          type: userType.human,
          name: userName,
          example: false,
        },
      ];
      setMessageValue("");
      setMessages(dataMessage);
      e?.currentTarget?.reset();

      await chatCreate(dataMessage, data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleWidgetSubmit = (text: string) => {
    setMessageValue(text);
    const timeout = setTimeout(() => {
      if (ref.current) {
        ref.current.click();
        setMessageValue("");
      } else {
        console.error("Ref is not initialized at handleWidgetSubmit");
      }
    }, 0);

    return timeout;
  };

  useEffect(() => {
    return () => {
      controller?.abort();
    };
  }, []);

  const createSession = async (message: string) => {
    try {
      const res = await chatApi.post("/threads", {
        metadata: {
          user_id: userId,
          workspace_id: workspaceId,
          Title: message,
          Author: userName,
        },
      });

      const id = res.data.thread_id;
      setThread(id);
      if (setRefetch) {
        setRefetch((prev: any) => !prev);
        navigate(`/workspace/${workspaceId}/brightbot/${id}`);
      }
      return id;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (threadId && thread !== threadId) {
      setThread(threadId);
    }
  }, [threadId, thread, pathname, workspaceId]);

  useEffect(() => {
    if (pathname === `/workspace/${workspaceId}/brightbot`) {
      setThread();
      setMessages([]);
    }
  }, [pathname, workspaceId]);

  useEffect(() => {
    chatApi
      .post("/assistants/search", { graph_id: "chat_agent" })
      .then((res) => {
        setAssistant(res.data[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (threadId && thread !== threadId) {
      // on going from one thread to another, clear the stream message and set stream animation chat to false
      setStream(false);
      setStreamMessage("");
      setLoading(true);
      setMessages([]);
      chatApi
        .get(`/threads/${threadId}/state`)
        .then((res) => setMessages(res?.data?.values?.messages || []))
        .finally(() => setLoading(false));
    }
  }, [threadId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (externalState?.brightbotText) {
      timeout = handleWidgetSubmit(externalState.brightbotText);
    }

    return () => clearTimeout(timeout);
  }, [externalState?.brightbotText, assistant]);

  return (
    <ChatBotWrapper className="ChatBot">
      <MainContainer>
        <SubMainContainer>
          {!loading ? (
            <>
              {size(messages) < 1 && (
                <WidgetsWrapper>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Draft a data strategy"
                      description="Look at the documents provided in the workspace and write a data strategy document for me."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Look at the documents provided in the workspace and write a data strategy document for me."
                        )
                      }
                    />
                  </WidgetCol>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Extract information for docs"
                      description="Take the document that I upload and extract key terms based on the category of teams that I specify."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Take the document that I upload and extract key terms based on the category of teams that I specify."
                        )
                      }
                    />
                  </WidgetCol>
                  <WidgetCol>
                    <ChatBotWidget
                      title="Quick insights from my data"
                      description="Look at my most recently used data asset and tell me some interesting and novel insights."
                      onClick={() =>
                        handleWidgetSubmit(
                          "Look at my most recently used data asset and tell me some interesting and novel insights."
                        )
                      }
                    />
                  </WidgetCol>
                </WidgetsWrapper>
              )}

              <MessagesWrapper>
                {messages.map((message: any) => (
                  <>
                    {message?.type === userType.human && (
                      <ChatSentBotWidget>
                        <ReactMarkdown
                          className="line-break"
                          children={message.content}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw] as any}
                        />
                      </ChatSentBotWidget>
                    )}
                    {message?.type === userType.ai && (
                      <ChatRcvBotWidget>
                        <ReactMarkdown
                          children={message.content}
                          //remarkPlugins={[remarkGfm]} removed because it was causing the markdown to not render properly
                          rehypePlugins={[rehypeRaw] as any}
                          components={reactMarkdownComp}
                        />
                      </ChatRcvBotWidget>
                    )}
                  </>
                ))}
                {streaming && (
                  <ChatRcvBotWidget>
                    <ReactMarkdown
                      children={streamMessage}
                      //remarkPlugins={[remarkGfm]} removed because it was causing the markdown to not render properly
                      rehypePlugins={[rehypeRaw] as any}
                      components={reactMarkdownComp}
                    />
                  </ChatRcvBotWidget>
                )}
                {showSuggestion && <SuggestionBox />}
              </MessagesWrapper>
            </>
          ) : (
            <LoadingContainer>
              <CircularProgress sx={{ color: palette.primary.darkest }} />
            </LoadingContainer>
          )}
        </SubMainContainer>
      </MainContainer>
      <EditorContainer>
        <EditorWrapper>
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <FormField
                id="chatId"
                placeholder="Ask something&hellip;"
                name="message"
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    e.preventDefault();
                    ref?.current?.click();
                    setMessageValue("");
                  }
                }}
                multiline
              />
              <ActionsWrapper>
                <ButtonAttachment>
                  <input type="file" id="attachement" name="attachement" />
                  <label htmlFor="attachement">
                    <AttachmentIcon />
                  </label>
                </ButtonAttachment>
                <ButtonSubmit
                  disabled={streaming || !assistant}
                  type="submit"
                  ref={ref}
                >
                  <PaperPlaneIcon />
                </ButtonSubmit>
              </ActionsWrapper>
            </FormWrapper>
          </form>
        </EditorWrapper>
      </EditorContainer>
    </ChatBotWrapper>
  );
};
