import {
  PlusIcon,
  EllipsisVerticalIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/24/outline";
import { useState, useEffect, useRef } from "react";

import { ChatListProps } from "../hooks/useChatList";
import { cn } from "../utils/cn";
import { useThreadAndAssistant } from "../hooks/useThreadAndAssistant";
import { ConfigListProps, useConfigList } from "../hooks/useConfigList";
import { SearchIcon } from "../../../common/Icons/SearchIcon";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  ButtonChatStart,
  ButtonHolder,
  SquareIcon,
  Text,
  TypographyHeading,
  YourChatList,
  YourChatListItem,
  ButtonChatItem,
  FirstNameBox,
  ButtonChatItemInfo,
  ButtonEllipsisOpener,
  DeleteDropDown,
  AccordionCustom,
  AccordionSummaryCustom,
  SearchListLi,
} from "./style";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";
import { Chat } from "../types";
import { ExpandMore } from "@mui/icons-material";

export function ChatList(props: {
  workspaceChat: ChatListProps["chats"];
  workspaceDeleteChat: (id: string) => void;
  createChat: (name: string, assistant_id: string) => Promise<Chat>;
  chats: ChatListProps["chats"];
  configs: ConfigListProps["configs"];
  deleteConfig: (id: string) => Promise<void>;
  enterChat: (id: string | null) => void;
  deleteChat: (id: string) => void;
  enterConfig: (id: string | null) => void;
}) {
  const { chatId, workspaceId, assistantId } = useParams();

  const { currentChat, assistantConfig } = useThreadAndAssistant();

  const navigate = useNavigate();

  // State for tracking which chat's menu is visible
  const [visibleMenu, setVisibleMenu] = useState<string | null>(null);

  // Event listener to close the menu when clicking outside of it
  useEffect(() => {
    const closeMenu = () => setVisibleMenu(null);
    window.addEventListener("click", closeMenu);
    return () => window.removeEventListener("click", closeMenu);
  }, []);

  const [deleteChat, setDeleteChat] = useState<any>();

  const [expanded, setExpanded] = useState<number>(0);

  useEffect(() => {
    if (chatId) {
      setExpanded(3);
    }
    const timeout = setTimeout(() => {
      document
        .getElementsByClassName("chat active")[0]
        ?.scrollIntoView({ block: "nearest" });
    }, 500); // Delay by .5 second

    // Cleanup timeout when the dependency changes or the component unmounts
    return () => clearTimeout(timeout);
  }, [chatId, props.chats]);

  const [searchChat, setSearchChat] = useState("");
  const [filterChat, setFilterChat] = useState<Chat[] | null>(null);

  const [searchConfig, setSearchConfig] = useState("");
  const [filterConfig, setFilterConfig] = useState<Chat[] | null>(null);

  const [searchWChat, setSearchWChat] = useState("");
  const [filterWChat, setFilterWChat] = useState<Chat[] | null>(null);

  const searchHandler = (
    chats: any,
    value: string,
    setFilter: Function,
    setSearch: Function
  ) => {
    setSearch(value);
    if (!chats) return;
    setFilter(chats?.filter((chat: any) => chat?.name.toLowerCase().includes(value.toLowerCase())));
  };

  const filterSelect = (index: number, chat: any) => {
    switch (index) {
      case 0:
        return searchWChat ? filterWChat : chat;
      case 1:
        return searchConfig ? filterConfig : chat;
    }
  };

  return (
    <>
      <ButtonHolder>
        <ButtonChatStart
          onClick={() => props.enterConfig(null)}
          className={cn(!assistantConfig ? "active" : "normal")}
        >
          <SquareIcon>
            <PlusIcon />
          </SquareIcon>
          <Text>New Bot</Text>
        </ButtonChatStart>
      </ButtonHolder>

      {[props.workspaceChat, props.configs].map((assistants, index) => (
        <Box key={`assistant-${index}`}>
          <AccordionCustom
            expanded={expanded === index + 1}
            onChange={() =>
              setExpanded((prev) => (prev === index + 1 ? 0 : index + 1))
            }
          >
            <AccordionSummaryCustom
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index}-content`}
              id={`"panel${index}-header"`}
            >
              <TypographyHeading>
                {index === 0 ? "Workspace Bots" : "Your Saved Bots"}
              </TypographyHeading>
            </AccordionSummaryCustom>
            <AccordionDetails>
              <YourChatList>
                <SearchListLi>
                  <SearchIcon />
                  <TextField
                    id="search"
                    className="chatsearch"
                    placeholder="Search"
                    value={index ? searchConfig : searchWChat}
                    onChange={(e) => {
                      index
                        ? searchHandler(
                            assistants,
                            e.target.value,
                            setFilterConfig,
                            setSearchConfig
                          )
                        : searchHandler(
                            assistants,
                            e.target.value,
                            setFilterWChat,
                            setSearchWChat
                          );
                    }}
                  />
                </SearchListLi>
                {filterSelect(index, assistants)?.map((chat: any) => (
                  <YourChatListItem
                    key={chat.assistant_id}
                    className={
                      "chat " +
                      cn(
                        chat.assistant_id === assistantId ? "active" : "normal"
                      )
                    }
                  >
                    <ButtonChatItem
                      onClick={() =>
                        navigate(
                          `/workspace/${workspaceId}/brightbot-studio/assistant/${chat.assistant_id}`
                        )
                      }
                    >
                      <FirstNameBox
                        className={cn(
                          chat.assistant_id === assistantId
                            ? "active"
                            : "normal"
                        )}
                      >
                        {chat.name?.[0] ?? " "}
                      </FirstNameBox>
                      <ButtonChatItemInfo>
                        <span>{chat.name}</span>
                        <span className="add">
                          {
                            props.configs?.find(
                              (config) =>
                                config.assistant_id === chat.assistant_id
                            )?.name
                          }
                        </span>
                      </ButtonChatItemInfo>
                    </ButtonChatItem>

                    {/* Ellipsis Button */}
                    {index === 1 && (
                      <>
                        <ButtonEllipsisOpener
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent triggering click for the chat item
                            setVisibleMenu(
                              visibleMenu === chat.assistant_id
                                ? null
                                : chat.assistant_id
                            );
                          }}
                        >
                          <EllipsisVerticalIcon />
                        </ButtonEllipsisOpener>
                        {/* Menu Dropdown */}
                        {visibleMenu === chat.assistant_id && (
                          <DeleteDropDown
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <Button
                              className="edit"
                              onClick={(event) => {
                                navigate(
                                  `/workspace/${workspaceId}/brightbot-studio/assistant/${chat.assistant_id}/edit`
                                );
                              }}
                            >
                              Edit
                              <EditPencilIcon />
                            </Button>
                            <Button
                              className="delete"
                              role="menuitem"
                              onClick={(event) => {
                                event.preventDefault();
                                setDeleteChat(chat);
                              }}
                            >
                              Delete
                              <DeleteIcon />
                            </Button>
                          </DeleteDropDown>
                        )}
                      </>
                    )}
                  </YourChatListItem>
                )) ?? (
                  <YourChatListItem className="loading">
                    Loading ...
                  </YourChatListItem>
                )}
              </YourChatList>
            </AccordionDetails>
          </AccordionCustom>
        </Box>
      ))}
      <AccordionCustom
        expanded={expanded === 3}
        onChange={() => setExpanded((prev) => (prev === 3 ? 0 : 3))}
        className="no-border"
      >
        <AccordionSummaryCustom
          expandIcon={<ExpandMore />}
          aria-controls={`panel${2}-content`}
          id={`"panel${2}-header"`}
        >
          <TypographyHeading>Your chats</TypographyHeading>
        </AccordionSummaryCustom>
        <AccordionDetails>
          <YourChatList className="no-border">
            <SearchListLi>
              <SearchIcon />
              <TextField
                id="search"
                placeholder="Search"
                variant="outlined"
                value={searchChat}
                onChange={(e) =>
                  searchHandler(
                    props.chats,
                    e.target.value,
                    setFilterChat,
                    setSearchChat
                  )
                }
              />
            </SearchListLi>
            {(searchChat ? filterChat : props.chats)?.map((chat) => (
              <YourChatListItem
                key={chat.thread_id}
                className={
                  "chat " +
                  cn(
                    chat.thread_id === currentChat?.thread_id
                      ? "active"
                      : "normal"
                  )
                }
              >
                <ButtonChatItem onClick={() => props.enterChat(chat.thread_id)}>
                  <FirstNameBox
                    className={cn(
                      chat.thread_id === currentChat?.thread_id
                        ? "active"
                        : "normal"
                    )}
                  >
                    {chat.name?.[0] ?? " "}
                  </FirstNameBox>
                  <ButtonChatItemInfo>
                    <span>{chat.name}</span>
                    <span className="add">
                      {
                        props.configs?.find(
                          (config) => config.assistant_id === chat.assistant_id
                        )?.name
                      }
                    </span>
                  </ButtonChatItemInfo>
                </ButtonChatItem>
                {/* Ellipsis Button */}
                <ButtonEllipsisOpener
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent triggering click for the chat item
                    setVisibleMenu(
                      visibleMenu === chat.thread_id ? null : chat.thread_id
                    );
                  }}
                >
                  <EllipsisVerticalIcon />
                </ButtonEllipsisOpener>
                {/* Menu Dropdown */}
                {visibleMenu === chat.thread_id && (
                  <DeleteDropDown
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <Button
                      className="delete"
                      role="menuitem"
                      onClick={(event) => {
                        event.preventDefault();
                        setDeleteChat(chat);
                      }}
                    >
                      Delete
                      <DeleteIcon />
                    </Button>
                  </DeleteDropDown>
                )}
              </YourChatListItem>
            )) ?? (
              <YourChatListItem className="loading">
                Loading ...
              </YourChatListItem>
            )}
          </YourChatList>
        </AccordionDetails>
      </AccordionCustom>

      {deleteChat && (
        <ConfirmationDialog
          show={deleteChat}
          title={`Delete ${deleteChat?.name}`}
          text={`Are you sure you want to delete chat "${deleteChat.name}"?`}
          onDelete={() => {
            if (deleteChat.thread_id) {
              props.deleteChat(deleteChat.thread_id);
            } else if (deleteChat.assistant_id) {
              if (deleteChat.mine === true) {
                props.deleteConfig(deleteChat.assistant_id);
              } else {
                props.workspaceDeleteChat(deleteChat.assistant_id);
              }
            }
            setDeleteChat(null);
          }}
          onClose={() => setDeleteChat(null)}
        />
      )}
    </>
  );
}
