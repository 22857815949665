import React from "react";
import {
  BoxProps,
  Link as MUILink,
  LinkProps as MUILinkProps,
  useTheme,
} from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Typography } from "../Typography/Typography";
import * as xxsFilters from "xss-filters";

export type LinkProps = MUILinkProps &
  RouterLinkProps & { removestyling: boolean };

export const Link = (props: LinkProps) => {
  return (
    <MUILink
      className={props.removestyling ? "unstyled-link" : undefined}
      component={RouterLink}
      {...props}
    />
  );
};

Link.defaultProps = {
  removestyling: undefined,
};

export type DocLinkWithIconProps = {
  name: string;
  href: string;
  target?: string;
  hideIcon?: boolean;
  internal?: boolean;
};

export const DOCLink = (props: BoxProps & DocLinkWithIconProps) => {
  const { palette } = useTheme();
  const href = xxsFilters.uriInUnQuotedAttr(
    props.href.includes("http") || props.internal
      ? props.href
      : `https://${props.href}`
  );
  return (
    <MUILink
      display={"flex"}
      href={href}
      underline="none"
      sx={{ mb: 2 }}
      target={props.target || ""}
    >
      <Typography
        color={palette.link.main}
        variant="body2"
        sx={{ fontWeight: "bold" }}
      >
        {props.name}
      </Typography>
      {!props.hideIcon && (
        <OpenInNewIcon
          color="link"
          fontSize="small"
          sx={{ ml: 1, transform: "scale(0.8)" }}
        />
      )}
    </MUILink>
  );
};
