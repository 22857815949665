import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { MainContainer, StyledActiveTab, StyledTab } from "./style";

type ObjectTabType = {
  label: string;
  key: string;
};

type TabType = ObjectTabType | string;

export type ExtractKeyType<T extends TabType> = T extends ObjectTabType
  ? T["key"]
  : T;

type Props<T extends readonly TabType[]> = {
  tabs: T;
  activeTab: ExtractKeyType<T[number]>;
  setActiveTab: (tabKey: ExtractKeyType<T[number]>) => void;
};

const CustomTabs = <T extends readonly TabType[]>({
  tabs,
  activeTab,
  setActiveTab,
}: Props<T>) => {
  const { palette } = useTheme();
  const ref = useRef<null | HTMLDivElement>(null);

  const isObjectTab = (tab: TabType): tab is ObjectTabType =>
    (tab as ObjectTabType).label !== undefined;

  const handleAnimation = useCallback((inputTab: TabType) => {
    // if tab is ObjectTypeTab then use the key attr in tab object else use tab string
    const tab = isObjectTab(inputTab) ? inputTab.key : inputTab;

    const activeEl = document.querySelector(
      `[data-id='${tab}']`
    ) as HTMLDivElement;
    if (ref.current && activeEl) {
      const tabWidth = activeEl.offsetWidth;
      const tabHeight = activeEl.offsetHeight;
      const tabLeft = activeEl.offsetLeft;

      ref.current.style.width = `${tabWidth}px`;
      ref.current.style.height = `${tabHeight}px`;
      ref.current.style.transform = `translateX(${tabLeft}px)`;
      setActiveTab(tab as typeof activeTab);
    }
  }, []);

  useEffect(() => {
    handleAnimation(activeTab);
  }, []);

  return (
    <MainContainer>
      <StyledActiveTab ref={ref}></StyledActiveTab>
      {tabs.map((tab, index) => (
        <StyledTab
          key={index}
          data-id={isObjectTab(tab) ? tab.key : tab}
          onClick={() => handleAnimation(tab)}
        >
          <Typography variant="body2" fontWeight={400}>
            {isObjectTab(tab) ? tab.label : tab}
          </Typography>
        </StyledTab>
      ))}
    </MainContainer>
  );
};

export default CustomTabs;
