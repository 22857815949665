import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MoveNextIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.125 15.5C1.23499 15.5 1.34301 15.4709 1.43812 15.4156L13.3131 8.54062C13.4078 8.48568 13.4865 8.40683 13.5411 8.31195C13.5958 8.21707 13.6245 8.10949 13.6245 8C13.6245 7.89051 13.5958 7.78293 13.5411 7.68805C13.4865 7.59317 13.4078 7.51432 13.3131 7.45938L1.43812 0.584375C1.34314 0.529391 1.23536 0.500387 1.12561 0.50028C1.01586 0.500172 0.908022 0.528966 0.812934 0.583763C0.717846 0.638561 0.638863 0.717431 0.583929 0.812442C0.528996 0.907452 0.50005 1.01525 0.5 1.125V14.875C0.5 15.0408 0.565847 15.1997 0.683058 15.3169C0.800268 15.4342 0.95924 15.5 1.125 15.5ZM16.75 0.5H15.5V15.5H16.75V0.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
