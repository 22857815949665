import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0.666687C15.8409 0.666687 17.3333 2.15907 17.3333 4.00002C17.3333 5.84097 15.8409 7.33335 14 7.33335C12.9861 7.33335 12.078 6.88073 11.4666 6.16654L7.266 8.33034C7.31012 8.54668 7.33329 8.77064 7.33329 9.00002C7.33329 9.2291 7.31018 9.45278 7.26617 9.66886L11.4666 11.8335C12.078 11.1193 12.9861 10.6667 14 10.6667C15.8409 10.6667 17.3333 12.1591 17.3333 14C17.3333 15.8409 15.8409 17.3334 14 17.3334C12.159 17.3334 10.6666 15.8409 10.6666 14C10.6666 13.7706 10.6898 13.5467 10.7339 13.3303L6.53328 11.1665C5.92192 11.8807 5.0138 12.3334 3.99996 12.3334C2.15901 12.3334 0.666626 10.8409 0.666626 9.00002C0.666626 7.15907 2.15901 5.66669 3.99996 5.66669C5.0138 5.66669 5.92192 6.11931 6.53328 6.83351L10.7339 4.6697C10.6898 4.45336 10.6666 4.2294 10.6666 4.00002C10.6666 2.15907 12.159 0.666687 14 0.666687ZM14 12.3334C13.3499 12.3334 12.7868 12.7055 12.512 13.2483C12.507 13.2657 12.4992 13.2821 12.4908 13.2984L12.4799 13.3154C12.3857 13.5242 12.3333 13.756 12.3333 14C12.3333 14.9205 13.0795 15.6667 14 15.6667C14.9205 15.6667 15.6666 14.9205 15.6666 14C15.6666 13.0795 14.9205 12.3334 14 12.3334ZM3.99996 7.33335C3.07948 7.33335 2.33329 8.07952 2.33329 9.00002C2.33329 9.92052 3.07948 10.6667 3.99996 10.6667C4.64998 10.6667 5.21309 10.2946 5.48788 9.75175C5.49298 9.73445 5.50077 9.71803 5.50915 9.70177L5.51997 9.68467C5.61419 9.47581 5.66663 9.24404 5.66663 9.00002C5.66663 8.756 5.61419 8.52423 5.51997 8.31537C5.51548 8.31045 5.51228 8.30443 5.50915 8.29835L5.48788 8.2483C5.21309 7.70546 4.64998 7.33335 3.99996 7.33335ZM14 2.33335C13.0795 2.33335 12.3333 3.07955 12.3333 4.00002C12.3333 4.24403 12.3857 4.4758 12.4799 4.68466C12.4845 4.68964 12.4877 4.69566 12.4908 4.70174L12.512 4.75173C12.7868 5.29457 13.3499 5.66669 14 5.66669C14.9205 5.66669 15.6666 4.9205 15.6666 4.00002C15.6666 3.07955 14.9205 2.33335 14 2.33335Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
