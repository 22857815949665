import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 0.333334C7.68693 0.333334 8.02607 0.473807 8.27613 0.723861C8.5262 0.973907 8.66667 1.31305 8.66667 1.66667V3H11.3333C11.7015 3 12 3.29848 12 3.66667C12 4.03485 11.7015 4.33333 11.3333 4.33333H11.28L10.6664 11.6972C10.6585 12.2166 10.4487 12.713 10.0809 13.0809C9.7058 13.4559 9.19707 13.6667 8.66667 13.6667H3.33333C2.80289 13.6667 2.29419 13.4559 1.91911 13.0809C1.55124 12.713 1.34148 12.2166 1.33356 11.6972L0.719333 4.33333H0.666667C0.29848 4.33333 0 4.03485 0 3.66667C0 3.29848 0.29848 3 0.666667 3H3.33333V1.66667C3.33333 1.31305 3.47381 0.973907 3.72386 0.723861C3.97391 0.473807 4.31305 0.333334 4.66667 0.333334H7.33333ZM9.942 4.33333H2.05733L2.66436 11.6113C2.66589 11.6297 2.66666 11.6482 2.66666 11.6667C2.66666 11.8435 2.7369 12.0131 2.86193 12.1381C2.98695 12.2631 3.15652 12.3333 3.33333 12.3333H8.66667C8.84347 12.3333 9.01307 12.2631 9.13807 12.1381C9.26307 12.0131 9.33333 11.8435 9.33333 11.6667C9.33333 11.6482 9.33407 11.6297 9.3356 11.6113L9.942 4.33333ZM4.66667 5.66667C5.03487 5.66667 5.33333 5.96513 5.33333 6.33333V10.3333C5.33333 10.7015 5.03487 11 4.66667 11C4.29848 11 4 10.7015 4 10.3333V6.33333C4 5.96513 4.29848 5.66667 4.66667 5.66667ZM7.33333 5.66667C7.70153 5.66667 8 5.96513 8 6.33333V10.3333C8 10.7015 7.70153 11 7.33333 11C6.96513 11 6.66667 10.7015 6.66667 10.3333V6.33333C6.66667 5.96513 6.96513 5.66667 7.33333 5.66667ZM7.33333 1.66667H4.66667V3H7.33333V1.66667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
