import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetCurrentUserInfoDocument,
  GetWorkspaceInfoDocument,
} from "../../generated";
import { SidebarLoader } from "../SidebarLoader/SidebarLoader";

import { useGetUserRole } from "../../hooks/useGetUserRole";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { SidebarMenuCollapse } from "../Icons/SidebarMenuCollapse";
import { Sidenav } from "../Sidenav/Sidenav";

import { AssetRoutes } from "../../routes";
import CollapseButton from "./CollapseButton";
import { genNav } from "./genNav";
import SidebarLogo from "./SidebarLogo";
import {
  OpenCollapseBtnContainer,
  SidebarOwnCollapseButton,
  SidebarOwnCollapseWrapper,
  SideNavTopWrap,
  Wrapper,
} from "./style";

export interface WorkspaceSidenavProps {
  workspaceName?: string;
  collapse: boolean;
  setCollapse: Function;
  isEditable?: boolean;
}

export const SideBarV3 = ({
  workspaceName,
  collapse,
  setCollapse,
  isEditable,
}: WorkspaceSidenavProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const { workspaceId } = useWorkspaceId();
  const { accessible, role, loading } = useGetUserRole(workspaceId);
  const { data, loading: workspaceLoading } = useQuery(
    GetWorkspaceInfoDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
      },
    }
  );
  const showConfig = false;

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.currentUser.workspaces.find(
          (workpace) => workpace?.id === workspaceId
        )?.acceptedPolicies
      )
        return;
      else {
        navigate(`/agree-policy/${workspaceId}`);
      }
    }
  }, [userInfo]);

  const toggleCollapse = () => setCollapse((prev: boolean) => !prev);

  return (
    <>
      <Sidenav show={!collapse} aria-label={workspaceName}>
        <Wrapper className={!collapse ? "" : "sidenav-collapsed"}>
          {workspaceLoading ? (
            <SidebarLoader />
          ) : (
            <>
              <SidebarLogo
                collapse={collapse}
                data={data}
                workspaceName={workspaceName}
                toggleCollapse={toggleCollapse}
              />
              {/*
            <Box alignItems="center" display="flex" sx={{ padding: "13px 16px" }}>
              <SquareIcon
                icon={<GridIcon />}
                backgroundColor={`${palette.gray.lighter}`}
                color={`${palette.common.white}`}
                sx={{
                  width: "28px",
                  height: "28px",
                  minWidth: "28px",
                  mr: 2,
                  borderRadius: "6px",
                }}
              />

              <Typography
                variant="subtitle3"
                sx={{
                  color: palette.common.white,
                  maxWidth: "180px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: collapse ? "0" : "1",
                  visibility: collapse ? "hidden" : "visible",
                  transition: "opacity 0.3s ease, visibility 0.3s ease",

                  "@media (min-width: 768px)": {
                    opacity: collapse ? "1" : "0",
                    visibility: collapse ? "visible" : "hidden",
                  },
                }}
              >
                <Tooltip
                  title={workspaceName}
                  placement="bottom"
                  componentsProps={{
                    tooltip: { sx: { backgroundColor: palette.common.black } },
                    arrow: { sx: { color: palette.common.black } },
                  }}
                  arrow
                >
                  <span>{workspaceName}</span>
                </Tooltip>
              </Typography>
            </Box>
          */}
              {!loading && (
                <SideNavTopWrap>
                  {genNav(
                    AssetRoutes.Workspace.routes,
                    pathname,
                    workspaceId,
                    role,
                    collapse,
                    setCollapse
                  )}
                </SideNavTopWrap>
              )}

              <SidebarOwnCollapseWrapper>
                <SidebarOwnCollapseButton
                  sx={{
                    transform: collapse ? "rotate(0)" : "rotate(180deg)",
                    left: collapse ? "16px" : "20px",

                    "@media (min-width: 768px)": {
                      transform: collapse ? "rotate(180deg)" : "rotate(0)",
                      left: collapse ? "20px" : "16px",
                    },
                  }}
                  onClick={() => setCollapse((prev: boolean) => !prev)}
                >
                  <SidebarMenuCollapse />
                </SidebarOwnCollapseButton>
              </SidebarOwnCollapseWrapper>
            </>
          )}
        </Wrapper>
        {collapse && (
          <OpenCollapseBtnContainer>
            <CollapseButton
              collapse={collapse}
              toggleCollapse={toggleCollapse}
            />
          </OpenCollapseBtnContainer>
        )}
      </Sidenav>
    </>
  );
};
