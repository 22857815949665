import { Box, Divider, TextField, Tooltip, useTheme } from "@mui/material";
import { Sidenav } from "../common/Sidenav/Sidenav";
import { SquareIconButton } from "../common/SquareIconButton/SquareIconButton";
import { SidenavButtonLink } from "../common/Sidenav/SidenavButtonLink";
import { Typography } from "../common/Typography/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetUserRole } from "../hooks/useGetUserRole";
import { CollapsableNav } from "../common/SideNavigationPage/CollapsableNav";
import { PapersListIcon } from "../common/Icons/PapersListIcon";
import { Button } from "../common/Button/Button";
import { PlusIcon } from "../common/Icons/PlusIcon";
import chatApi from "../api/chatApi";
import { useState } from "react";
import { toast } from "react-toastify";
import { SidenavHolder, StyledContainer } from "./style";
import DropdownButton from "./DropdownButton";

export interface SessionNavNavProps {
  workspaceName: string;
  show: boolean;
  setShowSidenav: Function;
  threads: any;
  setRefetch: Function;
}

export const SessionNav = ({
  workspaceName,
  show,
  setShowSidenav,
  threads,
  setRefetch,
}: SessionNavNavProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { pathname } = useLocation();
  const { loading } = useGetUserRole(workspaceId);
  const [edit, setEdit] = useState<any>();

  const updateText = (id: string, text: string) => {
    chatApi
      .patch(`/threads/${id}`, { metadata: { Title: text } })
      .then((res) => toast.success(`Title updated to ${text}`))
      .catch((err) => console.log(err))
      .finally(() => {
        setRefetch((prev: any) => !prev);
        setEdit(null);
      });
  };

  return (
    <StyledContainer sx={{ width: show ? "220px" : "0px" }}>
      <SidenavHolder>
        {show && (
          <Sidenav
            show={show}
            aria-label={workspaceName}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            sx={{
              backgroundColor: palette.common.white,
              paddingBottom: "3rem",
              position: "relative",
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              sx={{ backgroundColor: palette.common.white, zIndex: 1 }}
            >
              <Button
                onClick={() => navigate(`/workspace/${workspaceId}/brightbot`)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  p: 2,
                }}
              >
                <SquareIconButton
                  variant="contained"
                  size="medium"
                  icon={<PlusIcon />}
                  color="settings"
                  sx={{
                    textTransform: "initial",
                    pointerEvents: "none",
                    marginRight: "8px",
                    boxShadow: "none",
                    background: palette.settings.lighter,
                    minWidth: "36px",
                    height: "36px",

                    "@media (min-width: 992px)": {
                      marginRight: show ? "8px" : "0",
                    },
                  }}
                />
                <Typography
                  variant="subtitle3"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "165px",
                    textAlign: "left",
                    width: "100%",
                    opacity: show ? "0" : "1",
                    visibility: show ? "hidden" : "visible",
                    transition: "opacity 0.3s ease, visibility 0.3s ease",

                    "@media (min-width: 992px)": {
                      opacity: show ? "1" : "0",
                      visibility: show ? "visible" : "hidden",
                    },
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title="New Session"
                    componentsProps={{
                      tooltip: {
                        sx: { backgroundColor: palette.common.black },
                      },
                      arrow: { sx: { color: palette.common.black } },
                    }}
                    arrow
                    onClick={() =>
                      navigate(`/workspace/${workspaceId}/brightbot`)
                    }
                  >
                    <span>New Session</span>
                  </Tooltip>
                </Typography>
              </Button>
            </Box>
            <Divider />
            {!loading && (
              <Box>
                {threads?.map((thread: any, index: number) => (
                  <Box key={thread.thread_id} className="roll-out">
                    {thread?.thread_id === edit?.thread_id ? (
                      <TextField
                        className="input"
                        name="thread_name"
                        defaultValue={
                          thread?.metadata?.Title || thread.thread_id
                        }
                        onKeyPress={(e: any) => {
                          if (e.key === "Enter") {
                            updateText(thread.thread_id, e.target.value);
                          }
                        }}
                      />
                    ) : (
                      <Box className="linkholder">
                        <SidenavButtonLink
                          to={`/workspace/${workspaceId}/brightbot/${thread.thread_id}`}
                          label={
                            index === 0
                              ? thread?.metadata?.Title || "This Session"
                              : thread?.metadata?.Title || thread.thread_id
                          }
                          active={pathname.includes(
                            `brightbot/${thread.thread_id}`
                          )}
                          className={
                            pathname.includes(`brightbot/${thread.thread_id}`)
                              ? "active"
                              : ""
                          }
                        />
                        <DropdownButton
                          thread={thread}
                          setRefetch={setRefetch}
                          setEdit={setEdit}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            {/* <CollapsableNav
          sideNav
          expanded={show}
          onClickToggleNav={() => setShowSidenav(!show)}
        /> */}
          </Sidenav>
        )}
      </SidenavHolder>
    </StyledContainer>
  );
};
