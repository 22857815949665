import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Label } from "../../common/Label/Label";
import { Field, Formik, Form, FieldArray } from "formik";
import { TextField } from "formik-mui";
import { TextField as MaterialUITextField } from "@mui/material";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import * as Yup from "yup";
import Editor from "@monaco-editor/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Dropdown from "../../common/Dropdown/Dropdown";
import RightSidePanel from "../../common/RightSidePanel";
import { size } from "lodash";
import { Alert } from "../../common/Alert";
import { Tag } from "../../common/Tag/Tag";
import { schemaJsonParser } from "../../helpers";
import FormikTextAreaAutosize from "../../common/TextAreaAutosize/FormikTextAreaAutosize";

interface ModalSchemaFieldProps {
  editMode?: boolean;
  closeDialog: Function;
  initialValues?: any;
  submit: Function;
  targetSchema: any;
}

export const CreateNewSchema = ({
  targetSchema,
  editMode = false,
  closeDialog,
  initialValues,
  submit,
}: ModalSchemaFieldProps) => {
  const { palette } = useTheme();
  const [enumValue, setEnumValue] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <Box
      boxSizing="border-box"
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ".asterisk": {
          color: palette.error.main,
        },
      }}
    >
      <Box
        sx={{
          paddingRight: "20px",
          paddingBottom: "16px",
        }}
        mb={6}
      >
        <Typography
          mb={4}
          variant="h5"
          color={palette.common.black}
          sx={{ wordBreak: "break-word" }}
        >
          {size(initialValues) > 0
            ? "Edit Schema Property"
            : "Create Schema Property"}
        </Typography>
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is Required"),
            description: Yup.string()
              .required("Description is Required")
              .max(MAX_DESCRIPTION_CHARS),
            type: Yup.string().required("Type is Required"),
            format: Yup.string().when("type", {
              is: (v: string) => v === "string",
              then: Yup.string().required("Format is required"),
              otherwise: Yup.string().notRequired(),
            }),
          })}
          initialValues={
            initialValues
              ? {
                  ...initialValues,
                  pii: initialValues.pii || false,
                  validate:
                    initialValues.required || initialValues.pattern
                      ? true
                      : false,
                  required: initialValues.required || false,
                  unique: initialValues.unique || false,
                  pattern: initialValues.pattern ? true : false,
                  patternValue: initialValues.pattern,
                  enum: initialValues.enum || [],
                }
              : {
                  name: "",
                  description: "",
                  title: "",
                  type: "",
                  format: "",
                  enum: [],
                  // primaryKey: false,
                  pii: false,
                  validate: false,
                  required: false,
                  unique: false,
                  // label: false,
                  // maxLength: false,
                  // minimum: false,
                  // maximum: false,
                  pattern: false,
                  patternValue: "",
                  // oneOf: false,
                  // oneOfValues: [],
                }
          }
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              await submit({
                ...values,
              });
              setSubmitting(false);
              closeDialog();
            } catch (err) {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box width="100%" boxSizing="border-box">
                  <Box mt={4}>
                    <Label
                      mb={1}
                      value={"Name"}
                      width={"100%"}
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: "400",
                      }}
                    />
                    <span className="asterisk"> *</span>
                    <Field
                      component={TextField}
                      name="name"
                      hiddenLabel
                      fullWidth
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                      sx={{ mb: 1 }}
                    />
                    <Typography
                      variant="body3"
                      color={palette.gray.dark}
                      sx={{ mb: 6 }}
                    >
                      The column name as it appears in a dataset or table
                    </Typography>
                    <Box display={"flex"} justifyContent="space-between">
                      <Label
                        mb={1}
                        value="Title"
                        width={"100%"}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                        }}
                      />
                      <Typography
                        variant="body3"
                        color={palette.gray.dark}
                        sx={{}}
                      >
                        Optional
                      </Typography>
                    </Box>
                    <Field
                      component={TextField}
                      name="title"
                      hiddenLabel
                      fullWidth
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                      sx={{ mb: 1 }}
                    />
                    <Typography
                      variant="body3"
                      color={palette.gray.dark}
                      sx={{ mb: 6 }}
                    >
                      Human-friendly label for the property that will display
                    </Typography>
                    <Box sx={{ mb: 6 }}>
                      <Label
                        mb={1}
                        value="Type"
                        width={"100%"}
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                        }}
                      />
                      <span className="asterisk"> *</span>
                      <Dropdown
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                          if (e.target.value !== "string") {
                            setFieldValue("format", "");
                            setFieldValue("patternValue", "");
                          }
                        }}
                        value={values.type}
                        options={[
                          "integer",
                          "number",
                          "string",
                          "boolean",
                          "object",
                          "array",
                          "null",
                        ]}
                      />
                      {errors.type && touched.type && (
                        <FormHelperText sx={{ marginLeft: "14px" }} error>
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Box>
                    {/* if Type is string then we need to show FORMAT otherwise hide it */}
                    {values.type === "string" && (
                      <Box sx={{ mb: 6 }}>
                        <Label
                          mb={1}
                          value="Format"
                          width={"100%"}
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "400",
                          }}
                        />
                        <span className="asterisk"> *</span>
                        <Dropdown
                          onChange={(e) => {
                            setFieldValue("format", e.target.value);
                          }}
                          value={values.format}
                          options={[
                            "none",
                            "date-time",
                            "email",
                            "uuid",
                            "uri",
                            "regex",
                          ]}
                        />
                        {errors.format && touched.format && (
                          <FormHelperText sx={{ marginLeft: "14px" }} error>
                            {errors.format}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                    <Label
                      mb={1}
                      value="Description"
                      width={"100%"}
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: "400",
                      }}
                    />
                    <span className="asterisk"> *</span>
                    <Field
                      component={FormikTextAreaAutosize}
                      name="description"
                      hiddenLabel
                      rows={3}
                      fullWidth
                      size="small"
                      sx={{ mb: 2, fontSize: "1rem" }}
                      maxLength={MAX_DESCRIPTION_CHARS}
                    />
                    <Typography
                      variant="body3"
                      color={palette.gray.dark}
                      sx={{ mb: 6 }}
                    >
                      {values.description.length}/{MAX_DESCRIPTION_CHARS}{" "}
                      characters
                    </Typography>
                    {/* <Field
                      type="checkbox"
                      name="primaryKey"
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label="This field is the primaryKey"
                      style={{ width: "100%" }}
                    /> */}
                    {/* <Field
                      type="checkbox"
                      name="pii"
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label="This field contains PII (Personal Identifiable Information)"
                      style={{ width: "100%" }}
                      sx={{
                        mb: 6,
                        ".MuiTypography-root": {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                        },
                      }}
                    /> */}
                    {values.type === "array" && (
                      <Box
                        display={"flex"}
                        pb={"1.5rem"}
                        alignItems={"flex-start"}
                        maxWidth={"58rem"}
                      >
                        <Box width="100%">
                          <MaterialUITextField
                            hiddenLabel
                            fullWidth
                            placeholder="Add enum here"
                            value={enumValue}
                            onChange={(e) => setEnumValue(e.target.value)}
                            onKeyDown={(e: any) => {
                              if (e.keyCode === 13) {
                                setFieldValue("enum", [
                                  ...values.enum,
                                  enumValue,
                                ]);

                                setEnumValue("");
                              }
                            }}
                            inputProps={{
                              sx: {
                                fontSize: "1rem",
                                padding: ".375rem .75rem",
                              },
                            }}
                            size="small"
                          />
                          <Box display={"flex"} pt={"1.125rem"}>
                            {values.enum.map((tag: string) => (
                              <Tag
                                label={tag}
                                dismissable={true}
                                mr={2}
                                onClickDismiss={() => {
                                  setFieldValue("enum", [
                                    ...(values.enum.filter(
                                      (aTag: any) => tag !== aTag
                                    ) || []),
                                  ]);
                                }}
                              ></Tag>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <Field
                      type="checkbox"
                      name="validate"
                      as={FormControlLabel}
                      control={<Checkbox size="medium" />}
                      label="Validate field constraints"
                      style={{ width: "100%" }}
                      sx={{
                        mb: 6,
                        ".MuiTypography-root": {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                        },
                      }}
                    />
                    {values.validate && (
                      <Box
                        sx={{
                          border: `1px solid ${palette.action.disabledBackground}`,
                          borderRadius: "4px",
                          p: 4,
                          mb: 6,
                        }}
                      >
                        <Typography
                          variant="body1"
                          color={palette.common.black}
                          mb={6}
                        >
                          Field constraints
                        </Typography>
                        <Alert
                          severity="warning"
                          sx={{
                            marginBottom: "16px",
                          }}
                        >
                          Editing these constraints may cause validation issues.
                          Procede with caution.
                        </Alert>

                        {["required"].map((input) => (
                          <Box key={input}>
                            <Field
                              type="checkbox"
                              name={input}
                              as={FormControlLabel}
                              control={<Checkbox />}
                              label={input}
                              style={{
                                width: "100%",
                                textTransform: "capitalize",
                              }}
                              sx={{
                                mb: 2,
                                ".MuiTypography-root": {
                                  fontSize: "0.875rem",
                                  lineHeight: 1.2,
                                },
                              }}
                            />
                          </Box>
                        ))}
                        {values.type === "string" &&
                          [
                            // ["label", "labelValue"],
                            // ["maxLength", "maxLengthValue"],
                            // ["minimum", "minimumValue"],
                            // ["maximum", "maximumValue"],
                            ["pattern", "patternValue"],
                          ].map((input) => (
                            <>
                              <Field
                                type="checkbox"
                                name={input[0]}
                                as={FormControlLabel}
                                control={<Checkbox />}
                                label={input[0]}
                                style={{
                                  width: "100%",
                                  textTransform: "capitalize",
                                }}
                                sx={{
                                  mb: 2,
                                  ".MuiTypography-root": {
                                    fontSize: "0.875rem",
                                    lineHeight: 1.2,
                                  },
                                }}
                              />
                              {(values as any)[input[0]] && (
                                <Box
                                  sx={{
                                    background: palette.settings.lighter,
                                    borderRadius: "4px",
                                    padding: "16px",
                                    margin: "0 0 0 24px",
                                  }}
                                >
                                  <Label
                                    mb={1}
                                    value="Value"
                                    width={"100%"}
                                    sx={{
                                      fontSize: "0.875rem",
                                      fontWeight: "400",
                                    }}
                                  />
                                  <Field
                                    component={TextField}
                                    name={input[1]}
                                    fullWidth
                                    hiddenLabel
                                    rows={3}
                                    multiline
                                    value={values.patternValue}
                                    inputProps={{
                                      sx: {
                                        fontSize: "1rem",
                                        padding: ".375rem .75rem",
                                      },
                                    }}
                                    size="small"
                                    sx={{
                                      background: "white",
                                      borderRadius: "3px",
                                      borderColor: palette.grayAccent.light,
                                    }}
                                  />
                                </Box>
                              )}
                            </>
                          ))}

                        {/* <Field
                            type="checkbox"
                            name="oneOf"
                            as={FormControlLabel}
                            control={<Checkbox />}
                            label="oneOf"
                            style={{ width: "100%" }}
                          /> */}
                        {/* {values.oneOf && (
                            <FieldArray name="oneOfValues">
                              {({ insert, remove, push }) => (
                                <Box>
                                  {values.oneOfValues.length > 0 &&
                                    values.oneOfValues.map(
                                      (oneOf: any, index: any) => (
                                        <Box key={index}>
                                          <Field
                                            component={TextField}
                                            name={`oneOfValues.${index}.type`}
                                            label="Type"
                                            size="small"
                                            sx={{ mb: 6 }}
                                          />
                                          <Field
                                            component={TextField}
                                            label="Value"
                                            name={`oneOfValues.${index}.value`}
                                            size="small"
                                            sx={{ mb: 6 }}
                                          />
                                          <Button
                                            type="button"
                                            onClick={() => remove(index)}
                                          >
                                            Remove
                                          </Button>
                                        </Box>
                                      )
                                    )}
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      push({ type: "", value: "" })
                                    }
                                  >
                                    Add Field
                                  </Button>
                                </Box>
                              )}
                            </FieldArray>
                          )} */}
                      </Box>
                    )}
                    <Label
                      mb={1}
                      value="Edit Raw JSON Schema (optional)"
                      width={"15.5rem"}
                    />

                    <Box sx={{ mb: 6 }}>
                      <Button
                        variant="outlined"
                        color="grayAccent"
                        size="small"
                        startIcon={<NavigateNextIcon />}
                        onClick={() => setOpen(!open)}
                      >
                        Show Raw JSON Schema
                      </Button>
                      <Collapse in={open} timeout="auto">
                        <Box
                          sx={{
                            border: `1px solid ${palette.grayAccent.light}`,
                            borderRadius: "4px",
                            padding: "4px",
                            mt: "8px",
                          }}
                        >
                          <Editor
                            height="300px"
                            defaultLanguage="json"
                            value={
                              initialValues
                                ? JSON.stringify(
                                    schemaJsonParser(
                                      targetSchema,
                                      initialValues,
                                      values
                                    ),
                                    null,
                                    2
                                  )
                                : JSON.stringify(
                                    {
                                      $id: "https://example.com/blank.schema.json",
                                      title: "Blank-schema-template",
                                      description: "",
                                      type: "object",
                                      properties: {},
                                    },
                                    null,
                                    2
                                  )
                            }
                            onChange={(v) => setFieldValue("jsonSchema", v)}
                          />
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </Box>

                <Button
                  onClick={() => closeDialog()}
                  color="grayAccent"
                  variant="outlined"
                  sx={{ ml: "auto", mr: 3 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={submitForm}
                  color="primary"
                  variant="contained"
                >
                  {editMode ? "Update" : "Save changes"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};
