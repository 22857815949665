import { Tooltip, useTheme } from "@mui/material";
import { GetWorkspaceInfoQuery } from "../../../../generated";
import { getRandomNumber } from "../../../../helpers";
import {
  SideNavLogo,
  SideNavLogoLink,
  SideNavLogoText,
  SideNavLogoTypo,
} from "../../style";

type Props = {
  collapse: boolean;
  data: GetWorkspaceInfoQuery | undefined;
  workspaceName?: string;
};

const TenantLogo = ({ data, collapse, workspaceName }: Props) => {
  const { palette } = useTheme();
  return (
    <SideNavLogoText>
      <img
        src={`${data?.workspace?.logoUrl}?${getRandomNumber()}` || ""}
        width={24}
        height={24}
      />
      <SideNavLogoTypo
        variant="subtitle3"
        sx={{
          opacity: collapse ? "0" : "1",
          visibility: collapse ? "hidden" : "visible",

          "@media (min-width: 768px)": {
            opacity: collapse ? "0" : "1",
            visibility: collapse ? "hidden" : "visible",
          },
        }}
      >
        <Tooltip
          title={workspaceName}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: { backgroundColor: palette.common.black },
            },
            arrow: { sx: { color: palette.common.black } },
          }}
          arrow
        >
          <span>{workspaceName}</span>
        </Tooltip>
      </SideNavLogoTypo>
    </SideNavLogoText>
  );
};

export default TenantLogo;
