import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface ConfirmationDialogProps {
  text: string;
  title: string;
  onDelete: () => void;
  show: boolean;
  onClose: () => void;
}
const ConfirmationDialog = ({
  text,
  title,
  onDelete,
  show,
  onClose,
}: ConfirmationDialogProps) => {
  const { palette } = useTheme();

  return (
    <Dialog maxWidth="sm" open={show}>
      <Box width="440px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          {text}
        </Typography>
        <Box mb={8}>
          <Typography variant="body3" color={palette.gray.dark} sx={{ mb: 2 }}>
            {title}
          </Typography>
        </Box>
        <Box mt={4} display="flex" alignItems={"center"}>
          <Button
            color="grayAccent"
            variant="outlined"
            onClick={() => onClose()}
            sx={{ ml: "auto", mr: 3 }}
          >
            Cancel
          </Button>
          <Button color="error" variant="contained" onClick={() => onDelete()}>
            Delete
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
