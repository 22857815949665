import { Box, styled } from "@mui/material";

export const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column-reverse",
  flex: "1 1 0",
  overflowY: "auto",
});

export const SubMainContainer = styled(Box)({
  maxWidth: "890px",
  width: "100%",
  boxSizing: "border-box",
  margin: "0 auto",
  paddingBottom: "1rem",
  paddingX: "1.5rem",
});

export const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export const EditorContainer = styled(Box)({
  paddingInline: "1rem",
});
