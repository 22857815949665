import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { PaperClipIcon, ShareIcon } from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";
import { orderBy, last } from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  ConfigListProps,
  Config as ConfigInterface,
} from "../hooks/useConfigList";
import { SchemaField, Schemas } from "../hooks/useSchemas";
import { cn } from "../utils/cn";
import { FileUploadDropzone } from "./FileUpload";
import { Combobox, Disclosure, Switch } from "@headlessui/react";
import { DROPZONE_CONFIG, TYPES } from "../constants";
import { Tool, ToolConfig, ToolSchema } from "../utils/formTypes";
import { useToolsSchemas } from "../hooks/useToolsSchemas";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import { CaretUpViiIcon } from "../../../common/Icons/CaretUpViiIcon";
import {
  PlusIcon,
  MinusIcon,
  RocketLaunchIcon,
} from "@heroicons/react/20/solid";
import { marked } from "marked";
import { Box, TextField } from "@mui/material";
import {
  RadioButton,
  FakeRadio,
  LabelText,
  RadioLabel,
  SharelinkArea,
  SharelinkAreaButton,
  SharelinkArealink,
  CustomLabelHeading,
  CustomLabeldes,
  RadioRow,
  CustomButtonTrash,
  NewChatBottomBlock,
  CustomTextarea,
  CustomForm,
  NameSaveArea,
  NameSaveAreaInner,
  NameSaveAreaInputHolder,
  CustomSaveButton,
  SelectToolBox,
  SelectToolHolder,
  SelectToolWrapper,
  SelectBoxIcon,
  AutocompleteHolder,
} from "./style";
import Autocomplete from "@mui/material/Autocomplete";
function Types(props: {
  field: SchemaField;
  value: string;
  readonly: boolean;
  setValue: (value: string) => void;
  alwaysExpanded?: boolean;
}) {
  const options = (
    props.field.enum?.map((id) => TYPES[id as keyof typeof TYPES]) ?? []
  ).filter((op) => op.id === "agent");

  return (
    <div className="-mx-8 mt-6 pt-4 border-t-2 border-dotted mb-8">
      <div className="mx-8 md:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className={cn(
            "block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          )}
          defaultValue={options.find((o) => o.id === props.value)?.id}
          onChange={(e) => props.setValue(e.target.value)}
          disabled={props.readonly}
        >
          {options.map((option) => (
            <option key={option.id}>{option.title}</option>
          ))}
        </select>
      </div>
      <div className="mx-8 hidden md:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {options.map((option) => (
              <div
                key={option.id}
                className={cn(
                  props.value === option.id
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  props.readonly
                    ? props.value === option.id
                      ? "cursor-default"
                      : "cursor-default opacity-50 pointer-events-none"
                    : "cursor-pointer"
                )}
                aria-current={props.value === option.id ? "page" : undefined}
                onClick={
                  !props.readonly ? () => props.setValue(option.id) : undefined
                }
                aria-disabled={props.readonly}
              >
                {option.title}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

function Label(props: { id?: string; title: string; description?: string }) {
  return (
    <CustomLabelHeading htmlFor={props.id}>
      <div>{props.title}</div>
      {props.description && (
        <CustomLabeldes>{props.description}</CustomLabeldes>
      )}
    </CustomLabelHeading>
  );
}

function StringField(props: {
  id: string;
  field: SchemaField;
  value: string;
  title: string;
  readonly: boolean;
  setValue: (value: string) => void;
}) {
  return (
    <div>
      <Label
        id={props.id}
        title={props.title}
        description={props.field.description}
      />
      <CustomTextarea
        rows={4}
        name={props.id}
        id={props.id}
        value={props.value}
        readOnly={props.readonly}
        disabled={props.readonly}
        onChange={(e) => props.setValue(e.target.value)}
      />
    </div>
  );
}

export default function SingleOptionField(props: {
  id: string;
  field: SchemaField;
  value: string;
  title: string;
  readonly: boolean;
  setValue: (value: string) => void;
}) {
  return (
    <AutocompleteHolder>
      <Label
        id={props.id}
        title={props.field.title}
        description={props.field.description}
      />
      <Autocomplete
        className="chat-auto-complete"
        disablePortal
        options={props?.field?.enum || []}
        value={props.value}
        onChange={(e, value) => props.setValue(value || "")}
        renderInput={(params) => <TextField {...params} />}
      />
    </AutocompleteHolder>
  );
}

const ToolDisplay = (props: {
  tool: Tool;
  onRemoveTool: () => void;
  onUpdateToolConfig: (conf: ToolConfig) => void;
  readonly: boolean;
}) => {
  const { tool, onRemoveTool, onUpdateToolConfig, readonly } = props;
  const confs = Object.entries(tool.config);
  return (
    <Disclosure
      as="div"
      key={"tool-" + tool.id}
      className="tootls-block"
      defaultOpen={!readonly}
    >
      {({ open }) => (
        <>
          <div className="tootls-row">
            {Object.keys(tool.config).length > 0 ? (
              <Disclosure.Button className="open-close-btn">
                {open ? <MinusIcon /> : <PlusIcon />}
              </Disclosure.Button>
            ) : (
              <div className="open-close-btn add">
                <RocketLaunchIcon />
              </div>
            )}
            <div className="info">
              <label>{tool.name}</label>
              {tool.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(tool.description) as any,
                  }}
                ></div>
              )}
            </div>
            {!readonly && (
              <CustomButtonTrash onClick={onRemoveTool}>
                <DeleteIcon />
              </CustomButtonTrash>
            )}
          </div>
          {confs.length > 0 && (
            <Disclosure.Panel className="formholder">
              {confs.map(([key, value]) => (
                <div className="fieldset" key={key}>
                  <label htmlFor={`${tool.id}-${key}`}>{key}</label>
                  <input
                    id={`${tool.id}-${key}`}
                    value={value}
                    onChange={(e) =>
                      onUpdateToolConfig({ [key]: e.target.value })
                    }
                    autoComplete="off"
                    readOnly={readonly}
                  />
                </div>
              ))}
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

function ToolSelectionField(props: {
  readonly: boolean;
  retrievalOn: boolean;
  selectedTools: Tool[];
  onAddTool: (tool: Tool) => void;
  onRemoveTool: (toolId: string) => void;
  onUpdateToolConfig: (
    toolId: string,
    config: {
      [key: string]: string;
    }
  ) => void;
}) {
  const { onAddTool, onRemoveTool, retrievalOn, selectedTools } = props;
  const { tools: availableTools, loading } = useToolsSchemas();
  const [query, setQuery] = useState("");
  const [filteredTools, setFilteredTools] = useState<ToolSchema[]>([]);

  const [value, setValue] = useState<ToolSchema | null>(null);

  const ref = useRef<any>(null);

  const handleSelectTool = useCallback(
    (toolSchema: ToolSchema) => {
      // Initialize config object based on ToolSchema
      const config: { [key: string]: string } = {};
      Object.keys(toolSchema.config.properties).forEach((key) => {
        const property = toolSchema.config.properties[key];
        // Use the default value if specified, otherwise initialize to an empty string
        config[key] = property.default || "";
      });

      // Create a new tool object with initialized config
      const tool: Tool = {
        id: toolSchema.name === "Retrieval" ? "retrieval" : uuidv4(),
        type: toolSchema.type,
        name: toolSchema.name,
        description: toolSchema.description,
        config: config,
      };

      onAddTool(tool);

      setValue(null);

      ref.current.value = "";
      ref.current?.blur();

      setQuery(""); // Clear the query
    },
    [onAddTool]
  );

  useEffect(() => {
    const retrieval = availableTools.find((t) => t.name === "Retrieval");
    if (!retrieval) return;
    const retrievalSelected = selectedTools.some((t) => t.name === "Retrieval");
    if (retrievalOn && !retrievalSelected) {
      handleSelectTool(retrieval);
    }
    if (!retrievalOn && retrievalSelected) {
      onRemoveTool("retrieval");
    }
  }, [
    retrievalOn,
    onRemoveTool,
    availableTools,
    handleSelectTool,
    selectedTools,
  ]);

  useEffect(() => {
    let toolSchemas = availableTools.filter(
      (tool) => tool.name !== "Retrieval"
    );
    if (query !== "") {
      toolSchemas = toolSchemas.filter((tool) =>
        tool.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      );
    }
    toolSchemas = toolSchemas.filter(
      (tool) =>
        !selectedTools.some((t) => t.name === tool.name && !tool.multiUse)
    );
    setFilteredTools(toolSchemas);
  }, [query, availableTools, selectedTools]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AutocompleteHolder>
      <Label title="Tools" />
      {props.selectedTools.map((t) => (
        <ToolDisplay
          key={`tool-display-${t.id}`}
          tool={t}
          onRemoveTool={() => props.onRemoveTool(t.id)}
          onUpdateToolConfig={(conf) => props.onUpdateToolConfig(t.id, conf)}
          readonly={props.readonly || t.name === "Retrieval"}
        />
      ))}
      <Autocomplete
        className="chat-auto-complete"
        disablePortal
        options={filteredTools || []}
        getOptionLabel={(option) => option.name}
        clearOnBlur
        onChange={(e, value: any) => handleSelectTool(value)}
        renderInput={(params) => (
          <TextField
            inputRef={(el) => {
              // Capture the input element reference
              if (el) {
                ref.current = el;
              }
            }}
            placeholder="Select a tool"
            {...params}
          />
        )}
      />
    </AutocompleteHolder>
  );
}

function PublicLink() {
  const link = window.location.href;
  return (
    <SharelinkArea>
      <SharelinkAreaButton
        type="submit"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await navigator.clipboard.writeText(link);
          window.alert("Copied to clipboard!");
        }}
      >
        <ShareIcon aria-hidden="true" />
        Copy Public Link
      </SharelinkAreaButton>
      <SharelinkArealink href={link}>{link}</SharelinkArealink>
    </SharelinkArea>
  );
}

function PublicToggle(props: {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  className?: string;
}) {
  return (
    <Switch.Group as="div" className={cn("flex items-center", props.className)}>
      <Switch
        checked={props.enabled}
        onChange={props.setEnabled}
        className={cn(
          props.enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            props.enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        <span className="font-medium text-gray-900">Public?</span>
      </Switch.Label>
    </Switch.Group>
  );
}

function fileId(file: File) {
  return `${file.name}-${file.size}-${file.lastModified}`;
}

const ORDER = [
  "system_message",
  "retrieval_description",
  "interrupt_before_action",
  "tools",
  "llm_type",
  "agent_type",
];

function assignDefaults(
  config: ConfigInterface["config"] | undefined | null,
  configDefaults: Schemas["configDefaults"]
) {
  return config
    ? {
        ...config,
        configurable: {
          ...configDefaults?.configurable,
          ...config.configurable,
        },
      }
    : configDefaults;
}

export function Config(props: {
  className?: string;
  configSchema: Schemas["configSchema"];
  configDefaults: Schemas["configDefaults"];
  config: ConfigInterface | null;
  saveConfig: ConfigListProps["saveConfig"];
  enterConfig: (id: string | null) => void;
  edit?: boolean;
}) {
  const [values, setValues] = useState(
    assignDefaults(props.config?.config, props.configDefaults)
  );
  const [selectedTools, setSelectedTools] = useState<Tool[]>([]);
  const typeKey = "type";
  const typeField =
    props.configSchema?.properties.configurable.properties[typeKey];
  const typeValue = values?.configurable?.[typeKey];
  const typeSpec = typeValue ? TYPES[typeValue as keyof typeof TYPES] : null;
  const [files, setFiles] = useState<File[]>([]);
  const dropzone = useDropzone(DROPZONE_CONFIG);
  const [isPublic, setPublic] = useState(props.config?.public ?? false);

  useEffect(() => {
    if (!values) return;
    if (!values.configurable) return;
    const tools = (values.configurable["type==agent/tools"] as Tool[]) ?? [];
    setSelectedTools((oldTools) =>
      oldTools !== tools ? [...tools] : oldTools
    );
  }, [values]);

  const handleAddTool = (tool: Tool) => {
    setSelectedTools([...selectedTools, tool]);
  };

  const handleRemoveTool = (toolId: string) => {
    setSelectedTools(selectedTools.filter((tool) => tool.id !== toolId));
  };

  const handleUpdateToolConfig = (toolId: string, config: ToolConfig) => {
    const updatedTools = selectedTools.map((tool) =>
      tool.id === toolId
        ? { ...tool, config: { ...tool.config, ...config } }
        : tool
    );
    setSelectedTools(updatedTools);
  };

  useEffect(() => {
    setValues(assignDefaults(props.config?.config, props.configDefaults));
  }, [props.config, props.configDefaults]);
  useEffect(() => {
    if (dropzone.acceptedFiles.length > 0) {
      const acceptedFileIds = dropzone.acceptedFiles.map(fileId);
      setFiles((files) => [
        ...files.filter((f) => !acceptedFileIds.includes(fileId(f))),
        ...dropzone.acceptedFiles,
      ]);
    }
  }, [dropzone.acceptedFiles, setFiles]);
  const [inflight, setInflight] = useState(false);
  const readonly = !!props.config && !props.edit && !inflight;

  const settings = !readonly ? (
    <NameSaveArea>
      <NameSaveAreaInner>
        <NameSaveAreaInputHolder>
          <input
            type="text"
            name="key"
            id="key"
            autoComplete="off"
            placeholder="Name"
            defaultValue={props.config?.name}
          />
        </NameSaveAreaInputHolder>
        <CustomSaveButton type="submit">
          {inflight ? "Saving..." : "Save"}
        </CustomSaveButton>
      </NameSaveAreaInner>

      {/* <PublicToggle enabled={isPublic} setEnabled={setPublic} /> */}
    </NameSaveArea>
  ) : (
    <>{props.config?.public && <PublicLink />}</>
  );
  return (
    <CustomForm
      className={cn(props.className)}
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.target as HTMLFormElement;
        const key = form.key.value;
        if (!key) return;
        setInflight(true);
        const vals = { ...values };
        if (vals?.configurable) {
          vals.configurable = { ...vals.configurable };
          vals.configurable["type==agent/tools"] = [...selectedTools];
          setSelectedTools([]);
        }
        const assistantId = await props.saveConfig(
          key,
          vals!,
          files,
          isPublic,
          props.config?.assistant_id
        );
        props.enterConfig(assistantId);
        setInflight(false);
      }}
    >
      {settings}
      <Box mb={5}></Box>
      {/* {typeField && (
        <Types
          field={typeField}
          value={typeValue as string}
          setValue={(value: string) =>
            setValues({
              ...values,
              configurable: { ...values!.configurable, [typeKey]: value },
            })
          }
          readonly={readonly}
        />
      )} */}

      {typeSpec?.description && (
        <>
          <Label title="Description" />
          <div className="mb-8">{typeSpec.description}</div>
        </>
      )}

      {/* {!props.config && typeSpec?.files && (
        <FileUploadDropzone
          state={dropzone}
          files={files}
          setFiles={setFiles}
          className="mb-8"
        />
      )} */}

      <NewChatBottomBlock className={cn("", readonly && "not-allowed")}>
        {orderBy(
          Object.entries(
            props.configSchema?.properties.configurable.properties ?? {}
          ),
          ([key]) => ORDER.indexOf(last(key.split("/"))!)
        ).map(([key, value]) => {
          const title = value.title;
          if (key.split("/")[0].includes("==")) {
            const [parentKey, parentValue] = key.split("/")[0].split("==");
            if (values?.configurable?.[parentKey] !== parentValue) {
              return null;
            }
          } else {
            return null;
          }
          if (
            last(key.split("/")) === "retrieval_description" &&
            !files.length
          ) {
            return null;
          }

          if (value.type === "string" && value.enum) {
            return (
              <SingleOptionField
                key={key}
                id={key}
                field={value}
                title={title}
                value={values?.configurable?.[key] as string}
                setValue={(value: string) =>
                  setValues({
                    ...values,
                    configurable: { ...values!.configurable, [key]: value },
                  })
                }
                readonly={readonly}
              />
            );
          }

          if (value.type === "string") {
            return (
              <>
                <StringField
                  key={key}
                  id={key}
                  field={value}
                  title={title}
                  value={values?.configurable?.[key] as string}
                  setValue={(value: string) =>
                    setValues({
                      ...values,
                      configurable: { ...values!.configurable, [key]: value },
                    })
                  }
                  readonly={readonly}
                />
                {!props.config &&
                  typeSpec?.files &&
                  value.title === "Instructions" && (
                    <FileUploadDropzone
                      state={dropzone}
                      files={files}
                      setFiles={setFiles}
                      className="mb-8"
                    />
                  )}
              </>
            );
          } else if (value.type === "boolean") {
            return (
              <SingleOptionField
                key={key}
                id={key}
                field={{
                  ...value,
                  type: "string",
                  enum: ["Yes", "No"],
                }}
                title={title}
                value={values?.configurable?.[key] ? "Yes" : "No"}
                setValue={(value: string) =>
                  setValues({
                    ...values,
                    configurable: {
                      ...values!.configurable,
                      [key]: value === "Yes",
                    },
                  })
                }
                readonly={readonly}
              />
            );
          } else if (key === "type==agent/tools") {
            return (
              <ToolSelectionField
                key={key}
                selectedTools={selectedTools}
                onAddTool={handleAddTool}
                onRemoveTool={handleRemoveTool}
                onUpdateToolConfig={handleUpdateToolConfig}
                readonly={readonly}
                retrievalOn={files.length > 0}
              />
            );
          }
        })}
      </NewChatBottomBlock>
    </CustomForm>
  );
}
