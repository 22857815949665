import React from "react";
import {
  SideNavLogo,
  SideNavLogoLink,
  SideNavLogoText,
  SideNavLogoTypo,
} from "../style";
import { useWorkspaceId } from "../../../hooks/useWorkspace";
import { GetWorkspaceInfoQuery } from "../../../generated";
import { getRandomNumber } from "../../../helpers";
import { Tooltip, useTheme } from "@mui/material";
import { BrighthiveLogoSvg } from "../../Icons/BrighthiveLogoSvg";
import { BrighthiveIcon } from "../../Icons/BrighthiveIcon";
import CollapseButton from "../CollapseButton";
import TenantLogo from "./TenantLogo";

type Props = {
  collapse: boolean;
  data: GetWorkspaceInfoQuery | undefined;
  workspaceName?: string;
  toggleCollapse: () => void;
};

const SidebarLogo = ({
  collapse,
  data,
  workspaceName,
  toggleCollapse,
}: Props) => {
  const { workspaceId } = useWorkspaceId();
  return (
    <SideNavLogo aria-label="global navigation" role="navigation">
      <SideNavLogoLink
        tabIndex={1}
        removestyling
        to={`/workspace/${workspaceId}/project`}
        sx={{
          ".large-logo": {
            display: collapse ? "none" : "inline",

            "@media (min-width: 768px)": {
              display: collapse ? "none" : "inline",
            },
          },
          ".logo-icon": {
            display: collapse ? "inline" : "none",

            "@media (min-width: 768px)": {
              display: collapse ? "inline" : "none",
            },
          },
        }}
      >
        {data?.workspace?.logoUrl ? (
          <TenantLogo
            data={data}
            collapse={collapse}
            workspaceName={workspaceName}
          />
        ) : (
          <>
            {collapse ? (
              <BrighthiveIcon className="logo-icon" />
            ) : (
              <>
                <BrighthiveLogoSvg className="large-logo" />
              </>
            )}
          </>
        )}
      </SideNavLogoLink>
      {!collapse && (
        <CollapseButton collapse={collapse} toggleCollapse={toggleCollapse} />
      )}
    </SideNavLogo>
  );
};

export default SidebarLogo;
