import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AppContext } from "../App";
import { SideNavigationPage } from "../common/SideNavigationPage/SideNavigationPage";
import { GetCurrentUserInfoDocument, GetProjectDocument } from "../generated";

import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReorderIcon from "@mui/icons-material/Reorder";
import { useParams } from "react-router-dom";
import chatApi from "../api/chatApi";
import { ChatBot } from "../BrightBot/ChatBox/ChatBot";
import { BrightBotSmileIcon } from "../common/Icons/BrightBotSmileIcon";
import { SideViewportIcon } from "../common/Icons/SideViewportIcon";
import { SessionNav } from "./SessionNav";
import {
  BrightbotIconContainer,
  ChatContainer,
  ContentContainer,
  LeftHeaderContainer,
  RightHeaderContainer,
  SidebarIconContainer,
} from "./style";

import BrightbotStateSidebar from "./BrightbotStateSidebar";

export const Session = ({
  title,
  children,
}: {
  title?: string;
  children?: any;
}) => {
  const [sidebar, setSidebar] = useState(false);
  const { projectId, workspaceId, threadId } = useParams();
  const [threads, setThreads] = useState();
  const [refetch, setRefetch] = useState(false);

  const { data: currentUser } = useQuery(GetCurrentUserInfoDocument);

  const { data } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const workspace = data?.workspace;

  useEffect(() => {
    if ((currentUser?.currentUser.id, workspaceId)) {
      chatApi
        .post("/threads/search", {
          metadata: {
            user_id: currentUser?.currentUser.id,
            workspace_id: workspaceId,
          },
        })
        .then((res) => setThreads(res.data));
    }
  }, [currentUser?.currentUser.id, workspaceId, refetch]);

  return (
    <AppContext.Consumer>
      {({ showSidenav, setShowSidenav }) => (
        <>
          <SideNavigationPage
            innerSideBar
            className={"full-width bg-white"}
            leftHeaderElements={
              <LeftHeaderContainer>
                <Tooltip title={showSidenav ? "Close sidebar" : "Open sidebar"}>
                  <SidebarIconContainer
                    onClick={() => setShowSidenav((prev) => !prev)}
                  >
                    {showSidenav ? <ArrowBackIcon /> : <ReorderIcon />}
                  </SidebarIconContainer>
                </Tooltip>

                <BrightbotIconContainer>
                  <BrightBotSmileIcon />
                </BrightbotIconContainer>

                <Typography variant="h5">Brightbot</Typography>
              </LeftHeaderContainer>
            }
            rightContainerProps={{
              flex: "1",
            }}
            rightHeaderElements={
              <RightHeaderContainer>
                <Tooltip title={sidebar ? "Close sidebar" : "Open sidebar"}>
                  <Box onClick={() => setSidebar((prev) => !prev)}>
                    <SideViewportIcon sx={{ cursor: "pointer" }} />
                  </Box>
                </Tooltip>
              </RightHeaderContainer>
            }
            verticalNav={
              <SessionNav
                show={showSidenav}
                workspaceName={workspace?.name || ""}
                setShowSidenav={setShowSidenav}
                threads={threads}
                setRefetch={setRefetch}
              />
            }
            content={
              <ContentContainer>
                <ChatContainer>
                  {threads && (
                    <ChatBot showSuggestion={false} setRefetch={setRefetch} />
                  )}
                </ChatContainer>
                {/* <Issue /> */}
                {sidebar && <BrightbotStateSidebar />}
              </ContentContainer>
            }
          />
        </>
      )}
    </AppContext.Consumer>
  );
};
