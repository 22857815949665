import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Button } from "../../common/Button/Button";
import CustomTabs, { ExtractKeyType } from "../../common/CustomTabs";
import { DownloadFileIcon } from "../../common/Icons/DownloadFileIcon";
import { MoveNextIcon } from "../../common/Icons/MoveNextIcon";
import { MovePrevIcon } from "../../common/Icons/MovePrevIcon";
import { PauseIcon } from "../../common/Icons/PauseIcon";
import { ShareFooterIcon } from "../../common/Icons/ShareFooterIcon";

import { IpynbRenderer } from "react-ipynb-renderer";

// select jupyter theme
import "react-ipynb-renderer/dist/styles/default.css";
import notebookData from "./test-notebook.json";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  ContentContainer,
  FooterBtnContainer,
  FooterContainer,
  HeaderContainer,
  MainContainer,
  OutputContainer,
  OutputItemContainer,
  OutputWrapper,
  SidebarContainer,
  StateContainer,
  StateParentContainer,
  TitleContainer,
} from "./style";

const tabs = [
  {
    key: "plan",
    label: "Plan",
  },
  {
    key: "output",
    label: "Output",
  },
  {
    key: "reasoning",
    label: "Reasoning",
  },
] as const;

const code = `-- models/anonymized_student_records.sql

-- Select all columns from the student records table except for the social security number
with original_data as (
    select
        student_id,
        first_name,
        last_name,
        hash(social_security_number) as hashed_ssn,
        grade_level,
        enrollment_date
    from {{ ref('student_records') }}
)

-- Create a new table with the anonymized student records
select *
from original_data`;

type Props = {};

const BrightbotStateSidebar = (props: Props) => {
  const [activeTab, setActiveTab] =
    useState<ExtractKeyType<typeof tabs[number]>>("output");
  const { palette } = useTheme();
  return (
    <Box display="flex" id="sidebar-section">
      <SidebarContainer>
        <MainContainer>
          <HeaderContainer id="header">
            <Button>
              <MovePrevIcon />
            </Button>
            <Button>
              <PauseIcon className="pause" />
            </Button>
            <Button>
              <MoveNextIcon />
            </Button>
          </HeaderContainer>
          <ContentContainer id="content">
            <Box sx={{ padding: "1.5rem" }}>
              <CustomTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Box>
            <StateParentContainer>
              <StateContainer>
                {activeTab === "output" && (
                  <OutputContainer>
                    <OutputWrapper>
                      <TitleContainer>
                        <Typography
                          color={palette.gray.dark}
                          variant="subtitle1"
                        >
                          DBT Code
                        </Typography>
                      </TitleContainer>
                      <OutputItemContainer>
                        <SyntaxHighlighter
                          lineProps={{
                            style: {
                              wordBreak: "break-all",
                              whiteSpace: "pre-wrap",
                            },
                          }}
                          wrapLines={true}
                          language="sql"
                          style={monokai}
                        >
                          {code}
                        </SyntaxHighlighter>
                      </OutputItemContainer>
                    </OutputWrapper>
                    <OutputWrapper>
                      <TitleContainer>
                        <Typography
                          color={palette.gray.dark}
                          variant="subtitle1"
                        >
                          Jupyter Notebook
                        </Typography>
                      </TitleContainer>
                      <OutputItemContainer>
                        <IpynbRenderer
                          ipynb={notebookData}
                          syntaxTheme="prism"
                        />
                      </OutputItemContainer>
                    </OutputWrapper>

                    <OutputWrapper>
                      <TitleContainer
                        sx={{
                          marginBottom: "1rem",
                        }}
                      >
                        <Typography
                          color={palette.gray.dark}
                          variant="subtitle1"
                        >
                          Dashboard
                        </Typography>
                      </TitleContainer>
                      <OutputItemContainer>
                        <iframe
                          style={{
                            width: "100%",
                            height: "100vh",
                            border: "none",
                          }}
                          src={process.env.REACT_APP_EVIDENCE_URL}
                        ></iframe>
                      </OutputItemContainer>
                    </OutputWrapper>
                  </OutputContainer>
                )}
                {activeTab === "reasoning" && <>Reasoning Tab</>}
                {activeTab === "plan" && <> Plan Tab</>}
              </StateContainer>
            </StateParentContainer>
          </ContentContainer>
          <FooterContainer id="footer">
            <Box display="flex" gap=".5rem">
              <FooterBtnContainer>
                <DownloadFileIcon sx={{ height: "1rem", width: "1rem" }} />
              </FooterBtnContainer>
              <FooterBtnContainer>
                <ShareFooterIcon sx={{ height: "1rem", width: "1rem" }} />
              </FooterBtnContainer>
            </Box>
          </FooterContainer>
        </MainContainer>
      </SidebarContainer>
    </Box>
  );
};

export default BrightbotStateSidebar;
