import React from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter } from "react-router-dom";
import { BrighthiveThemeProvider } from "./BrighthiveThemeProvider";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { MockedProvider } from "@apollo/client/testing";
import "./index.css";
import { mocks } from "./mocks";
import { AppApolloProvider } from "./AppApolloProvider";
import "react-toastify/dist/ReactToastify.css";
import { DataProvider } from "./hooks/dataContext";
import Confirmation from "./contexts/confirmation";
import { QueryClient, QueryClientProvider } from "react-query";

function getCookie(name: string) {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
}

document.addEventListener("DOMContentLoaded", () => {
  const userId =
    localStorage.getItem("opengpts_user_id") ||
    getCookie("opengpts_user_id") ||
    uuidv4();

  // Push the user id to localStorage in any case to make it stable
  localStorage.setItem("opengpts_user_id", userId);
  // Ensure the cookie is always set (for both new and returning users)
  const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const expires = new Date(Date.now() + weekInMilliseconds).toUTCString();
  document.cookie = `opengpts_user_id=${userId}; path=/; expires=${expires}; SameSite=Lax;`;
});

const ApolloProvider =
  process.env.REACT_APP_MOCKS_ENABLED === "true"
    ? MockedProvider
    : AppApolloProvider;

const apolloProviderProps = {
  ...(process.env.REACT_APP_MOCKS_ENABLED && { mocks }),
};

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrighthiveThemeProvider>
        <Confirmation.ContextProvider>
          <BrowserRouter>
            <ApolloProvider {...apolloProviderProps}>
              <DataProvider>
                <App />
              </DataProvider>
            </ApolloProvider>
          </BrowserRouter>
        </Confirmation.ContextProvider>
      </BrighthiveThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
